import React from 'react'
import { CreateTest } from '../../Pages/createtest'

const UserCreateTest = () => {
  return (
    <CreateTest/>

  )
}

export default UserCreateTest