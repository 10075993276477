import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

export default function CommonSelect(props) {
    const { selectHead, selectList, labelSelect, style, className,placeholder } = props;

    const [selectValue, setSelectValue] = React.useState('');

    const handleChange = (event) => {
        setSelectValue(event.target.value);
    };


    return (
        <>
            {labelSelect ?
                <Typography fontSize={{ xs: '12px', sm: '14px' }} fontWeight={600} marginBottom={{ xs: '3px', sm: '6px' }}>{labelSelect}</Typography>
                : ''
            }
            <Select
                value={selectValue}
                onChange={handleChange}
                displayEmpty
                fullWidth
                placeholder={placeholder ? placeholder : 'Select'}
                sx={style}
                className={className}
                {...props}
            >
                <MenuItem value="" hidden disabled>{selectHead}</MenuItem>
                {
                    selectList.map((data, i) => {
                        return (
                            <MenuItem value={data.value|| data} key={i}>{data.name || data}</MenuItem>
                        )
                    })
                }
            </Select>
        </>
    );
}