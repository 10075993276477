import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../Helper/Icons'
import { Button, Input } from '../Commons'
import { useFormik } from "formik";
import { updatePassword } from "../../Helper/initialValues"
import { updatePassValidation } from "../../Helper/validation"
import { userPassUpdate } from "../../Service/manageuser.service"
import { showNotification } from '../../Helper/Notification'

const ChangePassword = (props) => {
    const { setChangePassPopup, confirmPassChange, passChangeUserId } = props;

    const formik = useFormik({
        initialValues: updatePassword,
        onSubmit: async (value) => {
            const payload = {
                new_password: value.new_pass,
                confirm_password: value.confirm_new_pass
            }
            const res = await userPassUpdate(passChangeUserId, payload);
            if (res.status) {
                setChangePassPopup(false)
                showNotification("success", res.message || 'Password has been updated successfully')
            } else {
                showNotification("error", res.message || 'Failed to change password')
            }

        },
        validationSchema: updatePassValidation,
    });


    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Change Password
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setChangePassPopup(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Input placeholder='New Password' passwordInput formik={formik} name="new_pass" />
                    </Grid>
                    <Grid item xs={12}>
                        <Input placeholder='Confirm Password' passwordInput formik={formik} name="confirm_new_pass" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName='Cancel ' buttonColor='gray' onClick={() => setChangePassPopup(false)} />
                <Button buttonName='Save' onClick={formik.handleSubmit} />
            </DialogActions>
        </div>
    )
}

export default ChangePassword