import React, { useState, useEffect } from 'react'
import { Box, Divider, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import { Button, Checkbox, Input, Modal } from '../../Component/Commons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, FiFileText } from '../../Helper/Icons';
import { useStyles } from '../../Helper/Theme';
import UserDetails from '../../Pages/createtest/UserDetails';
import { Annotation } from 'react-mark-image';
import { getProfile } from "../../Service/profile.service";
import { podiatryIv } from "../../Helper/initialValues";
import { podiatryValidation } from "../../Helper/validation";
import { showNotification } from "../../Helper/Notification";
import { getPpodiatryFormByFormId } from "../../Service/podiatry.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import LeftFeet from '../../assets/image/left_feet.png'
import RightFeet from '../../assets/image/right_feet.png'


const PodiatryReqFormView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });

    useEffect(() => {
        viewFormApiHandler();
    }, []);


    const viewFormApiHandler = async () => {
        const { data, status } = await getPpodiatryFormByFormId(
            state.formId
        );
        if (status) {
            const { podiatry_requisition_information } = data;
            formik.setValues({
                ...formik.values,
                referByUser: {
                    facility_name: data?.user_information?.facility_name,
                    doctor: data?.user_information?.full_name,
                    address: data?.user_information?.address,
                    phone: data?.user_information?.phone,
                    fax: data?.user_information?.fax,
                },
                patientInformation: {
                    patient_first_name: data?.patient_information?.patient_first_name,
                    patient_last_name: data?.patient_information?.patient_last_name,
                    initial: data?.patient_information?.initial,
                    patient_address: data?.patient_information?.patient_address,
                    patient_city: data?.patient_information?.patient_city,
                    patient_state: data?.patient_information?.patient_state,
                    patient_zip: data?.patient_information?.patient_zip,
                    patient_area_code: data?.patient_information?.patient_area_code,
                    patient_age: data?.patient_information?.patient_age,
                    patient_sex: data?.patient_information?.patient_sex,
                    patient_dob: data?.patient_information?.patient_dob,
                    patient_phone: data?.patient_information?.patient_phone,
                    patient_payment: data?.patient_information?.patient_payment,
                    pay_amount: data?.patient_information?.pay_amount,
                    bill_number: data?.patient_information?.bill_number,
                    bill_amount: data?.patient_information?.bill_amount,
                    insurance_company: data?.patient_information?.insurance_company,
                    insurance_no: data?.patient_information?.insurance_no,
                    icd_10: data?.patient_information?.icd_10,
                    clinical_history: data?.patient_information?.clinical_history,
                    additional_physician: data?.patient_information?.additional_physician == null ? "" : data?.patient_information?.additional_physician,
                    other_test: data?.patient_information?.other_test,
                    pationtDoc : [data?.patient_information?.patient_document],
                },
                left_additional_test_notes: podiatry_requisition_information?.left_additional_test_notes,
                left_aspirate_cyst_or_other_fluid: podiatry_requisition_information?.left_aspirate_cyst_or_other_fluid,
                left_aspirate_joint_fluid: podiatry_requisition_information?.left_aspirate_joint_fluid,
                left_leg_codinet: podiatry_requisition_information?.left_leg_codinet,
                left_leg_image: podiatry_requisition_information?.left_leg_image,
                left_leg_specymen: podiatry_requisition_information?.left_leg_specymen,
                left_microbiology_only: podiatry_requisition_information?.left_microbiology_only,
                left_nail_biopsy: podiatry_requisition_information?.left_nail_biopsy,
                left_skin_soft_tissue_bone_biopsy: podiatry_requisition_information?.left_skin_soft_tissue_bone_biopsy,
                right_additional_test_notes: podiatry_requisition_information?.right_additional_test_notes,
                right_aspirate_cyst_or_other_fluid: podiatry_requisition_information?.right_aspirate_cyst_or_other_fluid,
                right_aspirate_joint_fluid: podiatry_requisition_information?.right_aspirate_joint_fluid,
                right_leg_codinet: podiatry_requisition_information?.right_leg_codinet,
                right_leg_image: podiatry_requisition_information?.right_leg_image,
                right_leg_specymen: podiatry_requisition_information?.right_leg_specymen,
                right_microbiology_only: podiatry_requisition_information?.right_microbiology_only,
                right_nail_biopsy: podiatry_requisition_information?.right_nail_biopsy,
                right_skin_soft_tissue_bone_biopsy: podiatry_requisition_information?.right_skin_soft_tissue_bone_biopsy,

            });
        }
    };

    const formik = useFormik({
        initialValues: podiatryIv,
        onSubmit: async (values) => {
        },
    });

    const rightNailBiopsy = [
        { label: "Nail Panel by PCR (nailbag)", value: "Nail Panel by PCR (nailbag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
        { label: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", value: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
        { label: "Histology with stains: PAS (formalin or nail bag)", value: "Histology with stains: PAS (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
        { label: "Fungal culture (nail bag)", value: "Fungal culture (nail bag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
    ]

    const leftNailBiopsy = [
        { label: "Nail Panel by PCR (nailbag)", value: "Nail Panel by PCR (nailbag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
        { label: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", value: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
        { label: "Histology with stains: PAS (formalin or nail bag)", value: "Histology with stains: PAS (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
        { label: "Fungal culture (nail bag)", value: "Fungal culture (nail bag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
    ]

    const rightBoneBiopsy = [
        { label: "Histology (formalin)", value: "Histology (formalin)", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
        { label: "Histology with gout analysis (100% Alcohol)", value: "Histology with gout analysis (100% Alcohol)", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
        { label: "Culture (swab)", value: "Culture (swab)", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
        { label: "Histology with stains: PAS, GAS, Fontana-Masson", value: "Histology with stains: PAS, GAS, Fontana-Masson", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
    ]

    const rightAspirateJointFluid = [
        { label: "Crystal analysis (lavender top tube)", value: "Crystal analysis (lavender top tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_joint_fluid", e) },
        { label: "Cell count (lavender top tube)", value: "Cell count (lavender top tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_joint_fluid", e) },
        { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_joint_fluid", e) },
    ]

    const leftAspirateJointFluid = [
        { label: "Crystal analysis (lavender top tube)", value: "Crystal analysis (lavender top tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_joint_fluid", e) },
        { label: "Cell count (lavender top tube)", value: "Cell count (lavender top tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_joint_fluid", e) },
        { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_joint_fluid", e) },
    ]



    const leftBoneBiopsy = [
        { label: "Histology (formalin)", value: "Histology (formalin)", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
        { label: "Histology with gout analysis (100% Alcohol)", value: "Histology with gout analysis (100% Alcohol)", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
        { label: "Culture (swab)", value: "Culture (swab)", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
        { label: "Histology with stains: PAS, GAS, Fontana-Masson", value: "Histology with stains: PAS, GAS, Fontana-Masson", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
    ]

    const rightAspirateOtherFluid = [
        { label: "Cytology only (cytolyt)", value: "Cytology only (cytolyt)", onChange: (e) => handleOrderChange(formik, "right_aspirate_cyst_or_other_fluid", e) },
        { label: "Crystal analysis for gout (lavender top tube)", value: "Crystal analysis for gout (lavender top tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_cyst_or_other_fluid", e) },
        { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_cyst_or_other_fluid", e) },
    ]

    const leftAspirateOtherFluid = [
        { label: "Cytology only (cytolyt)", value: "Cytology only (cytolyt)", onChange: (e) => handleOrderChange(formik, "left_aspirate_cyst_or_other_fluid", e) },
        { label: "Crystal analysis for gout (lavender top tube)", value: "Crystal analysis for gout (lavender top tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_cyst_or_other_fluid", e) },
        { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_cyst_or_other_fluid", e) },
    ]

    const rightMicrobiology = [
        { label: "Culture and sensitivity only (swab)", value: "Culture and sensitivity only (swab)", onChange: (e) => handleOrderChange(formik, "right_microbiology_only", e) },
        { label: "Aerobic only (for skin or superficial wound)", value: "Aerobic only (for skin or superficial wound)", onChange: (e) => handleOrderChange(formik, "right_microbiology_only", e) },
        { label: "Aerobic and Anaerobic (for deep wound or abscess)", value: "Aerobic and Anaerobic (for deep wound or abscess)", onChange: (e) => handleOrderChange(formik, "right_microbiology_only", e) },
        { label: "Fungal (Swab)", value: "Fungal (Swab)" },
    ]

    const leftMicrobiology = [
        { label: "Culture and sensitivity only (swab)", value: "Culture and sensitivity only (swab)", onChange: (e) => handleOrderChange(formik, "left_microbiology_only", e) },
        { label: "Aerobic only (for skin or superficial wound)", value: "Aerobic only (for skin or superficial wound)", onChange: (e) => handleOrderChange(formik, "left_microbiology_only", e) },
        { label: "Aerobic and Anaerobic (for deep wound or abscess)", value: "Aerobic and Anaerobic (for deep wound or abscess)", onChange: (e) => handleOrderChange(formik, "left_microbiology_only", e) },
        { label: "Fungal (Swab)", value: "Fungal (Swab)" },
    ]

    const handleOrderChange = (formik, orderFieldName, e) => {
        const currentValue = formik.values[orderFieldName];

        if (e.target.checked) {
            formik.setFieldValue(orderFieldName, [...currentValue, e.target.value]);
        } else {
            formik.setFieldValue(orderFieldName, currentValue.filter(item => item !== e.target.value));
        }
    };

    return (
        <>

            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
                } />
            <Box >
                <UserDetails formik={formik} isFormSubmitted={false} view={true} />

                <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>Right</Typography>
                <Divider />
                <Box marginTop={2}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Stack flexDirection={{ sm: 'row' }} alignItems={{ sm: 'flex-end' }} gap={2} className='markeble_image' style={{position:'relative',zIndex:'88'}}>
                                <div style={{position:'absolute',width:'100%',height:'100%',zIndex:'99'}}></div>
                                <Annotation
                                    src={RightFeet}
                                    alt="feet_image"
                                    annotations={formik.values.right_leg_codinet}
                                    onAnnotationsUpdate={(updatedAnnotations) => formik.setFieldValue("right_leg_codinet", updatedAnnotations)}

                                    editorMode='AnnotateOnly'
                                    //disable this to prevent annotation
                                    //    renderShape={(props) => {
                                    //      return null }}                                  
                                    disableAnnotation={true}

                                />
                                <Input disabled={true} variant='standard' placeholder='Specimen Source' name="right_leg_specymen" value={formik.values.right_leg_specymen} onChange={formik.handleChange} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={rightNailBiopsy} row labelInput='Nail, Biopsy'
                                checked={formik.values.right_nail_biopsy}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={rightBoneBiopsy} row labelInput='Skin, Soft Tissue, Bone Biopsy'
                                checked={formik.values.right_skin_soft_tissue_bone_biopsy}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={rightAspirateJointFluid} row labelInput='Aspirate (Joint Fluid)'
                                checked={formik.values.right_aspirate_joint_fluid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={rightAspirateOtherFluid} row labelInput='Aspirate (Cyst Or Other Fluid)'
                                checked={formik.values.right_aspirate_cyst_or_other_fluid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={rightMicrobiology} row labelInput='Microbiology Only'
                                checked={formik.values.right_microbiology_only}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input disabled={true} labelInput='Additional Test / Notes'
                                name="right_additional_test_notes"
                                value={formik.values.right_additional_test_notes}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Left</Typography>
                <Divider />
                <Box marginTop={2}>
                    <Grid container spacing={2}>
                        <Grid item>
                        <Stack flexDirection={{ sm: 'row' }} alignItems={{ sm: 'flex-end' }} gap={2} className='markeble_image' style={{position:'relative',zIndex:'88'}}>
                                <div style={{position:'absolute',width:'100%',height:'100%',zIndex:'99'}}></div>
                                <Annotation
                                    src={LeftFeet}
                                    alt="feet_image"
                                    annotations={formik.values.left_leg_codinet}
                                    onAnnotationsUpdate={(updatedAnnotations) => formik.setFieldValue("left_leg_codinet", updatedAnnotations)}
                                    allowTouch
                                    editorMode='AnnotateOnly'
                                />
                                <Input disabled={true} variant='standard' placeholder='Specimen Source' name="left_leg_specymen" value={formik.values.left_leg_specymen} onChange={formik.handleChange} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={leftNailBiopsy} row labelInput='Nail, Biopsy'
                                checked={formik.values.left_nail_biopsy}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={leftBoneBiopsy} row labelInput='Skin, Soft Tissue, Bone Biopsy'
                                checked={formik.values.left_skin_soft_tissue_bone_biopsy}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={leftAspirateJointFluid} row labelInput='Aspirate (Joint Fluid)'
                                checked={formik.values.left_aspirate_joint_fluid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={leftAspirateOtherFluid} row labelInput='Aspirate (Cyst Or Other Fluid)'
                                checked={formik.values.left_aspirate_cyst_or_other_fluid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={leftMicrobiology} row labelInput='Microbiology Only'
                                checked={formik.values.left_microbiology_only}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input disabled={true} labelInput='Additional Test / Notes' name="left_additional_test_notes" value={formik.values.left_additional_test_notes} onChange={formik.handleChange} />
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </>

    )
}

export default PodiatryReqFormView