import React, { useEffect, useState } from "react";
import { Stack, TextField } from "@mui/material";
import CreateFormTable from "./CreateFormTable";
import { Button } from "../../Component/Commons";
import { VscSettings } from "../../Helper/Icons";
import FilterForm from "./FilterForm";
import { submitedFormListHandler } from "../../Service/commanForm.service";
import { getStateData, getCityData } from "../../Service/dropdown.service";
import { useFormik } from "formik";
import { formFilterIv } from "../../Helper/initialValues";

const SubmittedForm = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [submitFormList, setSubmitFormList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    totalSubmitFormData(1);
  }, []);

  useEffect(() => {
    getStateDatas();
    getCityDatas();
  }, [])

  const testTypeList = [
    { id: 2, name: "Surgical Pathology", value : "SurgicalPathology" },
    { id: 1, name: "Non-Gyn Cytopathology" , value : "NonGynCytopathologyRequisition"},
    { id: 4, name: "Molecular Pathology" , value : "MolecularRequisition"},
    { id: 5, name: "Hematopathology" , value : "HematopathologyRequisition"},
    { id: 6, name: "Gynecological Cytology" , value : "GynecologicalCytologyRequisition"},
    { id: 7, name: "Podiatry Request" , value : "PodiatryRequisition"},
    { id: 3, name: "Prostate Pathology" , value : "ProstateRequisition"},
  ];
  const genderList = [
    {name:"Male", value:"Male"},
    {name:"Female", value:"Female"}
  ]


  const getStateDatas = async () => {
    const res = await getStateData();
    if (res) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  }
  const getCityDatas = async () => {
    const res = await getCityData();
    if (res) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  }


  const formik = useFormik({
    initialValues: formFilterIv,
    onSubmit: async (values) => {
      totalSubmitFormData(1);
      setOpenDrawer(false);

    },
});

  const totalSubmitFormData = async (page) => {
    let selectQry = `?search=${formik.values.search}&page=${page}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&patient_state=${formik.values.patient_state}&patient_city=${formik.values.patient_city}&patient_sex=${formik.values.patient_sex}&form_type=${formik.values.form_type}`;
    const { data, message, status,count,totalPage} = await submitedFormListHandler(selectQry);
    if (status) {
      setSubmitFormList(data);
      formik.setFieldValue("totalCount", totalPage)
      formik.setFieldValue("totalCountData", count)

    } else {
      setSubmitFormList([]);
      formik.setFieldValue("totalCountData", 0)
      formik.setFieldValue("totalCount", 0)
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <TextField
          hiddenLabel
          className="search_field"
          placeholder="Search"
          onChange={(event)=>{formik.setFieldValue("search",event.target.value);
          formik.handleSubmit()
           }}
          sx={{ width: { xs: "100%", sm: "unset" } }}
        />
        <Button
          style={{ padding: "9px 11px", minWidth: "auto" }}
          buttonName={
            <VscSettings style={{ fontSize: "20px", cursor: "pointer" }} />
          }
          onClick={toggleDrawer}
        />
        <FilterForm openDrawer={openDrawer} testTypeList={testTypeList} genderList={genderList} stateList={stateList} cityList={cityList} toggleDrawer={toggleDrawer}
       formik={formik}
        />
      </Stack>
      <CreateFormTable submitFormList={submitFormList} formik={formik} dashboard={false}/>
    </>
  );
};

export default SubmittedForm;
