import React from "react";
import TextField from "@mui/material/TextField";
import { Box, IconButton, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "../../Helper/Icons";
import { useLocation } from "react-router-dom";

const CommonInput = (props) => {
  const location = useLocation();
  const {
    type,
    view,
    placeholder,
    iconInput,
    labelInput,
    value,
    disabled,
    boxWidth,
    variant,
    passwordInput,
    onChange,
    errorText,
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <Box width={boxWidth}>
      {labelInput && (
        <Typography
          fontSize={{ xs: "12px", sm: "14px" }}
          fontWeight={600}
          marginBottom={{ xs: "3px", sm: "6px" }}
          color={"#000"}
        >
          {labelInput}
        </Typography>
      )}
      <Box position={"relative"}>
        <TextField
          fullWidth
          hiddenLabel
          variant={variant ? variant : "outlined"}
          disabled={view}
          placeholder={placeholder}
          inputProps={{
            maxLength: props.maxLength,
            max: props.min ? props.min : null,
          }}
          value={value}
          onChange={onChange}
          autoComplete={passwordInput ? "new-password" : "off"}
          type={
            type
              ? type
              : passwordInput
                ? showPassword
                  ? "text"
                  : "password"
                : "text"
          }
          color="primary"
          error={
            props?.formik?.touched[props.name] &&
            props?.formik?.errors[props.name]
          }
          helperText={
            props?.formik?.touched[props.name] &&
            props?.formik?.errors[props.name]
          }
          {...props?.formik?.getFieldProps(props.name)}
          {...props}
        />
        {passwordInput ? (
          <InputAdornment
            position="end"
            sx={{
              height: "100%",
              position: "absolute",
              right: "5px",
              top: "6px",
            }}
          >
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : (
          iconInput && (
            <InputAdornment
              position="end"
              sx={{
                height: "100%",
                position: "absolute",
                right: "5px",
                top: "6px",
              }}
            >
              <IconButton> {iconInput} </IconButton>
            </InputAdornment>
          )
        )}
      </Box>
      {errorText && (
        <Typography fontSize={"12px"} sx={{ color: "#dc3545" }}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

export default CommonInput;
