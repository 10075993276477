import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import {
  Button,
  Checkbox,
  Input,
  Modal,
} from "../../../../Component/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "../../../../Helper/Icons";
import { StyledTableRow, useStyles } from "../../../../Helper/Theme";
import UserDetails from "../../UserDetails";
import {
  bladderIcdCodes,
  prostateIcdCodes,
  specimenType,
  urineCytologySource,
} from "../../../../Helper/Helper";
import { useFormik } from "formik";
import { prostateIv } from "../../../../Helper/initialValues";
import { prostateValidation } from "../../../../Helper/validation";
import { getProfile } from "../../../../Service/profile.service";
import { useTheme } from "@mui/material/styles";
import { createProstateFormhandler } from "../../../../Service/prostate.service";
import { showNotification } from "../../../../Helper/Notification";
import { useSelector } from "react-redux";
import { savePatientDoc } from "../../../../Service/commanForm.service";
import ProstateImage from "./ProstateImage";

const ProstateReq = () => {

  const { user } = useSelector((state) => state?.root?.auth);

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [psa, setPsa] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [freePsa, setFreePsa] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [prostateSelectedImageList, setProstateSelectedImageList] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [specimenTypeData, setSpecimenTypeData] = useState([
    {
      specimentsType1: "",
      specimentsType2: "",
      specimentsType3: "",
      specimentsType4: "",
      specimentsType5: "",
    },
  ]);

  const [bodySubProcedureData, setBodySubProcedureData] = useState([
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    }
  ]);

  const handleItemRemoval = (value) => {
    if (prostateSelectedImageList.includes(value)) {
      setProstateSelectedImageList(prostateSelectedImageList.filter((v) => v !== value));
    }

    if (bodySubProcedureData.find((data) => data.BodySite === value)) {
      const newData = [...bodySubProcedureData];
      const index = newData.findIndex((data) => data.BodySite === value);
      newData.splice(index, 1);
      newData.push({
        BodySite: "",
        SubSite: "",
        Procedure: "",
        changed: 0
      });
      setBodySubProcedureData(newData);
    }
  };

  // checkBoxHandler
  const [prostateIcdValue, setProstateIcdValue] = useState([]);
  const [prostateText, setProstateText] = useState("");
  const [prostateFlag, setProstateFlag] = useState(false);
  const prostateIcdHandler = (value) => {
    if (value === "Other") {
      setProstateFlag(!prostateFlag);
      setProstateText("");
    }
    if (prostateIcdValue.includes(value)) {
      setProstateIcdValue(prostateIcdValue.filter((v) => v !== value));
    } else {
      setProstateIcdValue([...prostateIcdValue, value]);
    }
  };

  const [bladderIcdValues, setBladderIcdValues] = useState([]);
  const [bladderIcdText, setBladderIcdText] = useState("");
  const [bladderIcdFlag, setBladderIcdFlag] = useState(false);
  const bladderIcdCodesHandler = (value) => {
    if (value === "Other") {
      setBladderIcdFlag(!bladderIcdFlag);
      setBladderIcdText("")
    }
    if (bladderIcdValues.includes(value)) {
      setBladderIcdValues(bladderIcdValues.filter((v) => v !== value));
    } else {
      setBladderIcdValues([...bladderIcdValues, value]);
    }
  };
  const [specimentValues, setSpecimentValues] = useState([]);
  const [specimentText, setSpecimentText] = useState("");
  const [specimentFlag, setSpecimeFlag] = useState(false);
  const SpecimenHandler = (value) => {
    if (value === "Other") {
      setSpecimeFlag(!specimentFlag);
      setSpecimentText("");
    }
    if (specimentValues.includes(value)) {
      setSpecimentValues(specimentValues.filter((v) => v !== value));
    } else {
      setSpecimentValues([...specimentValues, value]);
    }
  };

  const [urineCytologyValues, setUrineCytologyValues] = useState([]);
  const [urineText, setUrineText] = useState("");
  const [urineFlag, setUrineFlag] = useState(false);

  const urineCytologyHandler = (value) => {
    if (value === "Other") {
      setUrineText("");
      setUrineFlag(!urineFlag);
    }
    if (urineCytologyValues.includes(value)) {
      setUrineCytologyValues(urineCytologyValues.filter((v) => v !== value));
    } else {
      setUrineCytologyValues([...urineCytologyValues, value]);
    }
  };


  useEffect(() => {
    referredByDataHandler();
  }, []);

  const referredByDataHandler = async () => {
    const { data, message, status } = await getProfile();
    if (status) {
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data.facility_name,
          doctor: data.full_name,
          address: data.address,
          phone: data.phone,
          fax: data.fax,
        },
      });
    }
  };

  const submitFormHandler = async () => {
    const payload = {
      patient_information: {
        patient_first_name: formik.values.patientInformation.patient_first_name,
        patient_last_name: formik.values.patientInformation.patient_last_name,
        initial: formik.values.patientInformation.initial,
        patient_address: formik.values.patientInformation.patient_address,
        patient_city: formik.values.patientInformation.patient_city,
        patient_state: formik.values.patientInformation.patient_state,
        patient_zip: formik.values.patientInformation.patient_zip,
        patient_area_code: formik.values.patientInformation.patient_area_code,
        patient_age: formik.values.patientInformation.patient_age,
        patient_sex: formik.values.patientInformation.patient_sex,
        patient_dob: formik.values.patientInformation.patient_dob,
        patient_phone: formik.values.patientInformation.patient_phone,
        patient_payment: formik.values.patientInformation.patient_payment,
        bill_number: formik.values.patientInformation.bill_number,
        bill_amount: formik.values.patientInformation.bill_amount,
        insurance_company: formik.values.patientInformation.insurance_company,
        insurance_no: formik.values.patientInformation.insurance_no,
        pay_amount: formik.values.patientInformation.pay_amount,
        icd_10: formik.values.patientInformation.icd_10,
        clinical_history: formik.values.patientInformation.clinical_history,
        additional_physician:
          formik.values.patientInformation.additional_physician,
        other_test: formik.values.patientInformation.other_test,
      },
      prostate_requisition_information: {
        prostate_icd_10_codes: prostateIcdValue,
        bladder_icd_10_codes: bladderIcdValues,
        specimen_type: specimentValues,
        urine_cytology_source: urineCytologyValues,
        psa_of_ml: psa,
        free_psa: freePsa,
        specimens_other_than_prostate: specimenTypeData,
        bodysite_subsite_procedure: bodySubProcedureData,
        select: selectItem,
        specimen_type_other: specimentText,
        urine_cytology_source_other: urineText,
        bladder_icd_10_codes_other: bladderIcdText,
        prostate_icd_10_codes_other: prostateText
      },
    };

    const { data, message, status } = await createProstateFormhandler(payload);
    if (status) {
      if (formik.values.patientInformation.pationtDoc && formik.values.patientInformation.pationtDoc.length > 0) {
        savePatientDocHandler(data?.patient_information?.id)
      } else {

        showNotification("success", message || 'Form Register Successfully');
        if (user.group_name === "provider") {
          navigate(`/submitted-forms`)
        } else {
          navigate(`/admin/submitted-forms`);
        }
      }
      // submitted-forms
    } else {
      showNotification(
        "error",
        message || "Something went wrong while Form Register"
      );
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...bodySubProcedureData];
    newData[index][field] = value;
    newData[index].changed = 1;
    setBodySubProcedureData(newData);
    formik.setFieldValue("bodysiteSubsiteProcedure", newData);
  };

  const specimenTypeHandler = (index, field, value) => {
    const newData = [...specimenTypeData];
    newData[index][field] = value;
    formik.setFieldValue("specimensOtherThanProstate", newData);
    setSpecimenTypeData(newData);
  };

  const formik = useFormik({
    initialValues: prostateIv,
    validationSchema: prostateValidation,
    onSubmit: submitFormHandler,
  });

  const savePatientDocHandler = async (id) => {
    const formData = new FormData();
    formData.append("patient_document", formik.values.patientInformation.pationtDoc[0]);
    const { data, message, status } = await savePatientDoc(id, formData);
    if (status) {
      showNotification("success", message || 'Form Register Successfully');
      if (user.group_name === "provider") {
        navigate(`/submitted-forms`)
      } else {
        navigate(`/admin/submitted-forms`);
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const validationHandler = () => {
    setIsFormSubmitted(true);
    if (Object.keys(formik.errors).length > 0) {
      showNotification("error", "Required filed is missing");
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", background: "#8cc63f", color: "#fff" }}
        />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
      <Box
        className={classes.cardContainer}
        padding={{ xs: "12px 10px", sm: 3 }}
      >
        <UserDetails formik={formik} isFormSubmitted={isFormSubmitted} />

        <Typography fontWeight={"700"} fontSize={{ xs: "15px", sm: "18px" }}>
          ICD-10 Codes Diagnosis/Signs/Symptoms
        </Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize={"14px"}>
                For your convenience, a partial list of common diagnoses
                follows. For a complete listing of all ICD-10 codes, refer to a
                current version of the ICD-10-CM book. All diagnoses should be
                provided by the ordering physician or his/her authorized
                designee.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checkboxList={prostateIcdCodes}
                row
                labelInput="Prostate-ICD-10 Codes"
                onChange={(event) => prostateIcdHandler(event.target.value)}
              />
              {prostateFlag && (
                <Input
                  variant="standard"
                  value={prostateText}
                  onChange={(event) => setProstateText(event.target.value)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checkboxList={bladderIcdCodes}
                row
                labelInput="Bladder-ICD-10 Codes"
                onChange={(event) => bladderIcdCodesHandler(event.target.value)}
              />
              {bladderIcdFlag && (
                <Input
                  variant="standard"
                  value={bladderIcdText}
                  onChange={(event) => setBladderIcdText(event.target.value)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checkboxList={specimenType}
                row
                labelInput="Specimen Type"
                onChange={(event) => SpecimenHandler(event.target.value)}
              />
              {specimentFlag && (
                <Input
                  variant="standard"
                  value={specimentText}
                  onChange={(event) => setSpecimentText(event.target.value)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checkboxList={urineCytologySource}
                row
                labelInput="Urine Cytology Source"
                onChange={(event) => urineCytologyHandler(event.target.value)}
              />
              {urineFlag && (
                <Input
                  variant="standard"
                  value={urineText}
                  onChange={(event) => setUrineText(event.target.value)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  fontWeight={"600"}
                  fontSize={{ xs: "14px", sm: "16px" }}
                >
                  Clinical Information
                </Typography>
                <Grid container spacing={2} marginTop={"5px"}>
                  <Grid item xs={6} sm={4} md={3}>
                    <Input
                      labelInput="PSA(of/ml)"
                      value={psa}
                      onChange={(event) => setPsa(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <Input
                      labelInput="% Free PSA."
                      value={freePsa}
                      onChange={(event) => setFreePsa(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  fontWeight={"600"}
                  fontSize={{ xs: "14px", sm: "16px" }}
                >
                  Specimens (other than prostate, list below)
                </Typography>
                <Stack flexDirection={{ xs: "column", sm: "row" }} gap={2}>
                  {specimenTypeData.map((res, i) => {
                    return (
                      <>
                        <Box width={"100%"}>
                          <Input
                            labelInput="1"
                            value={res.specimentsType1}
                            onChange={(e) =>
                              specimenTypeHandler(
                                i,
                                "specimentsType1",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                        <Box width={"100%"}>
                          <Input
                            labelInput="2"
                            value={res.specimentsType2}
                            onChange={(e) =>
                              specimenTypeHandler(
                                i,
                                "specimentsType2",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                        <Box width={"100%"}>
                          <Input
                            labelInput="3"
                            value={res.specimentsType3}
                            onChange={(e) =>
                              specimenTypeHandler(
                                i,
                                "specimentsType3",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                        <Box width={"100%"}>
                          <Input
                            labelInput="4"
                            value={res.specimentsType4}
                            onChange={(e) =>
                              specimenTypeHandler(
                                i,
                                "specimentsType4",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                        <Box width={"100%"}>
                          <Input
                            labelInput="5"
                            value={res.specimentsType5}
                            onChange={(e) =>
                              specimenTypeHandler(
                                i,
                                "specimentsType5",
                                e.target.value
                              )
                            }
                          />
                        </Box>
                      </>
                    );
                  })}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container marginTop={3} spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.cardContainer}>
              <Table stickyHeader aria-label="simple table">
                <TableBody>
                  <StyledTableRow>
                    <TableCell sx={{ textAlign: "center !important" }}>
                      Body Site
                    </TableCell>
                    <TableCell>Sub Site</TableCell>
                    <TableCell>Procedure</TableCell>
                  </StyledTableRow>
                  {bodySubProcedureData.map((data, i) => {
                    return (
                      <StyledTableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"flex-end"}
                          >
                            <Typography
                              fontWeight={"700"}
                              sx={{ width: "20px" }}
                              fontSize={{ xs: "13px", sm: "16px" }}
                            >
                              {i + 1}.
                            </Typography>
                            <Input
                              boxWidth="100%"
                              variant="standard"
                              value={data.BodySite}
                              onChange={(e) =>
                                handleChange(i, "BodySite", e.target.value)
                              }
                            />
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Input
                            variant="standard"
                            value={data.SubSite}
                            onChange={(e) =>
                              handleChange(i, "SubSite", e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Input
                            variant="standard"
                            value={data.Procedure}
                            onChange={(e) =>
                              handleChange(i, "Procedure", e.target.value)
                            }
                          />
                        </TableCell>
                        {/* <TableCell component="th" scope="row"><Input variant='standard' /></TableCell> */}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
            <ProstateImage bodySubProcedureData={bodySubProcedureData} selectedImage={selectedImage} setSelectedImage={setSelectedImage} handleItemRemoval={handleItemRemoval} prostateSelectedImageList={prostateSelectedImageList} setBodySubProcedureData={setBodySubProcedureData} setProstateSelectedImageList={setProstateSelectedImageList} />
          </Grid>
        </Grid>

        {location.pathname === "/admin/submitted-forms/view" ? (
          ""
        ) : (
          <>
            <Divider
              sx={{
                marginTop: "20px !important",
                marginBottom: "16px !important",
              }}
            />
            <Stack
              gap={"10px"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button buttonName="Cancel" onClick={() => navigate(-1)} />
              <Button
                buttonName="Submit"
                onClick={() => [formik.handleSubmit(), validationHandler()]}
              />
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default ProstateReq;
