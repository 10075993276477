import React, { useState, useEffect } from 'react'
import { useStyles } from '../../Helper/Theme';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { FaRegUser, MdOutlineModeEditOutline } from '../../Helper/Icons'
import { Button, Input, Modal } from '../../Component/Commons';
import { getProfile, updateProfile } from "../../Service/profile.service"
import { useFormik } from "formik";
import { profileInitialValues } from '../../Helper/initialValues'
import { profileValidationSchema } from '../../Helper/validation';
import { showNotification } from '../../Helper/Notification'
import { EventEmitter } from '../../Helper/Helper';
import Date from '../../Component/Commons/Date'
import dayjs from 'dayjs'

const Profile = () => {
  const classes = useStyles();
  const [edit, setEdit] = useState();
  const [profileDetails, setProfileDetails] = useState({});
  const handleClick = () => setEdit((show) => !show);
  const [file, setFile] = useState();
  const [selectedFile, setSelectedFile] = useState({});
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });


  const formik = useFormik({
    initialValues: profileInitialValues,
    onSubmit: async (value) => {
      let formData = new FormData();
      formData.append("first_name", value.fname);
      formData.append("last_name", value.lname);
      formData.append("phone", value.phone);
      formData.append("address", value.address);
      formData.append("profile_image", selectedFile);
      formData.append("birthdate", dayjs(value.dob).format('YYYY-MM-DD'));
      formData.append("fax", value.fax);

      const res = await updateProfile(formData);
      if (res.status) {
        handleClick()
        EventEmitter.dispatch('profileUpdate', true);
        showNotification("success", res.message || 'Login Successfully')
      } else {
        showNotification("error", res.message || 'Something went wrong')
      }
    },
    validationSchema: profileValidationSchema,
  });

  function handleChange(e) {
    const baseFile = e.target.files[0]
    if (baseFile && baseFile.type.substring(0, 5) === "image") {
      setSelectedFile(baseFile)
      setFile(URL.createObjectURL(baseFile));
      // convert e.target.files to  base 64 string
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        setFile(reader.result);
      };
    }
  }

  const getProfileDetails = async () => {
    const res = await getProfile();
    if (res.status) {
      setFile(res?.data?.profile_image)
      formik.setValues({
        ...formik.values,
        fname: res?.data?.first_name,
        lname: res?.data?.last_name,
        phone: res?.data?.phone,
        email: res?.data?.email,
        address: res?.data?.address,
        fax: res?.data?.fax,
        username:res?.data.username,
        dob: res?.data?.birthdate,
      })
      setProfileDetails(res.data);
    } else {
      setProfileDetails({})
    }
  }

  useEffect(() => {
    getProfileDetails();
  }, [])

  return (
    <>
      <Box className={classes.cardContainer} sx={{ padding: { xs: 2, sm: 5 } }}>
        <Grid container spacing={{ xs: '30px', sm: '50px' }} justifyContent={'space-between'} sx={{ maxWidth: '1200px', margin: 'auto' }}>
          <Grid item xs={12} sm={3}>
            <Grid container sx={{ width: { xs: '100%', sm: '200px' } }}>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                {
                  !edit ?
                    <Box sx={{ width: '110px', height: '110px', background: '#f1f1f1', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {
                        file ?
                          <img src={file} width={110} height={110} style={{ borderRadius: '50%' }} />
                          :
                          <FaRegUser style={{ fontSize: '50px', color: '#00000091' }} />
                      }
                    </Box>
                    :
                    <Box sx={{ width: '110px', height: '110px', background: '#f1f1f1', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                      {
                        file ?
                          <img src={file} width={110} height={110} style={{ borderRadius: '50%' }} />
                          :
                          <FaRegUser style={{ fontSize: '50px', color: '#00000091' }} />
                      }
                      <Box sx={{ position: 'absolute', cursor: 'pointer', bottom: '14px', right: '0px', borderRadius: '50%' }}>
                        <Box sx={{ position: 'relative', width: '22px', height: '22px' }}>
                          <MdOutlineModeEditOutline style={{ position: 'relative', background: '#000', color: '#fff', fontSize: '22px', padding: '4px', borderRadius: '50%' }} />
                          <input type="file" onChange={handleChange} style={{ position: 'absolute', opacity: '0', width: '100%', height: '100%', right: '0' }} accept="image/png, image/gif, image/jpeg" />
                        </Box>
                      </Box>
                    </Box>
                }

              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={2}>
                <Typography fontSize={'20px'} fontWeight={700}>{`${formik.values.fname || ""} ${formik.values.lname || ""}`}</Typography>
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={2}>
                <Button buttonName='Change Password ' onClick={() => setIsModalOpen({ open: true, currentComponent: "userChangePassword", })} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={3} justifyContent={'space-between'}>
              <Grid item xs={12} sm={6}>
                <Input name="fname" labelInput='First Name' formik={formik} disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input name="lname" labelInput='Last Name' formik={formik} disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input formik={formik} name="email" labelInput='Email' disabled={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input formik={formik} name="username" labelInput='Username' disabled={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input formik={formik} name="address" labelInput='Address' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input formik={formik} name="phone" labelInput='Phone Number' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input placeholder='Fax' name="fax" formik={formik} labelInput='Fax' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Date
                  labelInput='Date of Birth'
                  disabled={edit ? false : true}
                  onChange={(date) => {
                    formik.setFieldValue(
                      'dob',
                      date.format('MM-DD-YYYY'),
                    );
                    formik.setFieldTouched('dob', false);
                  }}
                  formik={formik}
                  format="MM-DD-YYYY"
                  placeholder="Start Date"
                  name="dob"
                  value={
                    formik.values.dob == ''
                      ? ""
                      : dayjs(formik.values.dob).format(
                        'MM-DD-YYYY',
                      )
                  }
                />
                {formik?.touched['dob'] &&
                  formik?.errors['dob'] ? (
                  <div
                    style={{
                      color: '#D32F2F',
                      fontSize: '12px',
                      marginTop: '0px',
                      marginBottom: '10px',
                      textAlign: 'left',
                      marginLeft: '5px',
                    }}
                  >
                    {formik?.errors['dob']}
                  </div>
                ) : (
                  <div className="error"></div>
                )}
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Input value='Admin' labelInput='Role' disabled={edit ? false : true} />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={'end'}>
            {
              edit ?
                <Stack gap={2} flexDirection={'row'} justifyContent={'flex-end'}>
                  <Button buttonName='Cancel ' onClick={handleClick} />
                  <Button buttonName='Save' onClick={formik.handleSubmit} />
                </Stack>
                :
                <Button buttonName='Edit' onClick={handleClick} />
            }
          </Grid>
        </Grid>
      </Box>

      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        } />
    </>
  )
}

export default Profile