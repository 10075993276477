import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import { Button, Input } from '../Commons'
import React from "react";
import { CloseIcon } from "../../Helper/Icons";
import { useFormik } from "formik";
import { userChangePasswordIv } from "../../Helper/initialValues";
import { updatePassValidation, userChangePasswordValidation } from "../../Helper/validation";
import { userChangePasswordHandler } from "../../Service/manageuser.service";
import { showNotification } from "../../Helper/Notification";
import { useDispatch } from "react-redux";
import { logoutApi } from "../../state/action/authAction";
import { useNavigate } from 'react-router-dom';

function UserChangePassword({ handleClose, modalOpen }) {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const saveHandler = async () => {
    if(formik.values.new_password !== formik.values.confirm_password){
      formik.setFieldError('confirm_password','New and confirm passwords must be the same');
      return;
    }
    const payload ={
      new_password: formik.values.new_password,
      confirm_password:formik.values.confirm_password
    }
    const {data,message,status} = await userChangePasswordHandler(payload);
    if(status){
      handleClose();
      dispatch(logoutApi())
                    Navigate("/login")
      showNotification("success", message || 'Password has been updated successfully')
    }else{
      handleClose();
      showNotification("error", message || 'Failed to change password')
    }
  };

  const formik = useFormik({
    initialValues: userChangePasswordIv,
    validationSchema: userChangePasswordValidation,
    onSubmit: saveHandler,
  });

  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Change Password
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleClose(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              placeholder="New Password"
              passwordInput
              formik={formik}
              name="new_password"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder="Confirm Password"
              passwordInput
              formik={formik}
              name="confirm_password"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel "
          buttonColor="gray"
          onClick={() => handleClose(false)}
        />
        <Button buttonName="Save" onClick={formik.handleSubmit} />
      </DialogActions>
    </div>
  );
}

export default UserChangePassword;
