import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { ChangePassword1, Logout, ExportSuccess, Delete } from '../modal';
import { AddUser, ViewUser } from '../../Pages/usermanagement';
import UserChangePassword from '../modal/UserChangePassword';

export default function CommonModal({ handleClose, clickSave, passChangeUserId,confirmDelete, refreshApi, modalOpen }) {

    return (
        // 
        <Dialog
            onClose={handleClose}
            aria-labelledby="Common_modal"
            open={modalOpen.open}
        >
            {modalOpen.currentComponent === 'changePassword' &&
                <ChangePassword1 handleClose={handleClose} passChangeUserId={passChangeUserId} modalOpen={modalOpen} />}
            {modalOpen.currentComponent === 'userChangePassword' &&
                <UserChangePassword handleClose={handleClose} modalOpen={modalOpen} />}
            {modalOpen.currentComponent === 'logout' &&
                <Logout handleClose={handleClose} modalOpen={modalOpen} />}
            {modalOpen.currentComponent === 'exportSuccess' && (
                <ExportSuccess handleClose={handleClose} modalOpen={modalOpen} />)}

            {modalOpen.currentComponent === 'addUser' &&
                <AddUser handleClose={handleClose} refreshApi={refreshApi} modalOpen={modalOpen} />}
            {modalOpen.currentComponent === 'viewUser' &&
                <ViewUser handleClose={handleClose} modalOpen={modalOpen} />}
            {/* {modalOpen.currentComponent === 'delete' &&
                <Delete handleClose={handleClose} modalOpen={modalOpen} />} */}
        </Dialog>
    );
}
