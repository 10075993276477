import { Box, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CloseIcon } from '../../Helper/Icons'
import moment from "moment";
import Skeleton from '@mui/material/Skeleton';

const ViewUser = ({ handleClose, modalOpen }) => {
    const { data } = modalOpen;
    const [skalaton, setSkalaton] = useState("")

    useEffect(() => {
        setTimeout(() => {
            setSkalaton("false")
        }, 1000)
    }, [data])

    return (
        <div>

            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {skalaton == "" && <Skeleton width="50%">
                    <Typography>.</Typography>
                </Skeleton>}
                {skalaton == "false" && "Details of User"}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container gap={2} width={{ xs: '250px', sm: '400px' }} paddingY={1}>

                    <Grid item xs={12}>
                        <Box sx={{ background: '#f1f1f1', borderRadius: '15px', padding: '20px' }}>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>

                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>Name :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'}> {`${data?.full_name ? data?.full_name : '-'}`} </Typography>}
                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>Facility Code :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'}>{data?.facility_code}</Typography>}
                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>Facility Name :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'}>{data?.facility_name ? data?.facility_name : '-'}</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>Address :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                    {skalaton == "false" && <Typography fontSize={'13px'}>{data?.address ? data?.address : "-"}</Typography>}
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>Email :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'}>{data?.email ? data?.email : "-"}</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>User Name :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'}>{data?.username ? data?.username : "-"}</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>Phone :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'}>{data?.phone ? data?.phone : '-'}</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'} fontWeight={600}>Fax :</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {skalaton == "false" && <Typography fontSize={'13px'}>{data?.fax ? data?.fax : '-'}</Typography>}

                                    {skalaton == "" && <Skeleton width="90%">
                                        <Typography>.</Typography>
                                    </Skeleton>}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                </Grid>
            </DialogContent>

        </div>
    )
}

export default ViewUser