export const loginInitialValues = {
  username:"",
  password: "",
  remeberme: false,
};

export const updatePassword = {
  new_pass: "",
  confirm_new_pass: "",
};

export const profileInitialValues = {
  fname: "",
  lname: "",
  email: "",
  username:"",
  phone: "",
  address: "",
  profile_image: {},
  dob: null,
  fax: "",
};

export const addUserInitialValues = {
  first_name: "",
  last_name: "",
  group_id: 2,
  facility_code: "",
  email: "",
  username:"",
  address: "",
  phone: "",
  fax: "",
  facility_name: "",
  password: "",
};

export const referByUserIv = {
  facility_name: "",
  doctor: "",
  first_name: "",
  last_name: "",
  address: "",
  phone: "",
  fax: "",
};

export const patientInformationIv = {
  patient_first_name: "",
  patient_last_name: "",
  initial: "",
  patient_address: "",
  patient_city: "",
  patient_state: "",
  patient_zip: "",
  patient_area_code: "",
  patient_age: "",
  patient_sex: "",
  patient_dob: "",
  patient_phone: "",
  patient_payment: "",
  pay_amount: "",
  bill_number: "",
  bill_amount: "",
  insurance_company: "",
  insurance_no: "",
  icd_10: "",
  clinical_history: "",
  additional_physician: "",
  other_test: "",
  pationtDoc : [],
};

export const nonGynCytopathologyRequisitionIV = {
  referByUser: referByUserIv,
  patientInformation: patientInformationIv,
  testingType: [],
  bodySubProcedure: [],
};


export const userChangePasswordIv = {
  new_password: "",
  confirm_password: ""
}


// SURGICAL FORM 

export const SurgicalPathologyIv = {
  referByUser: referByUserIv,
  patientInformation: patientInformationIv,
  pathologySubmit: [],
  pathology_specimens: [],
  breast_table: [],
  consultant_surgery: [],
  box_text: "",
  cytology_specimens: [],
  cytology_submitted: []
}

// PROSTATE FORM 

export const prostateIv = {
  referByUser: referByUserIv,
  patientInformation: patientInformationIv,
  prostateIcd10Codes: "",
  bladderIcd10Codes: "",
  specimenType: "",
  urineCytologySource: "",
  psaOfMl: "",
  free_psa: "",
  specimensOtherThanProstate: "",
  bodysiteSubsiteProcedure: "",
  select: ""
}

export const molecularIv = {
  referByUser: referByUserIv,
  patientInformation: patientInformationIv,
  box_text: "",
  physician_order:[],
  pneumonia_panel:[],
  urine:[],
  apex_dx_utm_swab:[],
}

export const hamepathologyIv = {
  referByUser: referByUserIv,
  patientInformation: patientInformationIv,
  specimen_information_1:[],
  specimen_information_other1:"",
  diagnosis_symptoms:"",
  treatment_status:[],
  therapy_other:"",
  therapy:[],
  bone_marrow_transplant_type:[],
  lymphoproliferative:[],
  myeloproliferative_neoplasms:[],
  plasma_cell_neoplasms:[],
  multiple_myeloma:[],
  test_menu:[],
  flow_cytometry:[],
  chromosome_analysis:[],
  bone_marrow_morphology:[],
  circulating_tumor_cell_analysis:[],
  pcr:[],
  pcr_other:"",
  fish_panels:[],
  induvidual_fish_assays:[],
  individual_fish_assays_other:"",
  myeloproliferative_neoplasms_other:"",
  specimen_information_2 :[],
  specimen_information_other2:"",
  specimen_information_3:[],
  specimen_information_other3:"",
  specimen_information_4:[],
  specimen_type_of_stain:[],
  specimen_information_other_5:"",
  specimen_information_other:"",
  treatment_status:[],
  collectionTime: "",
  collectionDate : "",
  bodySite:"",
  test_menu_text:"",
}

export const gynIv = {
  referByUser: referByUserIv,
  patientInformation: patientInformationIv,
  submit_in_formalin1: "",
  submit_in_formalin2: "",
  submit_in_formalin3: "",
  submit_in_formalin4: "",
  submit_in_formalin5: "",
  lmp: null,
  icd_10_code: "",
  diagnosis: "",
  specimen_source: [],
  previous_pap_dx:"",
  clinical_Impression:"",
  hormonal_therapy:[],
  hormonal_therapy_other:"",
  thinprep_pap_smear:[],
  thinprep_with_reflex_on_ascus:[],
  thinprep_with_reflex_on_ascus_or_higher:[],
  bacterial_vaginosis:[],
  candida:[],
  mycoplasma:[],
  hsv_1_2:[],
  trichomonas:[],
  gbs:[],
  icd_10_codes:[],
  blank_box:"",
  ct_gc:[],
  affirm_testbd_Vaginala_endocervical:[],
}

export const podiatryIv = {
  referByUser: referByUserIv,
  patientInformation: patientInformationIv,
  right_nail_biopsy:[],
  right_skin_soft_tissue_bone_biopsy:[],
  right_leg_image:"",
  left_leg_image:"",
  right_aspirate_joint_fluid:[],
  right_aspirate_cyst_or_other_fluid:[],
  right_microbiology_only:[],
  right_additional_test_notes:"",
  left_nail_biopsy:[],
  left_skin_soft_tissue_bone_biopsy:[],
  left_aspirate_joint_fluid:[],
  left_aspirate_cyst_or_other_fluid:[],
  left_microbiology_only:[],
  left_additional_test_notes:"",
  right_leg_specymen:"",
  left_leg_specymen:"",
  right_leg_codinet:[],
  left_leg_codinet:[],

}

export const formFilterIv = {
  startDate: "",
  endDate: "",
  patient_state: "",
  patient_city:"",
  patient_sex:"",
  form_type:"",
  search:"",
  totalCount: 0,
  totalCountData: 0,

};

export const userFilterIv = {
  search : "",
  startDate : "",
  endDate : "",
  totalCount: 0,
  totalCountData: 0,
  hospital: "",

};