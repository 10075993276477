import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Button, Checkbox, Modal } from "../../../../Component/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon, FiFileText } from "../../../../Helper/Icons";
import { useStyles } from "../../../../Helper/Theme";
import UserDetails from "../../UserDetails";
import { useFormik } from "formik";
import { molecularIv } from "../../../../Helper/initialValues";
import { molecularValidation } from "../../../../Helper/validation";
import { showNotification } from "../../../../Helper/Notification";
import { createMolecularFormhandler } from "../../../../Service/molecular.service";
import { useSelector } from "react-redux";
import { getProfile } from "../../../../Service/profile.service";
import { savePatientDoc } from "../../../../Service/commanForm.service";

const Molecular = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state?.root?.auth);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [exportFile, setExportFile] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [referByUser, setReferByUser] = useState({});
  const [consultantText, setConsultantText] = useState("");

  const handleClickExport = (event) => {
    setExportFile(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportFile(null);
  };

  const validationHandler = () => {
    setIsFormSubmitted(true)
    if (Object.keys(formik.errors).length > 0) {
      showNotification("error", 'Required filed is missing')
    }
  }

  const submitFormHandler = async () => {
    const payload = {
      "patient_information": {
        "patient_first_name": formik.values.patientInformation.patient_first_name,
        "patient_last_name": formik.values.patientInformation.patient_last_name,
        "initial": formik.values.patientInformation.initial,
        "patient_address": formik.values.patientInformation.patient_address,
        "patient_city": formik.values.patientInformation.patient_city,
        "patient_state": formik.values.patientInformation.patient_state,
        "patient_zip": formik.values.patientInformation.patient_zip,
        "patient_area_code": formik.values.patientInformation.patient_area_code,
        "patient_age": formik.values.patientInformation.patient_age,
        "patient_sex": formik.values.patientInformation.patient_sex,
        "patient_dob": formik.values.patientInformation.patient_dob,
        "patient_phone": formik.values.patientInformation.patient_phone,
        "patient_payment": formik.values.patientInformation.patient_payment,
        "bill_number": formik.values.patientInformation.bill_number,
        "bill_amount": formik.values.patientInformation.bill_amount,
        "insurance_company": formik.values.patientInformation.insurance_company,
        "insurance_no": formik.values.patientInformation.insurance_no,
        "pay_amount": formik.values.patientInformation.pay_amount,
        "icd_10": formik.values.patientInformation.icd_10,
        "clinical_history": formik.values.patientInformation.clinical_history,
        "additional_physician": formik.values.patientInformation.additional_physician,
        "other_test": formik.values.patientInformation.other_test
      },
      "molecular_requisition_information": {
        "physician_order": formik.values.physician_order,
        "apex_dx_utm_swab": formik.values.apex_dx_utm_swab,
        "urine": formik.values.urine,
        "pneumonia_panel": formik.values.pneumonia_panel
      }
    }

    const { data, message, status } = await createMolecularFormhandler(payload);
    if (status) {
      if (formik.values.patientInformation.pationtDoc && formik.values.patientInformation.pationtDoc.length > 0) {
        savePatientDocHandler(data?.patient_information?.id)
      } else {

        showNotification("success", message || 'Form Register Successfully');
        if (user.group_name === "provider") {
          navigate(`/submitted-forms`)
        } else {
          navigate(`/admin/submitted-forms`);
        }
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const savePatientDocHandler = async (id) => {
    const formData = new FormData();
    formData.append("patient_document", formik.values.patientInformation.pationtDoc[0]);
    const { data, message, status } = await savePatientDoc(id, formData);
    if (status) {
      showNotification("success", message || 'Form Register Successfully');
      if (user.group_name === "provider") {
        navigate(`/submitted-forms`)
      } else {
        navigate(`/admin/submitted-forms`);
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const formik = useFormik({
    initialValues: molecularIv,
    validationSchema: molecularValidation,
    onSubmit: submitFormHandler,
  });

  useEffect(() => {
    referredByDataHandler();
  }, []);

  const referredByDataHandler = async () => {
    const { data, message, status } = await getProfile();
    if (status) {
      setReferByUser(data);
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data.facility_name,
          doctor: data.full_name,
          address: data.address,
          phone: data.phone,
          fax: data.fax,
        },
      });
    }
  };

  const handleOrderChange = (formik, orderFieldName, e) => {
    const currentValue = formik.values[orderFieldName];
    if (e.target.checked) {
      formik.setFieldValue(orderFieldName, [...currentValue, e.target.value]);
    } else {
      formik.setFieldValue(orderFieldName, currentValue.filter(item => item !== e.target.value));
    }
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", background: "#8cc63f", color: "#fff" }}
        />
        <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          {location.pathname === "/admin/submitted-forms/view" ? (
            <Button buttonName="Export" onClick={handleClickExport} />
          ) : (
            ""
          )}
        </Stack>
        <Menu
          anchorEl={exportFile}
          open={Boolean(exportFile)}
          onClose={handleCloseExport}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            value="csv"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseExport}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <FiFileText style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>PDF</div>
            </Stack>
          </MenuItem>
          <MenuItem
            value="excel"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseExport}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <FiFileText style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>HL7</div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
      <Box
        className={classes.cardContainer}
        padding={{ xs: "12px 10px", sm: 3 }}
      >
        <UserDetails formik={formik} isFormSubmitted={isFormSubmitted} />

        <Typography fontWeight={"700"} fontSize={{ xs: "15px", sm: "18px" }}>
          Physician's Orders
        </Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={{ xs: 2, sm: 3 }}>
                <Grid item xs={12}>
                  <Checkbox
                    label="COVID19"
                    singleCheckbox
                    value='COVID19'
                    checkboxStyle={{ padding: "0 10px" }}
                    checked={formik.values.physician_order.includes('COVID19')}
                    onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Bordetella PCR Panel ( pertussis, parapertussis, hoirnesii )"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Bordetella PCR Panel (pertussis, parapertussis, hoirnesii)'
                    checked={formik.values.physician_order.includes('Bordetella PCR Panel (pertussis, parapertussis, hoirnesii)')}
                    onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Nasophary ngeai swab specimen
                    </Typography>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      ( UTM or blue top BD ESWab )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Group A Strep ( Nucieic acid arnollfication testing )"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Group A Strep ( Nucieic acid arnollfication testing )'
                    checked={formik.values.physician_order.includes('Group A Strep ( Nucieic acid arnollfication testing )')}
                    onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Throat swab specimen
                    </Typography>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      ( while top swab in liquid stuart media )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Influenza A and B (by PCR)"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Influenza A and B (by PCR)'
                    checked={formik.values.physician_order.includes('Influenza A and B (by PCR)')}
                    onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Nasopharyngeal swab specimen
                    </Typography>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      ( red top swab UTM kit )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Influenza A & B, RSV (by PCR)"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Influenza A & B, RSV (by PCR)'
                    checked={formik.values.physician_order.includes('Influenza A & B, RSV (by PCR)')}
                    onChange={(e) => handleOrderChange(formik, 'physician_order', e)}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Nasopharyngeal swab specimen
                    </Typography>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      ( red top swab UTM kit )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Respiratory Panel (21 targets) (by PCR)"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Respiratory Panel (21 targets) (by PCR)'
                    checked={formik.values.physician_order.includes('Respiratory Panel (21 targets) (by PCR)')}
                    onChange={(e) => handleOrderChange(formik, 'physician_order', e)}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Nasopharyngeal swab specimen
                    </Typography>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      ( BD Universel viral Traoget )
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontWeight={"600"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                  >
                    Pneumonia Panel
                  </Typography>
                  <Box marginLeft={3}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Sputum specimen
                    </Typography>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      ( Sputum specimen )
                    </Typography>
                  </Box>
                  <Checkbox
                    label="W/ culture"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='W/ culture'
                    checked={formik.values.pneumonia_panel.includes('W/ culture')}
                    onChange={(e) => handleOrderChange(formik, 'pneumonia_panel', e)}
                  />
                  <Checkbox
                    label="Without culture"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Without culture'
                    checked={formik.values.pneumonia_panel.includes('Without culture')}
                    onChange={(e) => handleOrderChange(formik, 'pneumonia_panel', e)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Checkbox
                    label="Oral HPV ( Scope rinse in sterile cup )"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Oral HPV ( Scope rinse in sterile cup )'
                    checked={formik.values.pneumonia_panel.includes('Oral HPV ( Scope rinse in sterile cup )')}
                    onChange={(e) => handleOrderChange(formik, 'pneumonia_panel', e)}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Also send Dacron swab in Thinprep vial
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontWeight={"600"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                  >
                    Apex Dx UTM Swab
                  </Typography>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Bacterial vaginosis"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Bacterial vaginosis'
                      checked={formik.values.apex_dx_utm_swab.includes('Bacterial vaginosis')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Candida"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Candida'
                      checked={formik.values.apex_dx_utm_swab.includes('Candida')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Mycoplasma/Ureaplasma"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Mycoplasma/Ureaplasma'
                      checked={formik.values.apex_dx_utm_swab.includes('Mycoplasma/Ureaplasma')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="HSV 1&2"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='HSV 1&2'
                      checked={formik.values.apex_dx_utm_swab.includes('HSV 1&2')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Trichomonas"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Trichomonas'
                      checked={formik.values.apex_dx_utm_swab.includes('Trichomonas')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="CT/GC (mark source)"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='CT/GC (mark source)'
                      checked={formik.values.apex_dx_utm_swab.includes('CT/GC (mark source)')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={7}>
                    <Checkbox
                      label="Vaginal"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Vaginal'
                      checked={formik.values.apex_dx_utm_swab.includes('Vaginal')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={7}>
                    <Checkbox
                      label="Throat"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Throat'
                      checked={formik.values.apex_dx_utm_swab.includes('Throat')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                  <Box marginLeft={7}>
                    <Checkbox
                      label="Rectal"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Rectal'
                      checked={formik.values.apex_dx_utm_swab.includes('Rectal')}
                      onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontWeight={"600"}
                    fontSize={{ xs: "14px", sm: "16px" }}
                  >
                    Urine
                  </Typography>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="CT/GC"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='CT/GC'
                      checked={formik.values.urine.includes('CT/GC')}
                      onChange={(e) => handleOrderChange(formik, 'urine', e)}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Trichomonas"
                      singleCheckbox
                      checkboxStyle={{ padding: "0 10px" }}
                      value='Trichomonas'
                      checked={formik.values.urine.includes('Trichomonas')}
                      onChange={(e) => handleOrderChange(formik, 'urine', e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="C. Diff only"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='C. Diff only'
                    checked={formik.values.urine.includes('C. Diff only')}
                    onChange={(e) => handleOrderChange(formik, 'urine', e)}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      (PCR for taxigenic C. dimelle and hypervirulent 027/NAP
                      1/8 straig)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Diff GDH and Toxin EIA "
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='Diff GDH and Toxin EIA'
                    checked={formik.values.urine.includes('Diff GDH and Toxin EIA')}
                    onChange={(e) => handleOrderChange(formik, 'urine', e)}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Stool specimen in Cary Blair or white top transport media
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="GI Panel (22 targets) (by PCR)"
                    singleCheckbox
                    checkboxStyle={{ padding: "0 10px" }}
                    value='GI Panel (22 targets) (by PCR)'
                    checked={formik.values.urine.includes('GI Panel (22 targets) (by PCR)')}
                    onChange={(e) => handleOrderChange(formik, 'urine', e)}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={"300"} fontSize={"14px"}>
                      Stool specimen in Cary Blair transport media
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {location.pathname === "/admin/submitted-forms/view" ? (
          ""
        ) : (
          <>
            <Divider
              sx={{
                marginTop: "20px !important",
                marginBottom: "16px !important",
              }}
            />
            <Stack
              gap={"10px"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button buttonName="Cancel" onClick={() => navigate(-1)} />
              <Button
                buttonName="Submit"
                onClick={() =>
                  [formik.handleSubmit(), validationHandler()]
                }
              />
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default Molecular;
