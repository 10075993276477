import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";

import { Button, Modal } from "../Commons";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon, FiFileText } from "../../Helper/Icons";
import {  useStyles } from "../../Helper/Theme";
import {
  exportCsvDataByFormId,
} from "../../Service/NonGynCytopathology.service";
import { showNotification } from "../../Helper/Notification";
import NonGynFormView from "../FormView/NonGynFormView";
import SurgicalFormView from "../FormView/SurgicalFormView";
import ProstateFormView from "../FormView/ProstateFormView";
import MolecularFormView from "../FormView/MolecularFormView";
import HematopathologyFormView from "../FormView/HematopathologyFormView";
import GynecologicalCytologyFormView from "../FormView/GynecologicalCytologyFormView";
import PodiatryReqFormView from "../FormView/PodiatryReqFormView";

const CommanFormView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [exportFile, setExportFile] = useState(null);
  const classes = useStyles();
  

  const handleCloseExport = () => {
    setExportFile(null);
  };

  const HandleExportHandler = async (formId,formType) => {
    const { data, message, status } = await exportCsvDataByFormId(formId,formType);
    if (status) {
      const downloadLink = document.createElement("a");
      downloadLink.href = data[0].file_url;
      downloadLink.download = `${state.firstName} ${state.lastName}.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setIsModalOpen({
        open: true,
        currentComponent: "exportSuccess",
      });
    } else {
      showNotification("error", message || "Something went wrong");
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", background: "#8cc63f", color: "#fff" }}
        />
        <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          {location.pathname === "/admin/submitted-forms/view" ? (
            <Button
              buttonName="Export"
              onClick={() => HandleExportHandler(state.formId,state.formType)}
            />
          ) : (
            ""
          )}
        </Stack>
        <Menu
          anchorEl={exportFile}
          open={Boolean(exportFile)}
          onClose={handleCloseExport}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            value="csv"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseExport}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <FiFileText style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>PDF</div>
            </Stack>
          </MenuItem>
          <MenuItem
            value="excel"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "exportSuccess",
              })
            }
            onMouseUp={handleCloseExport}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <FiFileText style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>HL7</div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
      <Box
        className={classes.cardContainer}
        padding={{ xs: "12px 10px", sm: 3 }}
      >
        {
          state.formType === "NonGynCytopathologyRequisition" && <NonGynFormView/>
        }

        {
          state.formType === "SurgicalPathology" && <SurgicalFormView/>
        }
        
        {
          state.formType === "ProstateRequisition" && <ProstateFormView/>
        }
           {
          state.formType === "MolecularRequisition" && <MolecularFormView/>
        }
         {
          state.formType === "HematopathologyRequisition" && <HematopathologyFormView/>
        }
        {
          state.formType === "GynecologicalCytologyRequisition" && <GynecologicalCytologyFormView/>
        }
        {
          state.formType === "PodiatryRequisition" && <PodiatryReqFormView/>
        }
        
      </Box>
    </>
  );
};

export default CommanFormView;
