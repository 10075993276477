import React, { useEffect, useState } from 'react'
import { useStyles } from '../../Helper/Theme';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { Button } from '../../Component/Commons';
import { useLocation, useNavigate } from 'react-router-dom';
import { CytopathologycheckboxOptions, GynecologicalCytologycheckboxOptions, SurgicalPathologycheckboxOptions, hematopathologycheckboxOptions, molecularcheckboxOptions, podiatrycheckboxOptions, prostatecheckboxOptions } from '../../Helper/Helper';
import Skeleton from '@mui/material/Skeleton';

const CreateTest = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [routePath, setRoutePath] = useState('');
  const [skalaton, setSkalaton] = useState("")

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    switch (selectedValue) {
      case 'SurgicalPathology':
        setRoutePath(`${location.pathname}/surgical-pathology-requisition-form`);
        break;
      case 'Cytopathology':
        setRoutePath(`${location.pathname}/cytopathology-requisition-form`);
        break;
      case 'GynecologicalCytology':
        setRoutePath(`${location.pathname}/gynecological-cytology-requisition-form`);
        break;
      case 'Molecular':
        setRoutePath(`${location.pathname}/molecular-requisition-form`);
        break;
      case 'Hematopathology':
        setRoutePath(`${location.pathname}/hematopathology-requisition-form`);
        break;
      case 'Podiatry':
        setRoutePath(`${location.pathname}/podiatry-requisition-form`);
        break;
      case 'Postater':
        setRoutePath(`${location.pathname}/prostater-requisition-form`);
        break;
      default:
    }
  };
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const handleChange = (event) => {
    setSelectedCheckbox(event.target.value);
  };

  useEffect(() => {

    setTimeout(() => {
      setSkalaton("false")
    }
      , 1000)
  }, [selectedCheckbox])



  return (
    <>
      <Stack direction="row" justifyContent="flex-end" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px', alignItems: { xs: 'end', sm: 'center' } }}>
        <Button buttonName='Next' onClick={() => navigate(routePath)} />
      </Stack>
      <FormControl className='form_select' sx={{ width: '100%' }}>
        <FormGroup onChange={handleRadioChange}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className={classes.cardContainer} padding={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                {skalaton == "" && <Skeleton width="50%" height="14%">
                  <Typography>.</Typography>
                </Skeleton>}

                {skalaton == "false" && <FormControlLabel
                  sx={{ marginLeft: '1px' }}
                  value='SurgicalPathology'
                  control={<Checkbox sx={{ padding: '5px', marginRight: '5px' }} checked={selectedCheckbox === 'SurgicalPathology'} onChange={handleChange} value="SurgicalPathology" />}
                  label={
                    <Typography style={{ fontWeight: '700', lineHeight: '1.2' }}>
                      Surgical Pathology
                    </Typography>
                  }
                />}
                <Divider />
                <Grid container marginTop={2} gap={1}>
                  {skalaton == "false" && SurgicalPathologycheckboxOptions?.map((option, i) => {
                    return (
                      <Grid item xs={12} sm={5.8} key={i} gap={'10px'} display={'flex'} flexDirection={'row'} minHeight={'28px'} alignItems={'center'}>
                        <Divider sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.32)!important' }} orientation="vertical" flexItem /><Typography fontSize={'14px'}>{option}</Typography>
                      </Grid>
                    )
                  }
                  )}
                  {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '32%' }} />
                  </Skeleton>}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.cardContainer} padding={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                {skalaton == "false" && <FormControlLabel
                  sx={{ marginLeft: '1px' }}
                  value='Cytopathology'
                  control={<Checkbox sx={{ padding: '5px', marginRight: '5px' }} checked={selectedCheckbox === 'Cytopathology'} onChange={handleChange} value="Cytopathology" />}
                  label={
                    <Typography style={{ fontWeight: '700', lineHeight: '1.2' }}>
                      Non-Gyn Cytopathology
                    </Typography>
                  }
                />}
                {skalaton == "" && <Skeleton width="50%" height="14%">
                  <Typography>.</Typography>
                </Skeleton>}
                <Divider />
                <Grid container marginTop={2} gap={1}>
                  {skalaton == "false" && CytopathologycheckboxOptions.map((option, i) => {
                    return (
                      <Grid item xs={12} sm={5.8} key={i} gap={'10px'} display={'flex'} flexDirection={'row'} minHeight={'28px'} alignItems={'center'}>
                        <Divider sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.32)!important' }} orientation="vertical" flexItem /><Typography fontSize={'14px'}>{option}</Typography>
                      </Grid>
                    )
                  }
                  )}
                  {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '32%' }} />
                  </Skeleton>}
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={classes.cardContainer} padding={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                {skalaton == "false" && <FormControlLabel
                  sx={{ marginLeft: '1px' }}
                  value='Molecular'
                  control={<Checkbox sx={{ padding: '5px', marginRight: '5px' }} checked={selectedCheckbox === 'Molecular'} onChange={handleChange} value="Molecular" />}
                  label={
                    <Typography style={{ fontWeight: '700', lineHeight: '1.2' }}>
                      Molecular
                    </Typography>
                  }
                />}
                {skalaton == "" && <Skeleton width="50%" height="8%">
                  <Typography>.</Typography>
                </Skeleton>}
                <Divider />
                <Grid container marginTop={2} gap={1}>
                  {skalaton == "false" && molecularcheckboxOptions?.map((option, i) => {
                    return (
                      <Grid item xs={12} sm={5.8} key={i} gap={'10px'} display={'flex'} flexDirection={'row'} minHeight={'28px'} alignItems={'center'}>
                        <Divider sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.32)!important' }} orientation="vertical" flexItem /><Typography fontSize={'14px'}>{option}</Typography>
                      </Grid>
                    )
                  }
                  )}
                  {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '59%' }} />
                  </Skeleton>}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.cardContainer} padding={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                {skalaton == "false" && <FormControlLabel
                  sx={{ marginLeft: '1px' }}
                  value='Hematopathology'
                  control={<Checkbox sx={{ padding: '5px', marginRight: '5px' }} checked={selectedCheckbox === 'Hematopathology'} onChange={handleChange} value="Hematopathology" />}
                  label={
                    <Typography style={{ fontWeight: '700', lineHeight: '1.2' }}>
                      Hematopathology
                    </Typography>
                  }
                />}

                {skalaton == "" && <Skeleton width="50%" height="8%">
                  <Typography>.</Typography>
                </Skeleton>}
                <Divider />
                <Grid container marginTop={2} gap={1}>
                  {skalaton == "false" && hematopathologycheckboxOptions?.map((option, i) => {
                    return (
                      <Grid item xs={12} sm={5.8} key={i} gap={'10px'} display={'flex'} flexDirection={'row'} minHeight={'28px'} alignItems={'center'}>
                        <Divider sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.32)!important' }} orientation="vertical" flexItem /><Typography fontSize={'14px'}>{option}</Typography>
                      </Grid>
                    )
                  }
                  )}

                  {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '59%' }} />
                  </Skeleton>}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.cardContainer} padding={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                {skalaton == "false" && <FormControlLabel
                  sx={{ marginLeft: '1px' }}
                  value='GynecologicalCytology'
                  control={<Checkbox sx={{ padding: '5px', marginRight: '5px' }} checked={selectedCheckbox === 'GynecologicalCytology'} onChange={handleChange} value="GynecologicalCytology" />}
                  label={
                    <Typography style={{ fontWeight: '700', lineHeight: '1.2' }}>
                      Gynecological Cytology
                    </Typography>
                  }
                />}

                {skalaton == "" && <Skeleton width="50%" height="18%">
                  <Typography>.</Typography>
                </Skeleton>}
                <Divider />
                <Grid container marginTop={2} gap={1}>
                  {skalaton == "false" && GynecologicalCytologycheckboxOptions?.map((option, i) => {
                    return (
                      <Grid item xs={12} sm={5.8} key={i} gap={'10px'} display={'flex'} flexDirection={'row'} minHeight={'28px'} alignItems={'center'}>
                        <Divider sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.32)!important' }} orientation="vertical" flexItem /><Typography fontSize={'14px'}>{option}</Typography>
                      </Grid>
                    )
                  }
                  )}

                  {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '20%' }} />
                  </Skeleton>}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.cardContainer} padding={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                {skalaton == "false" && <FormControlLabel
                  sx={{ marginLeft: '1px' }}
                  value='Podiatry'
                  control={<Checkbox sx={{ padding: '5px', marginRight: '5px' }} checked={selectedCheckbox === 'Podiatry'} onChange={handleChange} value="Podiatry" />}
                  label={
                    <Typography style={{ fontWeight: '700', lineHeight: '1.2' }}>
                      Podiatry
                    </Typography>
                  }
                />}
                {skalaton == "" && <Skeleton width="50%" height="18%">
                  <Typography>.</Typography>
                </Skeleton>}
                <Divider />
                <Grid container marginTop={2} gap={1}>
                  {skalaton == "false" && podiatrycheckboxOptions.map((option, i) => {
                    return (
                      <Grid item xs={12} sm={5.8} key={i} gap={'10px'} display={'flex'} flexDirection={'row'} minHeight={'28px'} alignItems={'center'}>
                        <Divider sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.32)!important' }} orientation="vertical" flexItem /><Typography fontSize={'14px'}>{option}</Typography>
                      </Grid>
                    )
                  }
                  )}
                  {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '20%' }} />
                  </Skeleton>}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.cardContainer} padding={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                {skalaton == "false" && <FormControlLabel
                  sx={{ marginLeft: '1px' }}
                  value='Postater'
                  control={<Checkbox sx={{ padding: '5px', marginRight: '5px' }} checked={selectedCheckbox === 'Postater'} onChange={handleChange} value="Postater" />}
                  label={
                    <Typography style={{ fontWeight: '700', lineHeight: '1.2' }}>
                      Prostate
                    </Typography>
                  }
                />}
                {skalaton == "" && <Skeleton width="50%" height="18%">
                  <Typography>.</Typography>
                </Skeleton>}
                <Divider />
                <Grid container marginTop={2} gap={1}>
                  {skalaton == "false" && prostatecheckboxOptions?.map((option, i) => {
                    return (
                      <Grid item xs={12} sm={5.8} key={i} gap={'10px'} display={'flex'} flexDirection={'row'} minHeight={'28px'} alignItems={'center'}>
                        <Divider sx={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.32)!important' }} orientation="vertical" flexItem /><Typography fontSize={'14px'}>{option}</Typography>
                      </Grid>
                    )
                  }
                  )}
                  {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '20%' }} />
                  </Skeleton>}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
      <Stack direction="row" justifyContent="flex-end" width='100%' sx={{ marginTop: { xs: '12px' }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px', alignItems: { xs: 'end', sm: 'center' } }}>
        <Button buttonName='Next' onClick={() => navigate(routePath)} />
      </Stack>
    </>

  )
}

export default CreateTest