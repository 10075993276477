import React, { useState, useEffect } from 'react'
import { Box, Divider, FormControlLabel, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { Button, Checkbox, Input, Modal } from '../../../../Component/Commons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, FiFileText } from '../../../../Helper/Icons';
import { useStyles } from '../../../../Helper/Theme';
import UserDetails from '../../UserDetails';
import { gynSpecimen, specimenList } from '../../../../Helper/Helper';
import { showNotification } from "../../../../Helper/Notification";
import { useFormik } from "formik";
import { hamepathologyIv } from "../../../../Helper/initialValues";
import { hamepathologyValidation } from "../../../../Helper/validation";
import { createHematopathologyFormhandler } from "../../../../Service/hematopathology.service";
import { useSelector } from "react-redux";
import { getProfile } from "../../../../Service/profile.service";
import { savePatientDoc } from "../../../../Service/commanForm.service";

const Hematopathology = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [referByUser, setReferByUser] = useState({});
  const { user } = useSelector((state) => state?.root?.auth);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);



  const validationHandler = () => {
    setIsFormSubmitted(true)
    if (Object.keys(formik.errors).length > 0) {
      showNotification("error", 'Required filed is missing')
    }
  }

  const submitFormHandler = async () => {
    const payload = {

      "patient_information": {
        "patient_first_name": formik.values.patientInformation.patient_first_name,
        "patient_last_name": formik.values.patientInformation.patient_last_name,
        "initial": formik.values.patientInformation.initial,
        "patient_address": formik.values.patientInformation.patient_address,
        "patient_city": formik.values.patientInformation.patient_city,
        "patient_state": formik.values.patientInformation.patient_state,
        "patient_zip": formik.values.patientInformation.patient_zip,
        "patient_area_code": formik.values.patientInformation.patient_area_code,
        "patient_age": formik.values.patientInformation.patient_age,
        "patient_sex": formik.values.patientInformation.patient_sex,
        "patient_dob": formik.values.patientInformation.patient_dob,
        "patient_phone": formik.values.patientInformation.patient_phone,
        "patient_payment": formik.values.patientInformation.patient_payment,
        "bill_number": formik.values.patientInformation.bill_number,
        "bill_amount": formik.values.patientInformation.bill_amount,
        "insurance_company": formik.values.patientInformation.insurance_company,
        "insurance_no": formik.values.patientInformation.insurance_no,
        "pay_amount": formik.values.patientInformation.pay_amount,
        "icd_10": formik.values.patientInformation.icd_10,
        "clinical_history": formik.values.patientInformation.clinical_history,
        "additional_physician": formik.values.patientInformation.additional_physician,
        "other_test": formik.values.patientInformation.other_test
      },
      "hematopathology_requisition_information": {
        "specimen_information_1": formik.values.specimen_information_1,
        "specimen_information_other1": formik.values.specimen_information_other1,
        "specimen_information_2": formik.values.specimen_information_2,
        "specimen_information_other2": formik.values.specimen_information_other2,
        "specimen_information_3": formik.values.specimen_information_3,
        "specimen_information_other3": formik.values.specimen_information_other3,
        "specimen_information_4": formik.values.specimen_information_4,
        "specimen_type_of_stain": formik.values.specimen_type_of_stain,
        "specimen_information_other_5": formik.values.specimen_information_other_5,
        "specimen_information_other": formik.values.specimen_information_other,
        "collection_date": formik.values.collectionDate,
        "collection_time": formik.values.collectionTime,
        "body_site": formik.values.bodySite,
        "diagnosis_symptoms": formik.values.diagnosis_symptoms,
        "treatment_status": formik.values.treatment_status,
        "therapy": formik.values.therapy,
        "therapy_other": formik.values.therapy_other,
        "multiple_myeloma": formik.values.multiple_myeloma,
        "bone_marrow_transplant_type": formik.values.bone_marrow_transplant_type,
        "lymphoproliferative_disorders": formik.values.lymphoproliferative,
        "myeloproliferative_neoplasms": formik.values.myeloproliferative_neoplasms,
        "myeloproliferative_neoplasms_other": formik.values.myeloproliferative_neoplasms_other,
        "plasma_cell_neoplasms": formik.values.plasma_cell_neoplasms,
        "comprehensive_hematopathology": formik.values.test_menu,
        "flow_cytometry": formik.values.flow_cytometry,
        "chromo_some_analysis": formik.values.chromosome_analysis,
        "bone_marrow_morphology": formik.values.bone_marrow_morphology,
        "circulating_tumor_cell_analysis": formik.values.circulating_tumor_cell_analysis,
        "pcr": formik.values.pcr,
        "pcr_other": formik.values.pcr_other,
        "fish_panels": formik.values.fish_panels,
        "individual_fish_assays": formik.values.induvidual_fish_assays,
        "individual_fish_assays_other": formik.values.individual_fish_assays_other,
        "test_menu_text": formik.values.test_menu_text,
      }
    }


    const { data, message, status } = await createHematopathologyFormhandler(payload);
    if (status) {
      if (formik.values.patientInformation.pationtDoc && formik.values.patientInformation.pationtDoc.length > 0) {
        savePatientDocHandler(data?.patient_information?.id)
      } else {

        showNotification("success", message || 'Form Register Successfully');
        if (user.group_name === "provider") {
          navigate(`/submitted-forms`)
        } else {
          navigate(`/admin/submitted-forms`);
        }
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const formik = useFormik({
    initialValues: hamepathologyIv,
    validationSchema: hamepathologyValidation,
    onSubmit: submitFormHandler,
  });

  const savePatientDocHandler = async (id) => {
    const formData = new FormData();
    formData.append("patient_document", formik.values.patientInformation.pationtDoc[0]);
    const { data, message, status } = await savePatientDoc(id, formData);
    if (status) {
      showNotification("success", message || 'Form Register Successfully');
      if (user.group_name === "provider") {
        navigate(`/submitted-forms`)
      } else {
        navigate(`/admin/submitted-forms`);
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  let therapy = [
    { label: "Prior ( >1 month ago )", value: "Prior", checked: formik.values.therapy?.includes("Prior"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Rituxan", value: "Rituxan", checked: formik.values.therapy?.includes("Rituxan"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Campath", value: "Campath", checked: formik.values.therapy?.includes("Campath"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Gieevec", value: "Gieevec", checked: formik.values.therapy?.includes("Gieevec"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Mylotarg", value: "Mylotarg", checked: formik.values.therapy?.includes("Mylotarg"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Velcade", value: "Velcade", checked: formik.values.therapy?.includes("Velcade"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Chemotherapy", value: "Chemotherapy", checked: formik.values.therapy?.includes("Chemotherapy"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Radiotherapy", value: "Radiotherapy", checked: formik.values.therapy?.includes("Radiotherapy"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "EPO", value: "EPO", checked: formik.values.therapy?.includes("EPO"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "GCSF", value: "GCSF", checked: formik.values.therapy?.includes("GCSF"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "GMCSF", value: "GMCSF", checked: formik.values.therapy?.includes("GMCSF"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
    { label: "Other", value: "Other", checked: formik.values.therapy?.includes("Other"), onChange: (e) => handleOrderChange(formik, "therapy", e) },
  ]

  let boneMarrowTransplantType = [
    { label: "Autologous", value: "Autologous", checked: formik.values.bone_marrow_transplant_type, onChange: (e) => handleOrderChange(formik, "bone_marrow_transplant_type", e) },
    { label: "Atlogeneic", value: "Atlogeneic", checked: formik.values.bone_marrow_transplant_type, onChange: (e) => handleOrderChange(formik, "bone_marrow_transplant_type", e) },
    { label: "Sex Mismatch", value: "Sex Mismatch", checked: formik.values.bone_marrow_transplant_type, onChange: (e) => handleOrderChange(formik, "bone_marrow_transplant_type", e) }
  ]

  let lymphoproliferative = [
    { label: "Chronic lymphocytic leukemia/small lymphocytic leukemia (CLL/SLL)", value: "Chronic lymphocytic leukemia/small lymphocytic leukemia (CLL/SLL)", checked: formik.values.lymphoproliferative?.includes("Chronic lymphocytic leukemia/small lymphocytic leukemia (CLL/SLL)"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "Mantle cell lymphoma (MCL)", value: "Mantle cell lymphoma (MCL)", checked: formik.values.lymphoproliferative?.includes("Mantle cell lymphoma (MCL)"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "Form (FL)", value: "Form (FL)", checked: formik.values.lymphoproliferative?.includes("Form (FL)"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "Hairy cell leukemia (HCL)", value: "Hairy cell leukemia (HCL)", checked: formik.values.lymphoproliferative?.includes("Hairy cell leukemia (HCL)"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "Diffuse large B-cell lymphoma (DLBCL)", value: "Diffuse large B-cell lymphoma (DLBCL)", checked: formik.values.lymphoproliferative?.includes("Diffuse large B-cell lymphoma (DLBCL)"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "Barklymphoma ", value: "Barklymphoma", checked: formik.values.lymphoproliferative?.includes("Barklymphoma"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "Hodgkin lymphoma", value: "Hodgkin lymphoma", checked: formik.values.lymphoproliferative?.includes("Hodgkin lymphoma"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "Marginal zone lympha", value: "Marginal zone lympha", checked: formik.values.lymphoproliferative?.includes("Marginal zone lympha"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
    { label: "T-cell lymphoma", value: "T-cell lymphoma", checked: formik.values.lymphoproliferative?.includes("T-cell lymphoma"), onChange: (e) => handleOrderChange(formik, "lymphoproliferative", e) },
  ]

  let myeloproliferativeNeoplasms = [
    { label: "CML", value: "CML", checked: formik.values.myeloproliferative_neoplasms?.includes("CML"), onChange: (e) => handleOrderChange(formik, "myeloproliferative_neoplasms", e) },
    { label: "Polycythemia vera (PV)", value: "Polycythemia vera (PV)", checked: formik.values.myeloproliferative_neoplasms?.includes("Polycythemia vera (PV)"), onChange: (e) => handleOrderChange(formik, "myeloproliferative_neoplasms", e) },
    { label: "Essential thrombocytosis (ET)", value: "Essential thrombocytosis (ET)", checked: formik.values.myeloproliferative_neoplasms?.includes("Essential thrombocytosis (ET)"), onChange: (e) => handleOrderChange(formik, "myeloproliferative_neoplasms", e) },
    { label: "Idiopathic myelofibrosis (IMF)", value: "Idiopathic myelofibrosis (IMF)", checked: formik.values.myeloproliferative_neoplasms?.includes("Idiopathic myelofibrosis (IMF)"), onChange: (e) => handleOrderChange(formik, "myeloproliferative_neoplasms", e) },
    { label: "Other", value: "Other", checked: formik.values.myeloproliferative_neoplasms?.includes("Other"), onChange: (e) => [handleOrderChange(formik, "myeloproliferative_neoplasms", e), otherChange(e, "myeloproliferative_neoplasms_other")] },
  ]

  let flowCytometry = [
    { label: "Comprehensive (myeloid, B-, T-Cell)", value: "Comprehensive (myeloid, B-, T-Cell)", checked: formik.values.flow_cytometry?.includes("Comprehensive (myeloid, B-, T-Cell)"), onChange: (e) => handleOrderChange(formik, "flow_cytometry", e) },
    { label: "B- & T-Cell", value: "B- & T-Cell", checked: formik.values.flow_cytometry?.includes("B- & T-Cell)"), onChange: (e) => handleOrderChange(formik, "flow_cytometry", e) },
    { label: " Plasma Cell", value: "Plasma Cell", checked: formik.values.flow_cytometry?.includes("Plasma Cell"), onChange: (e) => handleOrderChange(formik, "flow_cytometry", e) },
    { label: "The RNH", value: "The RNH", checked: formik.values.flow_cytometry?.includes("The RNH"), onChange: (e) => handleOrderChange(formik, "flow_cytometry", e) },
    { label: "ZAP-70", value: "ZAP-70", checked: formik.values.flow_cytometry?.includes("ZAP-70"), onChange: (e) => handleOrderChange(formik, "flow_cytometry", e) },
  ]


  let chromosomeAnalysis = [
    { label: "Classical Cytogenetics", value: "Classical Cytogenetics", checked: formik.values.chromosome_analysis?.includes("Classical Cytogenetics"), onChange: (e) => handleOrderChange(formik, "chromosome_analysis", e) },
    { label: "Array-CGH", value: "Array-CGH", checked: formik.values.chromosome_analysis?.includes("Array-CGH"), onChange: (e) => handleOrderChange(formik, "chromosome_analysis", e) },
  ];
  let pcr = [
    { label: "IGH/CCND1, t(11;14) for MCL, PCN (subset)", value: "IGH/CCND1, t(11;14) for MCL, PCN (subset)", checked: formik.values.pcr?.includes("IGH/CCND1, t(11;14) for MCL, PCN (subset)"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "IGH/BCL2, t(14;18) for FL, DLBCL (subset) ", value: "IGH/BCL2, t(14;18) for FL, DLBCL (subset)", checked: formik.values.pcr?.includes("IGH/BCL2, t(14;18) for FL, DLBCL (subset)"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "PML/RARA, 1(15;17) for APL monitoring ", value: "PML/RARA, 1(15;17) for APL monitoring", checked: formik.values.pcr?.includes("PML/RARA, 1(15;17) for APL monitoring"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "BCR/ABL, Quantitative, t(9;22) for CML, ALL", value: "BCR/ABL, Quantitative, t(9;22) for CML, ALL", checked: formik.values.pcr?.includes("BCR/ABL, Quantitative, t(9;22) for CML, ALL"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "BCR/ABL,major (p210; b2a2 or b2a3)", value: "BCR/ABL,major (p210; b2a2 or b2a3)", checked: formik.values.pcr?.includes("BCR/ABL,major (p210; b2a2 or b2a3)"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "BCR/ABL,minor (p190; e1a2)", value: "BCR/ABL,minor (p190; e1a2)", checked: formik.values.pcr?.includes("BCR/ABL,minor (p190; e1a2)"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "JAK2 mutation analysis for PV, ET, IMF", value: "JAK2 mutation analysis for PV, ET, IMF", checked: formik.values.pcr?.includes("JAK2 mutation analysis for PV, ET, IMF"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "FLT3 mutation analysis for AML ", value: "FLT3 mutation analysis for AML", checked: formik.values.pcr?.includes("FLT3 mutation analysis for AML"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "KIT mutation analysis for AML ", value: "KIT mutation analysis for AML", checked: formik.values.pcr?.includes("KIT mutation analysis for AML"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "MPL mutation analysis for ET, IMF ", value: "MPL mutation analysis for ET, IMF", checked: formik.values.pcr?.includes("MPL mutation analysis for ET, IMF"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "FIP1L1/PDGFRA (4q 12 deletion)", value: "FIP1L1/PDGFRA (4q 12 deletion)", checked: formik.values.pcr?.includes("FIP1L1/PDGFRA (4q 12 deletion)"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "B-Cell (IGH) Gene Rearrangement, B-Cell Clonality ", value: "B-Cell (IGH) Gene Rearrangement, B-Cell Clonality", checked: formik.values.pcr?.includes("B-Cell (IGH) Gene Rearrangement, B-Cell Clonality"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
    { label: "T-Cell (TGR) Gene Rearrangement, T-cell Clonality ", value: "T-Cell (TGR) Gene Rearrangement, T-cell Clonality", checked: formik.values.pcr?.includes("T-Cell (TGR) Gene Rearrangement, T-cell Clonality"), onChange: (e) => handleOrderChange(formik, "pcr", e) },
  ]

  const fishPanels = [
    { label: "CLL/SLL Panel", value: "CLL/SLL Panel", checked: formik.values.fish_panels?.includes("CLL/SLL Panel"), onChange: (e) => handleOrderChange(formik, "fish_panels", e) },
    { label: "MDS Panel", value: "MDS Panel", checked: formik.values.fish_panels?.includes("MDS Panel"), onChange: (e) => handleOrderChange(formik, "fish_panels", e) },
    { label: "Plasma Cell Neoplasm /Myeloma Panel", value: "Plasma Cell Neoplasm /Myeloma Panel", checked: formik.values.fish_panels?.includes("Plasma Cell Neoplasm /Myeloma Panel"), onChange: (e) => handleOrderChange(formik, "fish_panels", e) },
    { label: "NHL Panel", value: "NHL Panel", checked: formik.values.fish_panels?.includes("NHL Panel"), onChange: (e) => handleOrderChange(formik, "fish_panels", e) },
    { label: "AML Panel", value: "AML Panel", checked: formik.values.fish_panels?.includes("AML Panel"), onChange: (e) => handleOrderChange(formik, "fish_panels", e) },
  ]

  const induvidualFishAssays = [
    { label: "IGH/CCND1, t(11;14) for MCL, PCN (subset)", value: "IGH/CCND1, t(11;14) for MCL, PCN (subset)", checked: formik.values.induvidual_fish_assays?.includes("IGH/CCND1, t(11;14) for MCL, PCN (subset)"), onChange: (e) => handleOrderChange(formik, "induvidual_fish_assays", e) },
    { label: " IGH/BCL2, t(14;18) for FL, DLBCL (subset)", value: " IGH/BCL2, t(14;18) for FL, DLBCL (subset)", checked: formik.values.induvidual_fish_assays?.includes(" IGH/BCL2, t(14;18) for FL, DLBCL (subset)"), onChange: (e) => handleOrderChange(formik, "induvidual_fish_assays", e) },
    { label: " PML/RARA, t(15;17) for APL", value: " PML/RARA, t(15;17) for APL", checked: formik.values.induvidual_fish_assays?.includes(" PML/RARA, t(15;17) for APL"), onChange: (e) => handleOrderChange(formik, "induvidual_fish_assays", e) },
    { label: "BCR/ABL, t(9;22) for CML, ALL", value: "BCR/ABL, t(9;22) for CML, ALL", checked: formik.values.induvidual_fish_assays?.includes("BCR/ABL, t(9;22) for CML, ALL"), onChange: (e) => handleOrderChange(formik, "induvidual_fish_assays", e) },
    { label: " FIP1L1/PDGFRA (4q12 deletion)", value: " FIP1L1/PDGFRA (4q12 deletion)", checked: formik.values.induvidual_fish_assays?.includes(" FIP1L1/PDGFRA (4q12 deletion)"), onChange: (e) => handleOrderChange(formik, "induvidual_fish_assays", e) },
    { label: "PDGFRB Rearrangement; t(15;12) ", value: "PDGFRB Rearrangement; t(15;12) ", checked: formik.values.induvidual_fish_assays?.includes("PDGFRB Rearrangement; t(15;12) "), onChange: (e) => handleOrderChange(formik, "induvidual_fish_assays", e) },
    { label: "QX,Y for Bone Marrow Transplant", value: "QX,Y for Bone Marrow Transplant", checked: formik.values.induvidual_fish_assays?.includes("QX,Y for Bone Marrow Transplant"), onChange: (e) => handleOrderChange(formik, "induvidual_fish_assays", e) },
  ]

  let treatmentStatus = [
    { label: "New Diagnosis", value: "New Diagnosis", checked: formik.values.treatment_status?.includes("New Diagnosis"), onChange: (e) => handleOrderChange(formik, "treatment_status", e) },
    { label: "Follow Up", value: "Follow Up", checked: formik.values.treatment_status?.includes("Follow Up"), onChange: (e) => handleOrderChange(formik, "treatment_status", e) },
    { label: "MRD", value: "MRD", checked: formik.values.treatment_status?.includes("MRD"), onChange: (e) => handleOrderChange(formik, "treatment_status", e) },
    { label: "Relapse", value: "Relapse", checked: formik.values.treatment_status?.includes("Relapse"), onChange: (e) => handleOrderChange(formik, "treatment_status", e) },
    { label: "Monitoring", value: "Monitoring", checked: formik.values.treatment_status?.includes("Monitoring"), onChange: (e) => handleOrderChange(formik, "treatment_status", e) },
  ]

  const otherChange = (e, name) => {
    formik.setFieldValue(name, "");
  }



  useEffect(() => {
    referredByDataHandler();
  }, []);

  const referredByDataHandler = async () => {
    const { data, message, status } = await getProfile();
    if (status) {
      setReferByUser(data);
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data.facility_name,
          doctor: data.full_name,
          address: data.address,
          phone: data.phone,
          fax: data.fax,
        },
      });
    }
  };

  const handleOrderChange = (formik, orderFieldName, e) => {
    const currentValue = formik.values[orderFieldName];

    if (e.target.checked) {
      formik.setFieldValue(orderFieldName, [...currentValue, e.target.value]);
    } else {
      formik.setFieldValue(orderFieldName, currentValue.filter(item => item !== e.target.value));
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
        <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: 'pointer', background: '#8cc63f', color: '#fff' }} />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        } />
      <Box className={classes.cardContainer} padding={{ xs: '12px 10px', sm: 3 }}>
        <UserDetails formik={formik} isFormSubmitted={isFormSubmitted} />

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>Specimen Information</Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Peripheral Blood'
                    onChange={(e) => handleOrderChange(formik, "specimen_information_1", e)}
                    value="Peripheral Blood"
                    checked={formik.values.specimen_information_1.includes("Peripheral Blood")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Green top'
                    onChange={(e) => handleOrderChange(formik, "specimen_information_1", e)}
                    value="Green top"
                    checked={formik.values.specimen_information_1.includes("Green top")}

                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Purple top(s)'
                    onChange={(e) => handleOrderChange(formik, "specimen_information_1", e)}
                    value="Purple top(s)"
                    checked={formik.values.specimen_information_1.includes("Purple top(s)")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Other'
                    onChange={(e) => {
                      handleOrderChange(formik, "specimen_information_1", e);
                      otherChange(e, "specimen_information_other1")
                    }}
                    value="Other"
                    checked={formik.values.specimen_information_1.includes("Other")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {formik.values.specimen_information_1.includes("Other") && (
                    <Input variant='standard' name='specimen_information_other1' onChange={formik.handleChange} value={formik.values.specimen_information_other1} />

                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Bone Marrow'
                    onChange={(e) => handleOrderChange(formik, "specimen_information_2", e)}
                    value="Bone Marrow"
                    checked={formik.values.specimen_information_2.includes("Bone Marrow")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Green top'
                    onChange={(e) => handleOrderChange(formik, "specimen_information_2", e)}
                    value="Green top"
                    checked={formik.values.specimen_information_2.includes("Green top")}

                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Purple top(s)'
                    value="Purple top(s)"
                    checked={formik.values.specimen_information_2.includes("Purple top(s)")}
                    onChange={(e) => handleOrderChange(formik, "specimen_information_2", e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Core Biopsy'
                    value="Core Biopsy"
                    checked={formik.values.specimen_information_2.includes("Core Biopsy")}
                    onChange={(e) => handleOrderChange(formik, "specimen_information_2", e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} height={'40px'}></Grid>
                <Grid item xs={12} sm={6} md={3}></Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Clot'
                    value="Clot"
                    checked={formik.values.specimen_information_2.includes("Clot")}
                    onChange={(e) => handleOrderChange(formik, "specimen_information_2", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Other'
                    value="Other"
                    checked={formik.values.specimen_information_2.includes("Other")}
                    onChange={(e) => {
                      handleOrderChange(formik, "specimen_information_2", e);
                      otherChange(e, "specimen_information_other2")

                    }} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}></Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {formik.values.specimen_information_2.includes("Other") && (
                    <Input variant='standard' name="specimen_information_other2" onChange={formik.handleChange} value={formik.values.specimen_information_other2} />

                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Fluid'
                    value="Fluid"
                    checked={formik.values.specimen_information_3.includes("Fluid")}
                    onChange={(e) => handleOrderChange(formik, "specimen_information_3", e)} />

                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='CSF'
                    value="CSF"
                    checked={formik.values.specimen_information_3.includes("CSF")}
                    onChange={(e) => handleOrderChange(formik, "specimen_information_3", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Pleural'
                    value="Pleural"
                    checked={formik.values.specimen_information_3.includes("Pleural")}
                    onChange={(e) => handleOrderChange(formik, "specimen_information_3", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Other'
                    value="Other"
                    checked={formik.values.specimen_information_3.includes("Other")}
                    onChange={(e) => {
                      handleOrderChange(formik, "specimen_information_3", e);
                      otherChange(e, "specimen_information_other3")
                    }} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {formik.values.specimen_information_3.includes("Other") && (
                    <Input variant='standard' name="specimen_information_other3" onChange={formik.handleChange} value={formik.values.specimen_information_other3} />
                  )}
                </Grid>
                <Grid item xs={12} height={'40px'}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Fresh Tissue'
                    value="Fresh Tissue"
                    checked={formik.values.specimen_information_4.includes("Fresh Tissue")}
                    onChange={(e) => handleOrderChange(formik, "specimen_information_4", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Smears'
                    value="Smears"
                    checked={formik.values.specimen_type_of_stain.includes("Smears")}
                    onChange={(e) => handleOrderChange(formik, "specimen_type_of_stain", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Air dried'
                    value="Air dried"
                    checked={formik.values.specimen_type_of_stain.includes("Air dried")}
                    onChange={(e) => handleOrderChange(formik, "specimen_type_of_stain", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Fixed'
                    value="Fixed"
                    checked={formik.values.specimen_type_of_stain.includes("Fixed")}
                    onChange={(e) => handleOrderChange(formik, "specimen_type_of_stain", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Stained'
                    value="Stained"
                    checked={formik.values.specimen_type_of_stain.includes("Stained")}
                    onChange={(e) => handleOrderChange(formik, "specimen_type_of_stain", e)} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Input variant='standard' placeholder='Type of stain' name="specimen_information_other_5" onChange={formik.handleChange} value={formik.values.specimen_information_other_5} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Checkbox checkboxStyle={{ padding: '0 5px' }} singleCheckbox label='Other'
                    value="Other"
                    checked={formik.values.specimen_type_of_stain.includes("Other")}
                    onChange={(e) => {
                      handleOrderChange(formik, "specimen_type_of_stain", e);
                      otherChange(e, "specimen_information_other")

                    }} />
                </Grid>
                <Grid item xs={10}>
                  {formik.values.specimen_type_of_stain.includes("Other") && (
                    <Input variant='standard' placeholder='Other' name="specimen_information_other" onChange={formik.handleChange} value={formik.values.specimen_information_other} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input labelInput='Collection Date' type='date'
                    onChange={formik.handleChange}
                    value={formik.values.collectionDate}
                    name='collectionDate'
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input labelInput='Collection Time' type='time'
                    onChange={formik.handleChange}
                    value={formik.values.collectionTime}
                    name='collectionTime'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input labelInput='Body Site' onChange={formik.handleChange} value={formik.values.bodySite} name='bodySite' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Clinical Information</Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input labelInput='Diagnosis or Signs/Symptoms (ICD-10 or Narrative)'
                onChange={formik.handleChange}
                value={formik.values.diagnosis_symptoms}
                name='diagnosis_symptoms'
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={treatmentStatus} row labelInput='Treatment Status'

              />
            </Grid>
            <Grid item xs={4}>
              <Checkbox singleCheckbox label='Current' row labelInput='Therapy'
                onChange={(e) => handleOrderChange(formik, "therapy", e)}
                value="Current"
                checked={formik.values.therapy?.includes("Current")}
              />
            </Grid>
            <Grid item xs={4}>
              <Input labelInput='Type' variant='standard' name='therapy_other' onChange={formik.handleChange} value={formik.values.therapy_other} />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={therapy} row />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={boneMarrowTransplantType} row labelInput='Bone Marrow Transplant Type ' />
            </Grid>
            <Grid item xs={12}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox singleCheckbox sx={{ marginRight: '0' }} value="Lymphoproliferative Disorders" onChange={(e) => handleOrderChange(formik, "lymphoproliferative", e)} checked={formik.values.lymphoproliferative?.includes("Lymphoproliferative Disorders")} />
                <Typography fontWeight={'700'} fontSize={{ xs: '13px', sm: '16px' }}>Lymphoproliferative Disorders</Typography>
              </Stack>
              <Box marginLeft={3}>
                <Checkbox checkboxList={lymphoproliferative} row />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox singleCheckbox sx={{ marginRight: '0' }} checked={formik.values.myeloproliferative_neoplasms?.includes("Myeloproliferative Neoplasms")} value="Myeloproliferative Neoplasms" onChange={(e) => handleOrderChange(formik, "myeloproliferative_neoplasms", e)} />
                <Typography fontWeight={'700'} fontSize={{ xs: '13px', sm: '16px' }}>Myeloproliferative Neoplasms</Typography>
              </Stack>
              <Box marginLeft={3}>
                <Checkbox checkboxList={myeloproliferativeNeoplasms} row
                  checked={formik.values.myeloproliferative_neoplasms}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox singleCheckbox sx={{ marginRight: '0' }} value='Plasma cell neoplasms' onChange={(e) => handleOrderChange(formik, "plasma_cell_neoplasms", e)} checked={formik.values.plasma_cell_neoplasms?.includes("Plasma cell neoplasms")} />
                <Typography fontWeight={'700'} fontSize={{ xs: '13px', sm: '16px' }}>Plasma cell neoplasms</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox singleCheckbox sx={{ marginRight: '0' }} value='Multiple myeloma (MM)' onChange={(e) => handleOrderChange(formik, "multiple_myeloma", e)} checked={formik.values.multiple_myeloma?.includes("Multiple myeloma (MM)")} />
                <Typography fontWeight={'700'} fontSize={{ xs: '13px', sm: '16px' }}>Multiple myeloma (MM)</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {formik.values.myeloproliferative_neoplasms?.includes("Other") && (
                <Input variant='standard' placeholder="Other" name='myeloproliferative_neoplasms_other' onChange={formik.handleChange} value={formik.values.myeloproliferative_neoplasms_other} />
              )}
            </Grid>
          </Grid>
        </Box>

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Test Menu</Typography>
        <Divider />
        <Box marginY={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Checkbox
            label="Comprehensive Hematopathology Evaluation (Includes both Diagnostic and Prognostic Testing*)"
            singleCheckbox checkboxStyle={{ padding: '0 10px' }}
            onChange={(e) => handleOrderChange(formik, "test_menu", e)}
            value="Comprehensive Hematopathology Evaluation (Includes both Diagnostic and Prognostic Testing*)"
            checked={formik.values.test_menu?.includes("Comprehensive Hematopathology Evaluation (Includes both Diagnostic and Prognostic Testing*)")}
          />
          <Box>
            <Typography fontWeight={'300'} fontSize={'12px'} marginTop={'5px'}>(This includes morphologic evaluation and makers by IHC, Flow Cytometry, Chromosomal Analysis, FISH, a-CGH and PCR, as medically necessary*)</Typography>
          </Box>
          <Input variant='standard' placeholder="Other" name='test_menu_text' value={formik.values.test_menu_text}
            formik={formik} onChange={formik.handleChange} sx={{ width: '300px' }} />
        </Box>
        <Divider />
        <Typography fontSize={'14px'} marginTop={'5px'}><b>Individual Test Analysis</b> Only perform testing on the submitted specimen(s) using the specific test components listed below</Typography>

        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Checkbox checkboxList={flowCytometry} row labelInput='Flow Cytometry ' />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={chromosomeAnalysis} row labelInput='Chromosome Analysis' />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox singleCheckbox sx={{ marginRight: '0' }} checked={formik.values.bone_marrow_morphology?.includes("Bone Marrow Morphology")} value="Bone Marrow Morphology" onChange={(e) => handleOrderChange(formik, "bone_marrow_morphology", e)} />
                <Typography fontWeight={'700'} fontSize={{ xs: '13px', sm: '16px' }}>Bone Marrow Morphology</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox singleCheckbox sx={{ marginRight: '0' }} checked={formik.values.circulating_tumor_cell_analysis?.includes("Circulating Tumor Cell Analysis")} value="Circulating Tumor Cell Analysis" onChange={(e) => handleOrderChange(formik, "circulating_tumor_cell_analysis", e)} />
                <Typography fontWeight={'700'} fontSize={{ xs: '13px', sm: '16px' }}>Circulating Tumor Cell Analysis</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={pcr} row labelInput='PCR#' />
              <Input variant='standard' placeholder='other' name='pcr_other' onChange={formik.handleChange} value={formik.values.pcr_other} />
            </Grid>

            <Grid item xs={12}>
              <Checkbox checkboxList={fishPanels} row labelInput='Fish Panels*' />
              <Typography fontWeight={'700'} fontSize={'12px'}>*Contact TOPA for a complete list of FISH probes.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={induvidualFishAssays} row labelInput='Individual Fish Assays' />
              <Input variant='standard' placeholder='other' name="individual_fish_assays_other" onChange={formik.handleChange} value={formik.values.individual_fish_assays_other} />
            </Grid>

          </Grid>
        </Box>

        {
          location.pathname === '/admin/submitted-forms/view' ? '' :
            <>
              <Divider sx={{ marginTop: '20px !important', marginBottom: '16px !important' }} />
              <Stack gap={'10px'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Button buttonName='Cancel' onClick={() => navigate(-1)} />
                <Button buttonName='Submit' onClick={() => [formik.handleSubmit(), validationHandler()]} />
              </Stack>
            </>
        }
      </Box>
    </>

  )
}

export default Hematopathology