import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, LOADING } from '../action-types/authActionType';


const initialState = {
    user: {},
    client: {},
    isAuthenticate: false,
    token: '',
    permissions: []
};

export const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload || {},
                isAuthenticate: true,
                token: action.payload?.token || ''
            };
        case LOADING:
            return {
                ...state,
                isAuthenticate: "loading"
            };
        case LOGOUT:
            localStorage.removeItem("userToken");
            return {
                ...state,
                user: {},
                client: {},
                isAuthenticate: false,
                token: ''
            };
        default:
            return state;
    }
}
