import React, { useState, useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, Input } from "../../Component/Commons";
import { addUserInitialValues } from "../../Helper/initialValues";
import { useFormik } from "formik";
import {
  createUserValidationSchema,
  UpdateUserValidationSchema,
} from "../../Helper/validation";
import { createUser, userUpdateSave } from "../../Service/manageuser.service";
import { showNotification } from "../../Helper/Notification";
import Date from "../../Component/Commons/Date";
import dayjs from "dayjs";
import { TextareaAutosize } from "@mui/material";

const AddUser = (props) => {
  const { handleClose, modalOpen, refreshApi } = props;

  const formik = useFormik({
    initialValues: addUserInitialValues,
    validationSchema: modalOpen.isEdit
      ? UpdateUserValidationSchema
      : createUserValidationSchema,
    onSubmit: async (value) => {
      if (!modalOpen.isEdit) {
        const res = await createUser(value);
        if (res.status) {
          formik.resetForm();
          handleClose();
          refreshApi();
          showNotification("success", res.message || 'User created successfully')
        } else {
          showNotification("error", res.message || 'Something went wrong')
        }
      } else {
        const updatePayload = {
          first_name: value.first_name,
          last_name: value.last_name,
          facility_code: value.facility_code,
          // NEED TO KEEP THIS CODE COMMENTED
          // email: value.email,
          address: value.address,
          phone: value.phone,
          fax: value.fax,
          facility_name: value.facility_name,
          // NEED TO KEEP THIS CODE COMMENTED
          // password: value,
          // start_date: value.start_date,
          // end_date: value.end_date
        };
        const updateUser = await userUpdateSave(
          modalOpen?.data?.id,
          updatePayload
        );
        if (updateUser.status) {
          formik.resetForm();
          handleClose();
          refreshApi();
          showNotification(
            "success",
            updateUser.message || "User updated successfully"
          );
        } else {
          showNotification(
            "error",
            updateUser.message || "Something went wrong"
          );
        }
      }
    },
  });

  useEffect(() => {
    if (modalOpen.isEdit) {
      if (modalOpen.data) {
        const { data } = modalOpen;
        formik.setValues({
          ...formik.values,
          first_name: data?.first_name,
          last_name: data?.last_name,
          facility_code: data?.facility_code,
          email: data?.email,
          username:data?.username,
          address: data?.address,
          phone: data?.phone,
          fax: data?.fax,
          facility_name: data?.facility_name,
          // start_date: data?.start_date,
          // end_date: data?.end_date
        });
      }
    }
  }, []);

  const handlePhoneBlur = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    formik.setFieldValue('phone', formattedPhoneNumber);
  };

  const handleFaxBlur = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    formik.setFieldValue('fax', formattedPhoneNumber);
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove non-numeric characters
    phoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Consider only the first 10 digits
    phoneNumber = phoneNumber.substring(0, 10);

    // Check if the remaining value has 10 digits
    if (phoneNumber.length === 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
    }
    // Return original input if it doesn't meet the criteria
    return phoneNumber;
  };

  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? "Edit" : "Add"} User
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="First Name"
              formik={formik}
              name="first_name"
              labelInput="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="Last Name"
              formik={formik}
              name="last_name"
              labelInput="Last Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="Facility Name"
              formik={formik}
              name="facility_name"
              labelInput="Facility Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="Facililty Code"
              formik={formik}
              name="facility_code"
              labelInput="Facility Code"
            />
          </Grid>
         
          <Grid item xs={12} sm={12}>
            <Typography
              fontSize={"14px"}
              fontWeight={600}
              marginBottom={"6px"}
              color={"#000"}
            >
              Address
            </Typography>
            {/* <TextareaAutosize formik={formik} error={true} name="address" placeholder='Address' style={{ width: '100%', padding: '12px', fontSize: '16px', border: '1px solid #e3e3e3', outline: '1px solid #e3e3e3', borderRadius: '5px' }} /> */}
            <Input
              placeholder="Address"
              formik={formik}
              name="address"
              rows={3}
              cols={3}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="Email"
              disabled={modalOpen.isEdit}
              formik={formik}
              name="email"
              labelInput="Email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="Username"
              disabled={modalOpen.isEdit}
              formik={formik}
              name="username"
              labelInput="Username"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="Phone"
              formik={formik}
              name="phone"
              onBlur={handlePhoneBlur}
              value={formatPhoneNumber(formik.values.phone)}
              labelInput="Phone"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              placeholder="Fax"
              formik={formik}
              name="fax"
              value={formatPhoneNumber(formik.values.fax)}
              onBlur={handleFaxBlur}
              labelInput="Fax"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Date
              labelInput='Start Date'
              onChange={(date) => {
                formik.setFieldValue(
                  'start_date',
                  date.format('YYYY-MM-DD'),
                );
                formik.setFieldTouched('start_date', false);
              }}
              formik={formik}
              format="YYYY-MM-DD"
              placeholder="Start Date"
              name="start_date"
              value={
                formik.values.start_date == ''
                  ? ""
                  : dayjs(formik.values.start_date).format(
                    'YYYY-MM-DD',
                  )
              }
            />
            {formik?.touched['start_date'] &&
              formik?.errors['start_date'] ? (
              <div
                style={{
                  color: '#D32F2F',
                  fontSize: '12px',
                  marginTop: '0px',
                  marginBottom: '10px',
                  textAlign: 'left',
                  marginLeft: '5px',
                }}
              >
                {formik?.errors['start_date']}
              </div>
            ) : (
              <div className="error"></div>
            )}
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <Date
              labelInput='End Date'
              onChange={(date) => {
                formik.setFieldValue(
                  'end_date',
                  date.format('YYYY-MM-DD'),
                );
                formik.setFieldTouched('end_date', false);
              }}
              formik={formik}
              format="YYYY-MM-DD"
              placeholder="Start Date"
              name="end_date"
              value={
                formik.values.end_date == ''
                  ? ""
                  : dayjs(formik.values.end_date).format(
                    'YYYY-MM-DD',
                  )
              }
            />
            {formik?.touched['end_date'] &&
              formik?.errors['end_date'] ? (
              <div
                style={{
                  color: '#D32F2F',
                  fontSize: '12px',
                  marginTop: '0px',
                  marginBottom: '10px',
                  textAlign: 'left',
                  marginLeft: '5px',
                }}
              >
                {formik?.errors['end_date']}
              </div>
            ) : (
              <div className="error"></div>
            )}
          </Grid> */}

          {!modalOpen.isEdit ? (
            <Grid item xs={12} sm={6}>
              <Input
                placeholder="Password"
                formik={formik}
                name="password"
                labelInput="Password"
                passwordInput
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName="Cancel" buttonColor="gray" onClick={handleClose} />
        <Button
          buttonName={modalOpen.isEdit ? "Save" : "Add"}
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </div>
  );
};

export default AddUser;
