import React, { useState, useEffect } from 'react';
import { Box, Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserSidebarList } from '../../Helper/Helper';
import { MyComponent } from '../../Helper/Theme';
import { AiOutlineMenuUnfold, FaUserCircle, BiSolidUser, BiSolidLock, HiOutlineLogout, FaWpforms } from '../../Helper/Icons';
import { profile_image } from '../../Helper/Constant';
import { Modal } from '../Commons';
import { useSelector, useDispatch } from "react-redux";

const Header = ({ handleDrawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const { user } = useSelector((state) => state?.root?.auth);
  const [profileImage, setProfileImage] = useState(profile_image)

  const [anchorEl, setAnchorEl] = useState(null);
  const [myprofile, setMyProfile] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setMyProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setMyProfile(null);
  };

  useEffect(() => {
    if (user && user?.profile_image) {
      setProfileImage(user?.profile_image)
    }
  }, [user])

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MyComponent
              sx={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ p: '8px', m: '0' }}
              >
                <AiOutlineMenuUnfold style={{ color: '#fff', fontSize: '20px' }} />
              </IconButton>
            </MyComponent>
          </Box>
          <Box>
            {UserSidebarList.map((data, i) => {
              return (
                <Stack key={i} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'2px'}>
                  {location.pathname === data.path &&
                    <>
                      <Box width={'40px'} height={'40px'} display={'flex'} padding={'10px'} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                        {data.activeIcon}
                      </Box>
                      <Typography fontSize={'20px'} fontWeight={'500'} marginLeft={1} color={'#000'}>{data.name}</Typography>
                    </>
                  }
                </Stack>
              );
            })}
            {location.pathname === '/profile' ?
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                  <BiSolidUser style={{ color: '#fff', fontSize: '20px' }} />
                </Box>
                <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Profile Details</Typography>
              </Stack>
              : location.pathname === '/submitted-forms/view' ?
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                  <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                    <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                  </Box>
                  <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>View Patient Details</Typography>
                </Stack>
                : location.pathname === '/create-test/surgical-pathology-requisition-form' ?
                  <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                    <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                      <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                    </Box>
                    <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Surgical Pathology Requisition</Typography>
                  </Stack>
                  : location.pathname === '/create-test/cytopathology-requisition-form' ?
                    <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                      <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                        <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                      </Box>
                      <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Non-Gyn Cytopathology Requisition</Typography>
                    </Stack>
                    : location.pathname === '/create-test/gynecological-cytology-requisition-form' ?
                      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                        <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                          <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                        </Box>
                        <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Gynecological Cytology Requisition</Typography>
                      </Stack>
                      : location.pathname === '/create-test/molecular-requisition-form' ?
                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                          <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                            <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                          </Box>
                          <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Molecular Requisition</Typography>
                        </Stack>
                        : location.pathname === '/create-test/prostater-requisition-form' ?
                          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                            <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                              <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                            </Box>
                            <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Prostate Requisition</Typography>
                          </Stack>
                          : location.pathname === '/create-test/hematopathology-requisition-form' ?
                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                              <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                                <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                              </Box>
                              <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Hematopathology Requisition</Typography>
                            </Stack>
                            : location.pathname === '/create-test/podiatry-requisition-form' ?
                              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                                <Box width={{ xs: '35px', sm: '40px' }} height={{ xs: '35px', sm: '40px' }} display={'flex'} padding={{ xs: '7px', sm: '10px' }} sx={{ background: '#f7931e', borderRadius: '50%' }}>
                                  <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />
                                </Box>
                                <Typography fontSize={{ xs: '15px', sm: '20px' }} fontWeight={'500'}>Podiatry Requisition</Typography>
                              </Stack>
                              : ''}
          </Box>
        </Stack>
        <Box >
          <Stack flexDirection={'row'} gap={1}>
            <Box sx={{ display: 'flex', cursor: 'pointer' }} onClick={handleClickProfile}>
              <img src={profileImage} alt='profile_image' width={'40px'} height={'40px'} />
            </Box>
          </Stack>
        </Box>
        <Menu
          anchorEl={myprofile}
          open={Boolean(myprofile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => { navigate('/profile') }} onMouseUp={handleCloseProfile}>
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <FaUserCircle style={{ fontSize: '18px' }} />
              <div style={{ display: 'flex' }}>
                My Profile
              </div>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => setIsModalOpen({ open: true, currentComponent: "changePassword", })}
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <BiSolidLock style={{ fontSize: '18px' }} />
              <div style={{ display: 'flex' }}>
                Change Password
              </div>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() => setIsModalOpen({ open: true, currentComponent: "logout", })}
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <HiOutlineLogout style={{ fontSize: '18px' }} />
              <div style={{ display: 'flex' }}>
                Logout
              </div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        passChangeUserId = {user?.id}
        />
    </>
  )
}

export default Header