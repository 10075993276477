import React from 'react'
import { Box, Checkbox, Grid, Stack, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Logo, login } from '../../Helper/Constant'
import { Input, Button } from '../../Component/Commons'

const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <Box height={'100vh'}>
      <Box padding={'16px 16px 0'}>
        <img src={Logo} width={'120px'} />
      </Box>
      <Grid container height={'calc(100vh - 100px)'} sx={{ overflow: 'hidden' }} justifyContent={'center'} alignItems={'center'}>
        <Grid item md={5.8} sx={{ position: 'relative', height: '100%', display: { xs: 'none', md: 'block' } }}>
          <img src={login} style={{ height: '100%', width: '100%', objectFit: 'contain', padding: '50px' }} />
        </Grid>
        <Grid item xs={12} md={5.8} sx={{ overflow: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
          <Stack gap={3} justifyContent={'center'} height={'100%'} alignItems={'center'} width={'400px'} margin={'auto'} padding={'50px 20px'} sx={{ border: { xs: '', sm: '1px solid #c1c1c1' }, borderRadius: { xs: '', sm: '25px' }, height: 'fit-content' }} >
            <Box textAlign={'start'} marginBottom={2}>
              <Typography fontSize={'25px'} fontWeight={'600'} color={'#000'}>Forgot Password</Typography>
              <Typography fontSize={'16px'} fontWeight={'500'} color={'#000'} marginTop={1}>Please enter the email address associated with your account below. We will send you a link to reset your password</Typography>
            </Box>
            <Box width={'100%'}>
              <Stack gap={2}>
                <Box>
                  <Input placeholder='Enter your Email' labelInput='Email' />
                </Box>
              </Stack>
              <Stack padding={'30px 0 16px 0'}>
                <Button buttonName='Submit' onClick={() => navigate('/')} />
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ForgotPassword