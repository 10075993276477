import React, { useEffect, useState } from "react";
import UserDetails from "../../Pages/createtest/UserDetails";
import { useFormik } from "formik";
import { prostateIv } from "../../Helper/initialValues";
import { getProstateFormDataHandler } from "../../Service/prostate.service";
import { useLocation } from "react-router-dom";
import { Box, Chip, Divider, Grid, MenuItem, OutlinedInput, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, Typography, useTheme } from "@mui/material";
import { Checkbox, Input } from "../Commons";
import {
  bladderIcdCodes,
  prostateIcdCodes,
  prostateMultiSelect,
  specimenType,
  urineCytologySource,
} from "../../Helper/Helper";
import { StyledTableRow, useStyles } from "../../Helper/Theme";
import { prostateImage } from "../../Helper/Constant";
import ProstateImage from "../../Pages/createtest/forms/prostatereq/ProstateImage";

function ProstateFormView() {
  const { state } = useLocation();
  const theme = useTheme();
  const [psa, setPsa] = useState("");
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [freePsa, setFreePsa] = useState("");
  const [prostateIcdValues, setProstateIcdValues] = useState([]);
  const [bladderIcdCodesValue, setBladderIcdCodesValues] = useState([]);
  const [specimentValues, setSpecimentValues] = useState([]);
  const [urineCytologyValues, setUrineCytologyValues] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [specimenTypeData, setSpecimenTypeData] = useState([
    {
      specimentsType1: "",
      specimentsType2: "",
      specimentsType3: "",
      specimentsType4: "",
      specimentsType5: "",
    },
  ]);
  const [prostateText, setProstateText] = useState("");
  const [bladderIcdText, setBladderIcdText] = useState("");
  const [specimentText, setSpecimentText] = useState("");
  const [urineText, setUrineText] = useState("");
  const [prostateSelectedImageList, setProstateSelectedImageList] = useState([]);

  const [bodySubProcedureData, setBodySubProcedureData] = useState([
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    }
  ]);

  const formik = useFormik({
    initialValues: prostateIv,
  });

  useEffect(() => {
    viewFormApiHandler();
  }, []);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const viewFormApiHandler = async () => {
    const { data, message, status } = await getProstateFormDataHandler(
      state.formId
    );
    if (status) {
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data?.user_information?.facility_name,
          doctor: data?.user_information?.full_name,
          address: data?.user_information?.address,
          phone: data?.user_information?.phone,
          fax: data?.user_information?.fax,
        },
        patientInformation: {
          patient_first_name: data?.patient_information?.patient_first_name,
          patient_last_name: data?.patient_information?.patient_last_name,
          initial: data?.patient_information?.initial,
          patient_address: data?.patient_information?.patient_address,
          patient_city: data?.patient_information?.patient_city,
          patient_state: data?.patient_information?.patient_state,
          patient_zip: data?.patient_information?.patient_zip,
          patient_area_code: data?.patient_information?.patient_area_code,
          patient_age: data?.patient_information?.patient_age,
          patient_sex: data?.patient_information?.patient_sex,
          patient_dob: data?.patient_information?.patient_dob,
          patient_phone: data?.patient_information?.patient_phone,
          patient_payment: data?.patient_information?.patient_payment,
          pay_amount: data?.patient_information?.pay_amount,
          bill_number: data?.patient_information?.bill_number,
          bill_amount: data?.patient_information?.bill_amount,
          insurance_company: data?.patient_information?.insurance_company,
          insurance_no: data?.patient_information?.insurance_no,
          icd_10: data?.patient_information?.icd_10,
          clinical_history: data?.patient_information?.clinical_history,
          additional_physician: data?.patient_information?.additional_physician == null ? "" : data?.patient_information?.additional_physician,
          other_test: data?.patient_information?.other_test,
          pationtDoc: [data?.patient_information?.patient_document],
        }
      })
      setProstateIcdValues(data?.prostate_requisition_information?.prostate_icd_10_codes);
      setBladderIcdCodesValues(data?.prostate_requisition_information?.bladder_icd_10_codes);
      setSpecimentValues(data?.prostate_requisition_information?.specimen_type);
      setUrineCytologyValues(data?.prostate_requisition_information?.urine_cytology_source);
      setFreePsa(data?.prostate_requisition_information?.free_psa);
      setPsa(data?.prostate_requisition_information?.psa_of_ml);
      setSpecimenTypeData(data?.prostate_requisition_information?.specimens_other_than_prostate);
      setBodySubProcedureData(data?.prostate_requisition_information?.bodysite_subsite_procedure);
      setProstateSelectedImageList(data?.prostate_requisition_information?.bodysite_subsite_procedure.map((data) => data.BodySite));
      setSelectedImage(data?.prostate_requisition_information?.bodysite_subsite_procedure.map((data) => data.BodySite));
      setSelectItem(data?.prostate_requisition_information?.select);
      setProstateText(data?.prostate_requisition_information?.prostate_icd_10_codes_other);
      setBladderIcdText(data?.prostate_requisition_information?.bladder_icd_10_codes_other);
      setSpecimentText(data?.prostate_requisition_information?.specimen_type_other);
      setUrineText(data?.prostate_requisition_information?.urine_cytology_source_other);
    } else {
    }
  };

  return (
    <>
      <UserDetails formik={formik} view={true} />
      <Typography fontWeight={"700"} fontSize={{ xs: "15px", sm: "18px" }}>
        ICD-10 Codes Diagnosis/Signs/Symptoms
      </Typography>
      <Divider />
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={"14px"}>
              For your convenience, a partial list of common diagnoses follows.
              For a complete listing of all ICD-10 codes, refer to a current
              version of the ICD-10-CM book. All diagnoses should be provided by
              the ordering physician or his/her authorized designee.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checkboxList={prostateIcdCodes}
              row
              checked={prostateIcdValues}
              view={true}
              labelInput="Prostate-ICD-10 Codes"
            />
            {
              prostateText &&
              <Input
                variant="standard"
                value={prostateText}
                view={true}
              />
            }
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checkboxList={bladderIcdCodes}
              row
              checked={bladderIcdCodesValue}
              view={true}
              labelInput="Bladder-ICD-10 Codes"
            />
            {
              bladderIcdText &&
              <Input
                variant="standard"
                value={bladderIcdText}
                view={true}
              />
            }
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checkboxList={specimenType}
              row
              checked={specimentValues}
              view={true}
              labelInput="Specimen Type"
            />
            {
              specimentText &&
              <Input
                variant="standard"
                value={specimentText}
                view={true}
              />
            }
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checkboxList={urineCytologySource}
              row
              checked={urineCytologyValues}
              view={true}
              labelInput="Urine Cytology Source"
            />
            {
              urineText &&
              <Input
                variant="standard"
                value={urineText}
                view={true}
              />
            }
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography
                fontWeight={"600"}
                fontSize={{ xs: "14px", sm: "16px" }}
              >
                Clinical Information
              </Typography>
              <Grid container spacing={2} marginTop={"5px"}>
                <Grid item xs={6} sm={4} md={3}>
                  <Input
                    labelInput="PSA(of/ml)"
                    value={psa}
                    view={true}
                    onChange={(event) => setPsa(event.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                  <Input
                    labelInput="% Free PSA."
                    value={freePsa}
                    view={true}
                    onChange={(event) => setFreePsa(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography
                fontWeight={"600"}
                fontSize={{ xs: "14px", sm: "16px" }}
              >
                Specimens (other than prostate, list below)
              </Typography>
              <Stack flexDirection={{ xs: "column", sm: "row" }} gap={2}>
                {specimenTypeData.map((res, i) => {
                  return (
                    <>
                      <Box width={"100%"}>
                        <Input
                          labelInput="1"
                          view={true}
                          value={res.specimentsType1}
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Input
                          labelInput="2"
                          view={true}
                          value={res.specimentsType2}
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Input
                          view={true}
                          labelInput="3"
                          value={res.specimentsType3}
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Input
                          labelInput="4"
                          view={true}
                          value={res.specimentsType4}
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Input
                          labelInput="5"
                          view={true}
                          value={res.specimentsType5}
                        />
                      </Box>
                    </>
                  );
                })}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container marginTop={3} spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper} className={classes.cardContainer}>
            <Table stickyHeader aria-label="simple table">
              <TableBody>
                <StyledTableRow>
                  <TableCell sx={{ textAlign: "center !important" }}>
                    Body Site
                  </TableCell>
                  <TableCell>Sub Site</TableCell>
                  <TableCell>Procedure</TableCell>
                </StyledTableRow>
                {bodySubProcedureData.map((data, i) => {
                  return (
                    <StyledTableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Stack
                          flexDirection={"row"}
                          gap={1}
                          alignItems={"flex-end"}
                        >
                          <Typography
                            fontWeight={"700"}
                            sx={{ width: "20px" }}
                            fontSize={{ xs: "13px", sm: "16px" }}
                          >
                            {i + 1}.
                          </Typography>
                          <Input
                            boxWidth="100%"
                            view={true}
                            variant="standard"
                            value={data.BodySite}

                          />
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Input
                          variant="standard"
                          value={data.SubSite}
                          view={true}

                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Input
                          variant="standard"
                          view={true}
                          value={data.Procedure}

                        />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <ProstateImage bodySubProcedureData={bodySubProcedureData} selectedImage={selectedImage} setSelectedImage={setSelectedImage} isDetail={true} prostateSelectedImageList={prostateSelectedImageList} setBodySubProcedureData={setBodySubProcedureData} setProstateSelectedImageList={setProstateSelectedImageList} />
        </Grid>
      </Grid>
    </>
  );
}

export default ProstateFormView;
