import React from 'react'
import { Box, ListItemText, ListItem, ListItemButton, ListItemIcon, List, Divider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../../Helper/Constant';
import { UserSidebarList } from '../../Helper/Helper';

const Sidebar = ({ handleDrawerToggle, drawerWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box width={drawerWidth}>
      <List sx={{ padding: '16px 16px 11px' }}>
        <img src={Logo} alt='main_logo' width={'120'} />
      </List>
      <Box marginTop={2}>
        {UserSidebarList.map((data, i) => (
          <List key={i} sx={{ borderLeft: ` ${location.pathname === data.path ? '4px solid #8cc63f !important' : location.pathname === '/submitted-forms/create' && data.path === '/submitted-forms' ? '4px solid #8cc63f !important' : '4px solid transparent !important'}`, padding: '0' }}>
            <ListItem onClick={() => navigate(data.path)} key={data} disablePadding sx={{ margin: '8px 10px', width: 'unset' }}>
              <ListItemButton onClick={handleDrawerToggle}
                sx={{
                  background: `${location.pathname === data.path ? '#8cc63f' : location.pathname === '/submitted-forms/create' && data.path === '/submitted-forms' ? '#8cc63f' : 'transparent'}`,
                  borderRadius: '5px'
                }} className={location.pathname === data.path ? 'active_sidebar' : location.pathname === '/submitted-forms/create' && data.path === '/submitted-forms' ? 'active_sidebar' : ''}>
                <ListItemIcon sx={{ minWidth: 30, }}>
                  {location.pathname === data.path ? data.activeIcon : location.pathname === '/submitted-forms/create' && data.path === '/submitted-forms' ? data.activeIcon : data.icon}
                </ListItemIcon>
                <ListItemText primary={data.name} sx={{
                  color: location.pathname === data.path ? 'white' : location.pathname === '/submitted-forms/create' && data.path === '/submitted-forms' ? 'white' : 'black', fontSize: '15px'
                }} />
              </ListItemButton>
            </ListItem>
          </List>
        ))}
      </Box>
    </Box>
  )
}

export default Sidebar