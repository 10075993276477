import React, { useState, useEffect } from 'react';

import { Box, TextField, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

//add date picker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

//date color change to white

const WhiteBorderDate = styled(DesktopDatePicker)`
  & .MuiInputBase-input {
    color: white;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const Date = (props) => {
    const {
        name, boxWidth,
        value,
        onChange,
        color = '',
        maxRows = 1,
        error = null,
        labelInput,
        style,
        currentDate,
        formik,
        ...other
    } = props;

    const [ClassName, setClassName] = useState('');

    useEffect(() => {
        if (other && other.className) {
            setClassName(ClassName + other.className);
        }
    }, []);

    return (
        <Box width={boxWidth}>
            {labelInput && <Typography fontSize={{ xs: '12px', sm: '14px' }} fontWeight={600} marginBottom={{ xs: '3px', sm: '6px' }} color={'#000'}>{labelInput}</Typography>}
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <WhiteBorderDate
                    onClose={props.onClose}
                    className="custom-date"
                    sx={{
                        width: '100%'
                    }}
                    value={dayjs(value)}
                    onChange={onChange || ''}              
                    format={props.format || 'DD/MM/YYYY'}
                    disabled={props.disabled ? true : false}
                    onBlur={() => {
                        if (formik) {
                            formik.setFieldTouched(name, true);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField fullWidth size="small"
                            onBlur={() => {
                                if (formik) {
                                    formik.setFieldTouched(name, true);
                                }
                            }}
                            error={formik?.touched[name] && formik?.errors[name] ? true : false}
                            {...params} />
                    )}
                    {...(error && { error: true, helperText: error })}
                />
            </LocalizationProvider>
        </Box>

    );
};

export default Date;
