import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { StyledTableCell, StyledTableRow, useStyles } from "../../Helper/Theme";
import { useLocation, useNavigate } from "react-router-dom";
import { submitedFormListHandler } from "../../Service/commanForm.service";
import moment from "moment";
import { IoDownloadOutline, PictureAsPdfIcon } from "../../Helper/Icons";
import { Button, Modal } from "../../Component/Commons";
import {
  exportCsvDataByFormId, exportPdfDataByFormId,
} from "../../Service/NonGynCytopathology.service";
import { showNotification } from "../../Helper/Notification";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from '@mui/material/Skeleton';

const CreateFormTable = ({ submitFormList, formik, dashboard }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.root?.auth);

  // Pagination 
  const [page, setPage] = useState(1);
  const [totalSubmitList, setTotalSubmitList] = useState([]);
  const [skalaton, setSkalaton] = useState("")
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  useEffect(() => {
    if (submitFormList) {
      setTotalSubmitList(submitFormList);
      setTimeout(() => {
        setSkalaton("false")
      }, 500)

    } else {
      setTotalSubmitList([]);
    }
  }, [submitFormList])


  const handleChangePage = async (e, value) => {
    setSkalaton("")
    let selectQry = `?search=${formik.values.search}&page=${value}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&patient_state=${formik.values.patient_state}&patient_city=${formik.values.patient_city}&patient_sex=${formik.values.patient_sex}&form_type=${formik.values.form_type}`;
    setPage(value);
    const { data, message, status, count, totalPage } = await submitedFormListHandler(selectQry);
    if (status) {
      setTotalSubmitList(data);
      setSkalaton("false")
      formik.setFieldValue("totalCount", totalPage)
      formik.setFieldValue("totalCountData", count)


    } else {
      setTotalSubmitList([]);
      setSkalaton("false")
      formik.setFieldValue("totalCountData", 0)
      formik.setFieldValue("totalCount", 0)
    }
  };

  const handlePdfHandler = async (formId, formType) => {
    const { data, message, status } = await exportPdfDataByFormId(formId, formType);
    if (status) {
      const downloadLink = document.createElement("a");
      downloadLink.href = data.file_url;
      downloadLink.target = "_blank";
      // downloadLink.download = `${state.firstName} ${state.lastName}.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setIsModalOpen({
        open: true,
        currentComponent: "exportSuccess",
      });
    } else {
      showNotification("error", message || "Something went wrong");
    }
  };

  const HandleExportHandler = async (formId, formType) => {
    const { data, message, status } = await exportCsvDataByFormId(formId, formType);
    if (status) {
      const downloadLink = document.createElement("a");
      downloadLink.href = data[0].file_url;
      // downloadLink.download = `${state.firstName} ${state.lastName}.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setIsModalOpen({
        open: true,
        currentComponent: "exportSuccess",
      });
    } else {
      showNotification("error", message || "Something went wrong");
    }
  };

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={25} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={25} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={25} />
        </TableCell>

        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={25} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" width={210} height={25} />
        </TableCell>
      </TableRow>
    ));
  };

  console.log(totalSubmitList, 'totalSubmitList++');

  return (
    <>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sr No.</StyledTableCell>
              <StyledTableCell>Doctor Name</StyledTableCell>
              <StyledTableCell>Patient Name</StyledTableCell>
              <StyledTableCell>Date Collected</StyledTableCell>
              <StyledTableCell>Form Type</StyledTableCell>
              {user && user?.group_name == 'admin' && (
                <StyledTableCell></StyledTableCell>
              )}
              {/* {user && user?.group_name == 'admin' && (
                <StyledTableCell></StyledTableCell>
              )} */}
              {/* <StyledTableCell>Age</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {skalaton == "false" && totalSubmitList.length > 0 &&
              totalSubmitList.map((data, i) => {
                return (
                  <StyledTableRow
                    key={i}
                    onClick={() =>
                      navigate(
                        `${location.pathname === "/admin/submitted-forms"
                          ? "/admin/submitted-forms/view"
                          : location.pathname === "/admin" ? "/admin/submitted-forms/view" : "/submitted-forms/view"
                        }`, {
                        state: {
                          formType: data.form_type,
                          formId: data.form_id,
                          firstName: data.patient_first_name,
                          lastName: data.patient_last_name
                        }
                      }

                      )
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * 10 + (i + 1)}

                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.user_full_name ? data.user_full_name : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.patient_full_name ? data.patient_full_name : "-"}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {/* {moment(data?.created_at).format("MM-DD-YYYY LT")} */}
                      {moment.utc(data?.created_at).format("MM-DD-YYYY LT")}

                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data?.form_type}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      <Stack flexDirection={'row'} gap={1} justifyContent={'flex-end'}>
                        {user && user?.group_name == 'admin' && !dashboard && (
                          <Button
                            style={{ padding: "9px 11px", minWidth: "auto" }}
                            buttonName={
                              <IoDownloadOutline style={{ fontSize: "20px", cursor: "pointer" }} />
                            }
                            onClick={(e) => { e.stopPropagation(); HandleExportHandler(data.form_id, data.form_type) }}
                          />
                        )}
                        {data.form_type && user && user?.group_name == 'admin' && !dashboard && (
                          <Button
                            style={{ padding: "9px 11px", minWidth: "auto" }}
                            buttonName={
                              <PictureAsPdfIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                            }
                            onClick={(e) => { e.stopPropagation(); handlePdfHandler(data.form_id, data.form_type) }}
                          />
                        )}
                      </Stack>
                    </TableCell>
                    {/* {data.form_type === "NonGynCytopathologyRequisition" && user && user?.group_name == 'admin' && !dashboard && ( */}
                  </StyledTableRow>
                );
              })}


            {skalaton == "" && (
              <TableRowsLoader rowsNum={10} />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {skalaton == "false" && totalSubmitList?.length == 0 && (
        <div
          style={{
            color: "silver",
            textAlign: "center",
            paddingTop: "90px",
            borderBottom: "none",
            fontSize: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
            border: "1px solid #e0e0e0",
          }}
          colSpan="6"
        >
          No records to display
        </div>
      )}
      {!dashboard && (
        <Stack
          sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
          flexDirection="row"
          justifyContent="space-between"
        >

          <div>
            {`Showing ${totalSubmitList ? totalSubmitList?.length : 0} of ${formik.values?.totalCountData || 0} Results`}
          </div>

          <Pagination
            count={formik.values.totalCount}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
          <Modal
            modalOpen={isModalOpen}
            handleClose={() =>
              setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
            }
          />

        </Stack>
      )}
    </>
  );
};

export default CreateFormTable;
