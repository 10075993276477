import React, { useState, useEffect } from 'react'
import { Button, Modal } from '../../Component/Commons';
import { Stack, TextField, Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Switch, Menu, MenuItem, Grid, Pagination, Dialog } from '@mui/material';
import { StyledTableCell, StyledTableRow, useStyles, StyledChip, getUserStatus } from '../../Helper/Theme';
import { RiLockPasswordLine, MdOutlineMoreVert, EditIcon, DeleteIcon, ArticleIcon, VscSettings } from '../../Helper/Icons';
import FilterUser from './FilterUser';
import { showNotification } from '../../Helper/Notification'
import { userList, deleteUser, userStatusUpdate } from "../../Service/manageuser.service"
import moment from "moment";
import { Delete, ChangePassword } from '../../Component/modal';
import Skeleton from '@mui/material/Skeleton';
import { useFormik } from "formik";
import { userFilterIv } from "../../Helper/initialValues";
import { getHospitalData } from "../../Service/dropdown.service";


const UserManagement = () => {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [hospitalData, setHospitalData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        isEdit: "",
        data: null,
        clickSave: null
    });

    const [userListData, setUserListData] = useState([]);
    const [page, setPage] = useState(1);
    const [pagePerSize, setPagePerSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [viewUserId, setViewUserId] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [passChangeUserId, setPassChangeUserId] = useState(null);
    const [skalaton, setSkalaton] = useState("")
    const [deletePopup, setDeletePopup] = useState(false);
    const [statusPopup, setstatusPopup] = useState(false);
    const [changePassPopup, setChangePassPopup] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };


    const formik = useFormik({
        initialValues: userFilterIv,
        onSubmit: async (values) => {
            getUserList(1);
            setOpenDrawer(false);
        },
    });

    const handleChangePage = (event, value) => {
        setPage(value);
        getUserList(value);
    };


    const getDropdowndata = async () => {
        const res = await getHospitalData();
        if (res) {
            setHospitalData(res.data);
        } else {
            setHospitalData([]);
        }
    }

    useEffect(() => {
        getDropdowndata();
    }, [])

    const confirmStatusUpdate = async () => {
        if (viewUserId) {
            const resStatusUpdate = await userStatusUpdate(viewUserId);
            if (resStatusUpdate.status) {
                setstatusPopup(false)
                getUserList(page)
                showNotification("success", resStatusUpdate.message || 'Status has been updated successfully')
            } else {
                setstatusPopup(false)
                showNotification("error", resStatusUpdate.message || 'Failed to update status')
            }
        } else {
            showNotification("error", 'User Not selected')
        }

    }

    const confirmDelete = async () => {
        if (deleteUserId) {
            const deletRes = await deleteUser(deleteUserId);
            if (deletRes.status) {
                setDeletePopup(false);
                getUserList(page)
                showNotification("success", deletRes.message || 'User has been deleted successfully')
            } else {
                setDeletePopup(false);
                showNotification("error", deletRes.message || 'Failed to delete status')
            }
        } else {
            showNotification("error", 'User Not selected')
        }
    }

    const handleClickDelet = (id) => {
        setDeleteUserId(id);
        setDeletePopup(true);
    }

    const handleChange = (event, id) => {
        setViewUserId(id);
        setstatusPopup(true)
    };

    const handleChangePassSave = async (id) => {
        setPassChangeUserId(id);
        setChangePassPopup(true)
    }

    const confirmPassChange = async () => {

    }

    useEffect(() => {
        getUserList(1)
    }, [])

    const getUserList = async (page) => {
        try {
            setSkalaton("")
            let query = `?page=${page}&limit=${pagePerSize}&search=${formik.values.search}&start_date=${formik.values.startDate}&end_date=${formik.values.endDate}&facility_name=${formik.values.hospital}`;
            const res = await userList(query);
            const { data } = res;
            if (data.status) {
                setUserListData(data?.data)
                setSkalaton("false")
                formik.setFieldValue("totalCount", data?.total_page)
                formik.setFieldValue("totalCountData", data?.count)
            } else {
                setUserListData([])
                setSkalaton("false");
                formik.setFieldValue("totalCount", 0)
                formik.setFieldValue("totalCountData", 0)
            }
        } catch (error) {
            showNotification("error", 'Something went wrong')
        }
    }

    const TableRowsLoader = ({ rowsNum }) => {
        return [...Array(rowsNum)].map((row, index) => (
            <TableRow key={index}>
                <TableCell component="th" scope="row">
                    <Skeleton animation="wave" variant="text" width={210} height={25} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={25} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={25} />
                </TableCell>

                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={25} />
                </TableCell>
                <TableCell>
                    <Skeleton animation="wave" variant="text" width={210} height={25} />
                </TableCell>


            </TableRow>
        ));
    };


    return (
        <>
            <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px', alignItems: { xs: 'end', sm: 'center' } }}>
                <TextField hiddenLabel autoComplete="off" className='search_field' placeholder='Search' sx={{ width: { xs: '100%', sm: 'unset' } }} onChange={(event) => {
                    formik.setFieldValue("search", event.target.value);
                    formik.handleSubmit()
                }} />
                <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                    <Button buttonName="Add User" onClick={() => setIsModalOpen({ open: true, currentComponent: "addUser", data: null, isEdit: '' })} />
                    <Button style={{ padding: '9px 11px', minWidth: 'auto' }} buttonName={<VscSettings style={{ fontSize: '20px', cursor: 'pointer' }} />} onClick={toggleDrawer} />
                </Stack>
                <FilterUser openDrawer={openDrawer} toggleDrawer={toggleDrawer} formik={formik} hospitalData={hospitalData} />
            </Stack>
            <TableContainer component={Paper} className={classes.cardContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                Sr No.
                            </StyledTableCell>
                            <StyledTableCell>
                                User Name
                            </StyledTableCell>
                            <StyledTableCell>
                                Email
                            </StyledTableCell>
                            <StyledTableCell>
                                Join Date
                            </StyledTableCell>
                            <StyledTableCell>
                                Status
                            </StyledTableCell>
                            <StyledTableCell>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {skalaton == "false" && userListData.length > 0 && userListData?.map((data, i) => {
                            return (
                                <StyledTableRow key={i}
                                    onClick={() => {
                                        setIsModalOpen({ open: true, currentComponent: "viewUser", data: data, isEdit: true })
                                    }}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                    <TableCell component="th" scope="row">
                                        
                                        {(page - 1) * pagePerSize + (i + 1)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {`${data?.username ? data?.username : '-'}`}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {data?.email ? data?.email : "-"}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {moment(data?.created_at).format("MM-DD-YYYY LT")}
                                    </TableCell>
                                    <TableCell component="th" scope="row" onClick={(e) => { e.stopPropagation(); }}>
                                        <Switch
                                            checked={data?.is_active}
                                            onChange={(e) => { handleChange(e, data.id); }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row" width={'100px'} onClick={(e) => { e.stopPropagation() }}>
                                        <Stack flexDirection={'row'} gap={1} justifyContent={'center'}>

                                            <Button color='silver' style={{ padding: '7px', minWidth: 'auto' }} buttonName={<RiLockPasswordLine style={{ fontSize: '15px' }} />} onClick={() => handleChangePassSave(data.id)} />
                                            <Button style={{ padding: '7px', minWidth: 'auto' }} buttonName={<EditIcon style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => setIsModalOpen({ open: true, currentComponent: "addUser", data: data, isEdit: true })} />} />
                                            <Button color='red' style={{ padding: '7px', minWidth: 'auto' }} buttonName={<DeleteIcon style={{ fontSize: '15px', cursor: 'pointer' }} />} onClick={() => handleClickDelet(data.id)} />
                                        </Stack>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })}
                       
                        {skalaton == "" && (
                            <TableRowsLoader rowsNum={10} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer >

            {skalaton == "false" && userListData?.length == 0 && (
                <div
                    style={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                        border: "1px solid #e0e0e0",
                    }}
                    colSpan="6"
                >
                    No records to display
                </div>
            )}

            <Stack
                sx={{ mt: 4, pb: 2 }}
                flexDirection="row"
                justifyContent="space-between"
            >
               
                <div>
                    {`Showing ${userListData ? userListData?.length : 0} of ${formik.values?.totalCountData} Results`}
                </div>
                <Pagination
                    count={formik.values.totalCount}
                    page={page}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>

            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: "", clickSave: null })
                }
                refreshApi={() => getUserList(1)}
            />

            <Dialog
                open={deletePopup}
                aria-labelledby="Common_modal">
                <Delete
                    confirmDelete={confirmDelete}
                    setDeletePopup={setDeletePopup}
                    title="Delete User"
                    message="Are you sure want to Delete this user."
                />
            </Dialog>

            <Dialog
                open={statusPopup}
                aria-labelledby="Common_modal">
                <Delete
                    confirmDelete={confirmStatusUpdate}
                    setDeletePopup={setstatusPopup}
                    title="Change Status"
                    message="Are you sure want to change user status."
                />
            </Dialog>

            <Dialog
                open={changePassPopup}
                aria-labelledby="Common_modal">
                <ChangePassword
                    setChangePassPopup={setChangePassPopup}
                    confirmPassChange={confirmPassChange}
                    passChangeUserId={passChangeUserId}
                />
            </Dialog>
        </>
    )
}

export default UserManagement