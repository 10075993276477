import { get, post } from "../web.request"

export const createNonCytDataHandler = (data) =>{
    return post(`/patientform/non_gyn_cytopathology_requisition_form`,data);
}

export const getCreateNonGynDataHandler = (formId) =>{
    return get(`/patientform/non_gyn_cytopathology_requisition_form?form_id=${formId}`)
};

// export as csv
export const exportCsvDataByFormId = (formId,formType) =>{
    return get(`/patientform/export_all_form_excel/${formType}/${formId}`)
}

export const exportPdfDataByFormId = (formId,formType) =>{
    return get(`/patientform/export_all_form_Pdf/${formType}/${formId}`)
}