import React, { useState, useEffect } from "react";
import {
    Box,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { Button, Checkbox, Modal } from "../../Component/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "../../Helper/Theme";
import UserDetails from "../../Pages/createtest/UserDetails";
import { useFormik } from "formik";
import { molecularIv } from "../../Helper/initialValues";
import { molecularValidation } from "../../Helper/validation";
import { getMolecularFormByFormId } from "../../Service/molecular.service";
import { useSelector } from "react-redux";



const MolecularFormView = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        viewFormApiHandler();
    }, []);


    const viewFormApiHandler = async () => {
        const { data, status } = await getMolecularFormByFormId(
            state.formId
        );
        if (status) {
            formik.setValues({
                ...formik.values,
                referByUser: {
                    facility_name: data?.user_information?.facility_name,
                    doctor: data?.user_information?.full_name,
                    address: data?.user_information?.address,
                    phone: data?.user_information?.phone,
                    fax: data?.user_information?.fax,
                },
                patientInformation: {
                    patient_first_name: data?.patient_information?.patient_first_name,
                    patient_last_name: data?.patient_information?.patient_last_name,
                    initial: data?.patient_information?.initial,
                    patient_address: data?.patient_information?.patient_address,
                    patient_city: data?.patient_information?.patient_city,
                    patient_state: data?.patient_information?.patient_state,
                    patient_zip: data?.patient_information?.patient_zip,
                    patient_area_code: data?.patient_information?.patient_area_code,
                    patient_age: data?.patient_information?.patient_age,
                    patient_sex: data?.patient_information?.patient_sex,
                    patient_dob: data?.patient_information?.patient_dob,
                    patient_phone: data?.patient_information?.patient_phone,
                    patient_payment: data?.patient_information?.patient_payment,
                    pay_amount: data?.patient_information?.pay_amount,
                    bill_number: data?.patient_information?.bill_number,
                    bill_amount: data?.patient_information?.bill_amount,
                    insurance_company: data?.patient_information?.insurance_company,
                    insurance_no: data?.patient_information?.insurance_no,
                    icd_10: data?.patient_information?.icd_10,
                    clinical_history: data?.patient_information?.clinical_history,
                    additional_physician: data?.patient_information?.additional_physician == null ? "": data?.patient_information?.additional_physician,
                    other_test: data?.patient_information?.other_test,
                    pationtDoc : [data?.patient_information?.patient_document],
                },
                physician_order: data?.molecular_requisition_information?.physician_order,
                pneumonia_panel: data?.molecular_requisition_information?.pneumonia_panel,
                apex_dx_utm_swab: data?.molecular_requisition_information?.apex_dx_utm_swab,
                urine: data?.molecular_requisition_information?.urine,
            });
        }
    };

    const formik = useFormik({
        initialValues: molecularIv,
        validationSchema: molecularValidation,
        onSubmit: async (values) => {
        },
    });

    const handleOrderChange = (formik, orderFieldName, e) => {
        const currentValue = formik.values[orderFieldName];

        if (e.target.checked) {
            formik.setFieldValue(orderFieldName, [...currentValue, e.target.value]);
        } else {
            formik.setFieldValue(orderFieldName, currentValue.filter(item => item !== e.target.value));
        }
    };
    return (
        <>
            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
                }
            />
            <Box
            >
                <UserDetails formik={formik} view={true} isFormSubmitted={isFormSubmitted} />

                <Typography fontWeight={"700"} fontSize={{ xs: "15px", sm: "18px" }}>
                    Physician's Orders
                </Typography>
                <Divider />
                <Box marginTop={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={{ xs: 2, sm: 3 }}>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="COVID19"
                                        singleCheckbox
                                        view={true}
                                        value='COVID19'
                                        checkboxStyle={{ padding: "0 10px" }}
                                        checked={formik.values.physician_order?.includes('COVID19')}
                                        onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="Bordetella PCR Panel ( pertussis, parapertussis, hoirnesii )"
                                        singleCheckbox
                                        view={true}
                                                                                checkboxStyle={{ padding: "0 10px" }}
                                        value='Bordetella PCR Panel (pertussis, parapertussis, hoirnesii)'
                                        checked={formik.values.physician_order?.includes('Bordetella PCR Panel (pertussis, parapertussis, hoirnesii)')}
                                        onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Nasophary ngeai swab specimen
                                        </Typography>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            ( UTM or blue top BD ESWab )
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="Group A Strep ( Nucieic acid arnollfication testing )"
                                        singleCheckbox
                                        view={true}
                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='Group A Strep ( Nucieic acid arnollfication testing )'
                                        checked={formik.values.physician_order?.includes('Group A Strep ( Nucieic acid arnollfication testing )')}
                                        onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Throat swab specimen
                                        </Typography>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            ( while top swab in liquid stuart media )
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="Influenza A and B (by PCR)"
                                        singleCheckbox
                                        view={true}
                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='Influenza A and B (by PCR)'
                                        checked={formik.values.physician_order?.includes('Influenza A and B (by PCR)')}
                                        onChange={(e) => handleOrderChange(formik, 'physician_order', e)}

                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Nasopharyngeal swab specimen
                                        </Typography>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            ( red top swab UTM kit )
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="Influenza A & B, RSV (by PCR)"
                                        singleCheckbox
                                        view={true}
                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='Influenza A & B, RSV (by PCR)'
                                        checked={formik.values.physician_order?.includes('Influenza A & B, RSV (by PCR)')}
                                        onChange={(e) => handleOrderChange(formik, 'physician_order', e)}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Nasopharyngeal swab specimen
                                        </Typography>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            ( red top swab UTM kit )
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="Respiratory Panel (21 targets) (by PCR)"
                                        singleCheckbox
                                        view={true}
                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='Respiratory Panel (21 targets) (by PCR)'
                                        checked={formik.values.physician_order?.includes('Respiratory Panel (21 targets) (by PCR)')}
                                        onChange={(e) => handleOrderChange(formik, 'physician_order', e)}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Nasopharyngeal swab specimen
                                        </Typography>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            ( BD Universel viral Traoget )
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        fontWeight={"600"}
                                        fontSize={{ xs: "14px", sm: "16px" }}
                                    >
                                        Pneumonia Panel
                                    </Typography>
                                    <Box marginLeft={3}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Sputum specimen
                                        </Typography>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            ( Sputum specimen )
                                        </Typography>
                                    </Box>
                                    <Checkbox
                                        label="W/ culture"
                                        singleCheckbox
                                        view={true}
                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='W/ culture'
                                        checked={formik.values.pneumonia_panel?.includes('W/ culture')}
                                        onChange={(e) => handleOrderChange(formik, 'pneumonia_panel', e)}
                                    />
                                    <Checkbox
                                        label="Without culture"
                                        singleCheckbox
                                        view={true}

                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='Without culture'
                                        checked={formik.values.pneumonia_panel?.includes('Without culture')}
                                        onChange={(e) => handleOrderChange(formik, 'pneumonia_panel', e)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="Oral HPV ( Scope rinse in sterile cup )"
                                        singleCheckbox
                                        view={true}

                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='Oral HPV ( Scope rinse in sterile cup )'
                                        checked={formik.values.pneumonia_panel?.includes('Oral HPV ( Scope rinse in sterile cup )')}
                                        onChange={(e) => handleOrderChange(formik, 'pneumonia_panel', e)}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Also send Dacron swab in Thinprep vial
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        fontWeight={"600"}
                                        fontSize={{ xs: "14px", sm: "16px" }}
                                    >
                                        Apex Dx UTM Swab
                                    </Typography>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="Bacterial vaginosis"
                                            singleCheckbox
                                            view={true}

                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Bacterial vaginosis'
                                            checked={formik.values.apex_dx_utm_swab?.includes('Bacterial vaginosis')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="Candida"
                                            view={true}

                                            singleCheckbox
                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Candida'
                                            checked={formik.values.apex_dx_utm_swab?.includes('Candida')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="Mycoplasma/Ureaplasma"
                                            singleCheckbox
                                            view={true}

                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Mycoplasma/Ureaplasma'
                                            checked={formik.values.apex_dx_utm_swab?.includes('Mycoplasma/Ureaplasma')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="HSV 1&2"
                                            singleCheckbox
                                            view={true}

                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='HSV 1&2'
                                            checked={formik.values.apex_dx_utm_swab?.includes('HSV 1&2')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="Trichomonas"
                                            view={true}

                                            singleCheckbox
                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Trichomonas'
                                            checked={formik.values.apex_dx_utm_swab?.includes('Trichomonas')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="CT/GC (mark source)"
                                            singleCheckbox
                                            view={true}

                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='CT/GC (mark source)'
                                            checked={formik.values.apex_dx_utm_swab?.includes('CT/GC (mark source)')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={7}>
                                        <Checkbox
                                            label="Vaginal"
                                            singleCheckbox
                                            view={true}

                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Vaginal'
                                            checked={formik.values.apex_dx_utm_swab?.includes('Vaginal')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={7}>
                                        <Checkbox
                                            label="Throat"
                                            view={true}

                                            singleCheckbox
                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Throat'
                                            checked={formik.values.apex_dx_utm_swab?.includes('Throat')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={7}>
                                        <Checkbox
                                            label="Rectal"
                                            view={true}

                                            singleCheckbox
                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Rectal'
                                            checked={formik.values.apex_dx_utm_swab?.includes('Rectal')}
                                            onChange={(e) => handleOrderChange(formik, 'apex_dx_utm_swab', e)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        fontWeight={"600"}
                                        fontSize={{ xs: "14px", sm: "16px" }}
                                    >
                                        Urine
                                    </Typography>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="CT/GC"
                                            singleCheckbox
                                            view={true}

                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='CT/GC'
                                            checked={formik.values.urine?.includes('CT/GC')}
                                            onChange={(e) => handleOrderChange(formik, 'urine', e)}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox
                                            label="Trichomonas"
                                            view={true}

                                            singleCheckbox
                                            checkboxStyle={{ padding: "0 10px" }}
                                            value='Trichomonas'
                                            checked={formik.values.urine?.includes('Trichomonas')}
                                            onChange={(e) => handleOrderChange(formik, 'urine', e)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="C. Diff only"
                                        view={true}

                                        singleCheckbox
                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='C. Diff only'
                                        checked={formik.values.urine?.includes('C. Diff only')}
                                        onChange={(e) => handleOrderChange(formik, 'urine', e)}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            (PCR for taxigenic C. dimelle and hypervirulent 027/NAP
                                            1/8 straig)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="Diff GDH and Toxin EIA "
                                        singleCheckbox
                                        view={true}

                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='Diff GDH and Toxin EIA'
                                        checked={formik.values.urine?.includes('Diff GDH and Toxin EIA')}
                                        onChange={(e) => handleOrderChange(formik, 'urine', e)}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Stool specimen in Cary Blair or white top transport media
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        label="GI Panel (22 targets) (by PCR)"
                                        singleCheckbox
                                        view={true}

                                        checkboxStyle={{ padding: "0 10px" }}
                                        value='GI Panel (22 targets) (by PCR)'
                                        checked={formik.values.urine?.includes('GI Panel (22 targets) (by PCR)')}
                                        onChange={(e) => handleOrderChange(formik, 'urine', e)}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={"300"} fontSize={"14px"}>
                                            Stool specimen in Cary Blair transport media
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {location.pathname === "/admin/submitted-forms/view" ? (
                    ""
                ) : (
                    <>
                        <Divider
                            sx={{
                                marginTop: "20px !important",
                                marginBottom: "16px !important",
                            }}
                        />
                        <Stack
                            gap={"10px"}
                            flexDirection={"row"}
                            justifyContent={"flex-end"}
                        >
                            <Button buttonName="Cancel" onClick={() => navigate(-1)} />
                        </Stack>
                    </>
                )}
            </Box>
        </>
    );
};

export default MolecularFormView;
