import React from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const CommonRadio = (props) => {
  const location = useLocation();
  const { labelInput, radioList, name, value, onChange, view } = props;

  return (
    <>
      <Typography
        fontSize={{ xs: "12px", sm: "14px" }}
        fontWeight={600}
        marginBottom={{ xs: "0px", sm: "6px" }}
        color={"#000"}
      >
        {labelInput}
      </Typography>
      <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      >
        {radioList.map((data, i) => {
          return (
            <FormControlLabel
              key={i}
              value={data.value}
              control={<Radio />}
              label={data.label}
              disabled={view}
            //   disabled={
            //     location.pathname === "/admin/submitted-forms/view"
            //       ? true
            //       : false
            //   }
            />
          );
        })}
      </RadioGroup>
    </>
  );
};

export default CommonRadio;
