import React, { useState } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { Input, Radio } from '../../Component/Commons';
const StaticUserDetails = ({ handleStepTwo }) => {

    const [selectedBillList, setSelectedBillList] = useState('');

    const sexList = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
    ]
    const billList = [
        { value: 'bill_patient', label: 'Bill Patient' },
        { value: 'bill_insurance', label: 'Bill Insurance' },
        { value: 'uninsured_cash_pay', label: 'Uninsured / Cash Pay' },
    ]

    const handleRadioChange = (event) => {
        setSelectedBillList(event.target.value);
    };

    return (
        <>
            <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>Referred By: <small>(if Applicable)</small></Typography>
            <Divider />
            <Box marginTop={2} marginBottom={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Input labelInput='Facility Name' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input labelInput='Doctor' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Input labelInput='Address' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input labelInput='Phone' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input labelInput='Fax' />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Input labelInput='Copies to additional physician ( print full name )' />
                    </Grid>
                </Grid>
            </Box>
            <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>Patient Information</Typography>
            <Divider />
            <Box marginTop={2} marginBottom={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Input labelInput='First Name' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Input labelInput='Last Name' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Input labelInput='Initial' />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelInput='Address' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input labelInput='City' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input labelInput='State' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input labelInput='Zip Code' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input labelInput='Area Code' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input labelInput='Age' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Radio labelInput='Sex' radioList={sexList} row name='sex' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input labelInput='Birth Date' type='date' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input labelInput='Phone' />
                    </Grid>
                    <Grid item xs={12}>
                        <Radio radioList={billList} row name='billList' value={selectedBillList} onChange={handleRadioChange} />
                    </Grid>
                    <Grid item xs={12}>
                        {selectedBillList === 'bill_patient' && (
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Input labelInput='Bill No' />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input labelInput='Bill Amount' />
                                </Grid>
                            </Grid>
                        )}
                        {selectedBillList === 'bill_insurance' && (
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Input labelInput='Insurance Company' />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input labelInput='Insurance No' />
                                </Grid>
                            </Grid>
                        )}
                        {selectedBillList === 'uninsured_cash_pay' && (
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Input labelInput='Pay Amount' />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Input labelInput='ICD-10' />
                    </Grid>
                    <Grid item xs={6}>
                        <Input labelInput='Clinical History' />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelInput='Other Tests/Additional Tests'/>
                    </Grid>
                </Grid>
            </Box>
        </>

    )
}

// export default UserDetails

export default StaticUserDetails