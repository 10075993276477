import React, { useState, useEffect } from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { Checkbox, Input, Modal } from '../../Component/Commons';
import { useLocation } from 'react-router-dom';
import UserDetails from '../../Pages/createtest/UserDetails';
import { useFormik } from "formik";
import { gynIv } from "../../Helper/initialValues";
import { gynValidation } from "../../Helper/validation";
import { getGynecologyFormByFormId } from "../../Service/gynecology.service";

const GynecologicalCytologyFormView = () => {
    const { state } = useLocation();

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });

    useEffect(() => {viewFormApiHandler()} , []);


    const viewFormApiHandler = async () => {
        const { data, status } = await getGynecologyFormByFormId(
            state.formId
        );
        if (status) {
            const { gynecological_cytology_requisition_information } = data;
            formik.setValues({
                ...formik.values,
                referByUser: {
                    facility_name: data?.user_information?.facility_name,
                    doctor: data?.user_information?.full_name,
                    address: data?.user_information?.address,
                    phone: data?.user_information?.phone,
                    fax: data?.user_information?.fax,
                },
                patientInformation: {
                    patient_first_name: data?.patient_information?.patient_first_name,
                    patient_last_name: data?.patient_information?.patient_last_name,
                    initial: data?.patient_information?.initial,
                    patient_address: data?.patient_information?.patient_address,
                    patient_city: data?.patient_information?.patient_city,
                    patient_state: data?.patient_information?.patient_state,
                    patient_zip: data?.patient_information?.patient_zip,
                    patient_area_code: data?.patient_information?.patient_area_code,
                    patient_age: data?.patient_information?.patient_age,
                    patient_sex: data?.patient_information?.patient_sex,
                    patient_dob: data?.patient_information?.patient_dob,
                    patient_phone: data?.patient_information?.patient_phone,
                    patient_payment: data?.patient_information?.patient_payment,
                    pay_amount: data?.patient_information?.pay_amount,
                    bill_number: data?.patient_information?.bill_number,
                    bill_amount: data?.patient_information?.bill_amount,
                    insurance_company: data?.patient_information?.insurance_company,
                    insurance_no: data?.patient_information?.insurance_no,
                    icd_10: data?.patient_information?.icd_10,
                    clinical_history: data?.patient_information?.clinical_history,
                    additional_physician: data?.patient_information?.additional_physician == null ? "" : data?.patient_information?.additional_physician,
                    other_test: data?.patient_information?.other_test,
                    pationtDoc : [data?.patient_information?.patient_document],
                },
              
                submit_in_formalin1: gynecological_cytology_requisition_information?.submit_in_formalin[0],
                submit_in_formalin2: gynecological_cytology_requisition_information?.submit_in_formalin[1],
                submit_in_formalin3: gynecological_cytology_requisition_information?.submit_in_formalin[2],
                submit_in_formalin4: gynecological_cytology_requisition_information?.submit_in_formalin[3],
                submit_in_formalin5: gynecological_cytology_requisition_information?.submit_in_formalin[4],
                lmp: gynecological_cytology_requisition_information.lmp,
                diagnosis: gynecological_cytology_requisition_information.diagnosis,
                icd_10_code: gynecological_cytology_requisition_information.icd_10_code,
                specimen_source: gynecological_cytology_requisition_information.specimen_source,
                previous_pap_dx: gynecological_cytology_requisition_information.previous_pap_dx,
                clinical_Impression: gynecological_cytology_requisition_information.clinical_Impression,
                hormonal_therapy: gynecological_cytology_requisition_information.hormonal_therapy,
                hormonal_therapy_other: gynecological_cytology_requisition_information.hormonal_therapy_other,
                thinprep_pap_smear: gynecological_cytology_requisition_information.thinprep_pap_smear,
                thinprep_with_reflex_on_ascus: gynecological_cytology_requisition_information.thinprep_with_reflex_on_ascus,
                thinprep_with_reflex_on_ascus_or_higher: gynecological_cytology_requisition_information.thinprep_with_reflex_on_ascus_or_higher,
                bacterial_vaginosis: gynecological_cytology_requisition_information.bacterial_vaginosis,
                candida: gynecological_cytology_requisition_information.candida,
                mycoplasma: gynecological_cytology_requisition_information.mycoplasma,
                hsv_1_2: gynecological_cytology_requisition_information.hsv_1_2,
                trichomonas: gynecological_cytology_requisition_information.trichomonas,
                gbs: gynecological_cytology_requisition_information.gbs,
                icd_10_codes: gynecological_cytology_requisition_information.icd_10_codes,
                blank_box: gynecological_cytology_requisition_information.blank_box,
                ct_gc: gynecological_cytology_requisition_information.ct_gc,
                affirm_testbd_Vaginala_endocervical: gynecological_cytology_requisition_information.affirm_testbd_Vaginala_endocervical,
            });
        }
    };

    const formik = useFormik({
        initialValues: gynIv,
        validationSchema: gynValidation,
        onSubmit: async (values) => {
        },
    });

    const handleOrderChange = (formik, orderFieldName, e) => {
        const currentValue = formik.values[orderFieldName];

        if (e.target.checked) {
            formik.setFieldValue(orderFieldName, [...currentValue, e.target.value]);
        } else {
            formik.setFieldValue(orderFieldName, currentValue.filter(item => item !== e.target.value));
        }
    };

    const specimenList = [
        { label: "Vaginal", value: "Vaginal", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
        { label: "Cervical", value: "Cervical", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
        { label: "Endocervical", value: "Endocervical", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
        { label: "Conventional Pap", value: "Conventional Pap", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
    ]

    const gynSpecimen = [
        { label: "Hormonal Therapy", value: "Hormonal Therapy", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Quo", value: "Quo", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "DES Exposure", value: "DES Exposure", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Chemotherapy", value: "Chemotherapy", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Radiation Therapy", value: "Radiation Therapy", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Birth Control", value: "Birth Control", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Postpartum", value: "Postpartum", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Hysterectomy", value: "Hysterectomy", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Supracervical", value: "Supracervical", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Pregnant", value: "Pregnant", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Postmenopausal", value: "Postmenopausal", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "GYN Surgeries", value: "GYN Surgeries", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Hx of CA", value: "Hx of CA", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
        { label: "Other History", value: "Other History", onChange: (e) => [handleOrderChange(formik, "hormonal_therapy", e), otherChange(e, "hormonal_therapy_other")] }
    ]

    const otherChange = (e, name) => {
        formik.setFieldValue(name, "");
    }

    return (
        <>

            <Modal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
                } />
            <Box >
                <UserDetails formik={formik} view={true} isFormSubmitted={false} />

                <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>Pathology Specimens</Typography>
                <Divider />
                <Box marginTop={2}>
                    <Typography fontWeight={'600'}>Submit in Formalin</Typography>
                    <Stack flexDirection={{ xs: 'column', sm: 'row' }} gap={2}>
                        <Box width={'100%'}>
                            <Input disabled={true} labelInput='1'
                                onChange={(e) => {
                                    formik.setFieldValue("submit_in_formalin1", e.target.value);
                                }}
                                value={formik.values.submit_in_formalin1} />

                        </Box>
                        <Box width={'100%'}>
                            <Input disabled={true} labelInput='2'
                                onChange={(e) => {
                                    formik.setFieldValue("submit_in_formalin2", e.target.value);
                                }}
                                value={formik.values.submit_in_formalin2} />
                        </Box>
                        <Box width={'100%'}>
                            <Input disabled={true} labelInput='3'
                                onChange={(e) => {
                                    formik.setFieldValue("submit_in_formalin3", e.target.value);
                                }}
                                value={formik.values.submit_in_formalin3} />
                        </Box>
                        <Box width={'100%'}>
                            <Input disabled={true} labelInput='4'
                                onChange={(e) => {
                                    formik.setFieldValue("submit_in_formalin4", e.target.value);
                                }}
                                value={formik.values.submit_in_formalin4} />
                        </Box>
                        <Box width={'100%'}>
                            <Input disabled={true} labelInput='5'
                                onChange={(e) => {
                                    formik.setFieldValue("submit_in_formalin5", e.target.value);
                                }}
                                value={formik.values.submit_in_formalin5} />
                        </Box>
                    </Stack>
                </Box>

                <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Gyn Cytology Specimens</Typography>
                <Divider />
                <Box marginTop={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Input disabled={true} labelInput='LMP' type='date'
                                onChange={(e) => {
                                    formik.setFieldValue("lmp", e.target.value);
                                }}
                                value={formik.values.lmp}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Input disabled={true} labelInput='Diagnosis'
                                onChange={(e) => {
                                    formik.setFieldValue("diagnosis", e.target.value);
                                }}
                                value={formik.values.diagnosis}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Input disabled={true} labelInput='ICD-10 Code'
                                onChange={(e) => {
                                    formik.setFieldValue("icd_10_code", e.target.value);
                                }}
                                value={formik.values.icd_10_code}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontWeight={'600'} fontSize={{ xs: '12px', sm: '14px' }}>For Medicare Patient With No Diagnosis, Please Attach Abn.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} labelInput='Specimen Source' checkboxList={specimenList} row
                                checked={formik.values.specimen_source}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Input disabled={true} labelInput='Previous Pap Dx:'
                                onChange={(e) => {
                                    formik.setFieldValue("previous_pap_dx", e.target.value);
                                }}
                                value={formik.values.previous_pap_dx}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input disabled={true} labelInput='Clinical Impression / Pertinent History'
                                onChange={(e) => {
                                    formik.setFieldValue("clinical_Impression", e.target.value);
                                }}
                                value={formik.values.clinical_Impression}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox disabled={true} checkboxList={gynSpecimen} row
                                checked={formik.values.hormonal_therapy}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {formik.values.hormonal_therapy?.includes("Other History") && (
                                <Input disabled={true} variant='standard'
                                    placeholder='Other History'
                                    name='hormonal_therapy_other' onChange={formik.handleChange} value={formik.values.hormonal_therapy_other} />
                            )}

                        </Grid>
                    </Grid>
                </Box>

                <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Physician's Orders</Typography>
                <Divider />
                <Box marginTop={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontWeight={'700'} fontSize={{ xs: '12px', sm: '14px' }}>Note:  “HPV” Includes High Risk HPV w/ 16/18 subtyping if positive</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                ThinPrep Pap smear
                                            </Typography>
                                        }
                                        value="ThinPrep Pap smear"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "thinprep_pap_smear", e);
                                        }}
                                        checked={formik.values.thinprep_pap_smear?.includes("ThinPrep Pap smear")}
                                    />
                                    <Box marginLeft={4} marginTop={1}>
                                        <Checkbox disabled={true}
                                            label="HPV"
                                            singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                            onChange={(e) => {
                                                handleOrderChange(formik, "thinprep_pap_smear", e);
                                            }}
                                            value="HPV"
                                            checked={formik.values.thinprep_pap_smear?.includes("HPV")}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox disabled={true}
                                            label="Chlamydia by PCR"
                                            singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                            onChange={(e) => {
                                                handleOrderChange(formik, "thinprep_pap_smear", e);
                                            }}
                                            value="Chlamydia by PCR"
                                            checked={formik.values.thinprep_pap_smear?.includes("Chlamydia by PCR")}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox disabled={true}
                                            label="Gonorrhea by PCR"
                                            singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                            onChange={(e) => {
                                                handleOrderChange(formik, "thinprep_pap_smear", e);
                                            }}
                                            value="Gonorrhea by PCR"
                                            checked={formik.values.thinprep_pap_smear?.includes("Gonorrhea by PCR")}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={2}>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                ThinPrep with reflex on ASCUS
                                            </Typography>
                                        }
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            // formik.setFieldValue("thinprep_with_reflex_on_ascus", e.target.value);
                                            handleOrderChange(formik, "thinprep_with_reflex_on_ascus", e);

                                        }}
                                        value="ThinPrep with reflex on ASCUS"
                                        checked={formik.values.thinprep_with_reflex_on_ascus?.includes("ThinPrep with reflex on ASCUS")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                ThinPrep with reflex on ASCUS or higher
                                            </Typography>
                                        }
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "thinprep_with_reflex_on_ascus_or_higher", e);
                                        }}
                                        value="ThinPrep with reflex on ASCUS or higher"
                                        checked={formik.values.thinprep_with_reflex_on_ascus_or_higher?.includes("ThinPrep with reflex on ASCUS or higher")}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={1} marginTop={0}>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                Bacterial vaginosis (Apex Dx UTM Swab)
                                            </Typography>
                                        }
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "bacterial_vaginosis", e);
                                        }}
                                        value="Bacterial vaginosis (Apex Dx UTM Swab)"
                                        checked={formik.values.bacterial_vaginosis?.includes("Bacterial vaginosis (Apex Dx UTM Swab)")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                Candida (Apex Dx UTM Swab)
                                            </Typography>
                                        }
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "candida", e);
                                        }}
                                        value="Candida (Apex Dx UTM Swab)"
                                        checked={formik.values.candida?.includes("Candida (Apex Dx UTM Swab)")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                Mycoplasma/Ureaplasma (Apex Dx UTM Swab)
                                            </Typography>
                                        }
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "mycoplasma", e);
                                        }}
                                        value="Mycoplasma/Ureaplasma (Apex Dx UTM Swab)"
                                        checked={formik.values.mycoplasma?.includes("Mycoplasma/Ureaplasma (Apex Dx UTM Swab)")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                HSV 1&2 (Apex Dx UTM Swab)
                                            </Typography>
                                        }
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "hsv_1_2", e);
                                        }}
                                        value="HSV 1&2 (Apex Dx UTM Swab)"
                                        checked={formik.values.hsv_1_2?.includes("HSV 1&2 (Apex Dx UTM Swab)")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} marginTop={1}>
                                <Grid item xs={12}>
                                    <Typography fontWeight={'600'} fontSize={{ xs: '14px', sm: '16px' }}> Trichomonas </Typography>
                                    <Box marginLeft={4} marginTop={1}>
                                        <Checkbox disabled={true}
                                            label="Apex Dx UTM Swab (Vaginal/Endocervical)"
                                            singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                            onChange={(e) => {
                                                handleOrderChange(formik, "trichomonas", e);
                                            }}
                                            value="Apex Dx UTM Swab (Vaginal/Endocervical)"
                                            checked={formik.values.trichomonas?.includes("Apex Dx UTM Swab (Vaginal/Endocervical)")}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox disabled={true}
                                            label="Urine"
                                            singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                            onChange={(e) => {
                                                handleOrderChange(formik, "trichomonas", e);
                                            }}
                                            value="Urine"
                                            checked={formik.values.trichomonas?.includes("Urine")}
                                        />
                                    </Box>
                                    <Box marginLeft={4}>
                                        <Checkbox disabled={true}
                                            label="ThinPrep"
                                            singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                            onChange={(e) => {
                                                handleOrderChange(formik, "trichomonas", e);
                                            }}
                                            value="ThinPrep"
                                            checked={formik.values.trichomonas?.includes("ThinPrep")}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginTop={0}>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label={
                                            <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                                                GBS w/ reflex to susceptibility if positive (rectal/vaginal)
                                            </Typography>
                                        }
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "gbs", e);
                                        }}
                                        value="GBS w/ reflex to susceptibility if positive (rectal/vaginal)"
                                        checked={formik.values.gbs?.includes("GBS w/ reflex to susceptibility if positive (rectal/vaginal)")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>ICD-10 Codes</Typography>
                                </Grid>
                                <Grid item xs={12} marginBottom={1}>
                                    <Typography fontWeight={'600'} fontSize={{ xs: '12px', sm: '14px' }}>For your convenience, a partial list of common diagnoses follows. For a complete listing of all ICD-10 codes, refer to a current version of the ICD-10 book.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label=" Z01.411 Encounter for GYN exam (general)"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="Z01.411 Encounter for GYN exam (general)"
                                        checked={formik.values.icd_10_codes?.includes("Z01.411 Encounter for GYN exam (general)")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>(routine) w/abnormal findings</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="Z01.419 Encounter for GYN exam (general)"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="Z01.419 Encounter for GYN exam (general)"
                                        checked={formik.values.icd_10_codes?.includes("Z01.419 Encounter for GYN exam (general)")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}> (routine) w/o abnormal findings</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="Z12.4 Encounter for screening for malignant "
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="Z12.4 Encounter for screening for malignant"
                                        checked={formik.values.icd_10_codes?.includes("Z12.4 Encounter for screening for malignant")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}> neoplasm of cervix</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="Z12.82 Encounter for screening for malignant"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="Z12.82 Encounter for screening for malignant"
                                        checked={formik.values.icd_10_codes?.includes("Z12.82 Encounter for screening for malignant")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>neoplasm of nervous system</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label=" Z86.19 Personal history of other infectious and"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="Z86.19 Personal history of other infectious and"
                                        checked={formik.values.icd_10_codes?.includes("Z86.19 Personal history of other infectious and")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>parasitic diseases.</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="N72 Inflammatory disease of cervix uteri"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="N72 Inflammatory disease of cervix uteri"
                                        checked={formik.values.icd_10_codes?.includes("N72 Inflammatory disease of cervix uteri")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label=" N95.2 Postmenopausal atrophic vaginitis "
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="N95.2 Postmenopausal atrophic vaginitis"
                                        checked={formik.values.icd_10_codes?.includes("N95.2 Postmenopausal atrophic vaginitis")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="R87.610 ASC-US"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="R87.610 ASC-US"
                                        checked={formik.values.icd_10_codes?.includes("R87.610 ASC-US")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="R87.612 LGSIL"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="R87.612 LGSIL"
                                        checked={formik.values.icd_10_codes?.includes("R87.612 LGSIL")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="R87.613 HGSIL"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="R87.613 HGSIL"
                                        checked={formik.values.icd_10_codes?.includes("R87.613 HGSIL")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label=" R87.618 Other abnormal GYN findings on "
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="R87.618 Other abnormal GYN findings on"
                                        checked={formik.values.icd_10_codes?.includes("R87.618 Other abnormal GYN findings on")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}> specimens from cervix uteri</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="R87.810 Cervical high risk human papillomavirus"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="R87.810 Cervical high risk human papillomavirus"
                                        checked={formik.values.icd_10_codes?.includes("R87.810 Cervical high risk human papillomavirus")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>(HPV) DNA test positive</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Checkbox disabled={true}
                                        label="Others"
                                        singleCheckbox checkboxStyle={{ padding: '5px 10px', }}
                                        placeholder="Others"
                                        onChange={(e) => {
                                            otherChange(e, "blank_box");

                                            handleOrderChange(formik, "icd_10_codes", e);
                                        }}
                                        value="Others"
                                        checked={formik.values.icd_10_codes?.includes("Others")}
                                    />
                                    {formik.values.icd_10_codes?.includes("Others") && (<Input disabled={true} onChange={(e) => {
                                        formik.setFieldValue("blank_box", e.target.value);
                                    }
                                    } value={formik.values.blank_box} />)}


                                </Grid>
                                <Grid item xs={12} marginTop={1}>
                                    <Divider />
                                </Grid>
                                <Grid container spacing={2} marginTop={0}>
                                    <Grid item xs={12}>
                                        <Typography fontWeight={'600'} fontSize={{ xs: '14px', sm: '16px' }}>CT/GC</Typography>
                                        <Box marginLeft={4} marginTop={1}>
                                            <Checkbox disabled={true}
                                                label="Apex Dx UTM Swab (Vaginal/Endocervical)"
                                                singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                                onChange={(e) => {
                                                    handleOrderChange(formik, "ct_gc", e);
                                                }}
                                                value="Apex Dx UTM Swab (Vaginal/Endocervical)"
                                                checked={formik.values.ct_gc?.includes("Apex Dx UTM Swab (Vaginal/Endocervical)")}
                                            />
                                        </Box>
                                        <Box marginLeft={4}>
                                            <Checkbox disabled={true}
                                                label="Urine"
                                                singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                                onChange={(e) => {
                                                    handleOrderChange(formik, "ct_gc", e);
                                                }}
                                                value="Urine"
                                                checked={formik.values.ct_gc?.includes("Urine")}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox disabled={true}
                                        label="Affirm TestBD (Vaginala/Endocervical)"
                                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                                        onChange={(e) => {
                                            handleOrderChange(formik, "affirm_testbd_Vaginala_endocervical", e);
                                        }}
                                        value="Affirm TestBD (Vaginala/Endocervical)"
                                        checked={formik.values.affirm_testbd_Vaginala_endocervical?.includes("Affirm TestBD (Vaginala/Endocervical)")}
                                    />
                                    <Box marginLeft={7}>
                                        <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>(Candida, Gardnerella vaginalis, Trichomonas vaginalis)</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>

    )
}

export default GynecologicalCytologyFormView