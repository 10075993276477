import React, { useState, useEffect } from 'react'
import { Box, Divider, FormControlLabel, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { Button, Checkbox, Input, Modal } from '../../../../Component/Commons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, FiFileText } from '../../../../Helper/Icons';
import { useStyles } from '../../../../Helper/Theme';
import UserDetails from '../../UserDetails';
import { Annotation } from 'react-mark-image';
import { getProfile } from "../../../../Service/profile.service";
import { podiatryIv } from "../../../../Helper/initialValues";
import { podiatryValidation } from "../../../../Helper/validation";
import { showNotification } from "../../../../Helper/Notification";
import { createPodiatryFormhandler } from "../../../../Service/podiatry.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import LeftFeet from '../../../../assets/image/left_feet.png'
import RightFeet from '../../../../assets/image/right_feet.png'
import { savePatientDoc } from "../../../../Service/commanForm.service";


const PodiatryReq = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [leftFeet, setLeftFeet] = useState([]);
  const [rightFeet, setRightFeet] = useState([]);
  const { user } = useSelector((state) => state?.root?.auth);
  const [referByUser, setReferByUser] = useState({});

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const validationHandler = () => {
    setIsFormSubmitted(true)
    if (Object.keys(formik.errors).length > 0) {
      showNotification("error", 'Required filed is missing')
    }
  }

  useEffect(() => {
    const filePath = LeftFeet;



    convertPathToFile(filePath);
  }, []);

  const convertPathToFile = (path) => {
    fetch(path)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], 'filename.png', { type: 'image/png' });
        formik.setFieldValue("left_leg_image", file);
      })
      .catch(error => console.error('Error converting path to File:', error));
  };

  useEffect(() => {
    const filePath = RightFeet;


    convertPathToFile1(filePath);
  }, []);


  const convertPathToFile1 = (path) => {
    fetch(path)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], 'filename.png', { type: 'image/png' });
        formik.setFieldValue("right_leg_image", file);
      })
      .catch(error => console.error('Error converting path to File:', error));
  };



  const submitFormHandler = async () => {

    const formData = new FormData();
    formData.append("patient_first_name", formik.values.patientInformation.patient_first_name);
    formData.append("patient_last_name", formik.values.patientInformation.patient_last_name);
    formData.append("initial", formik.values.patientInformation.initial);
    formData.append("patient_address", formik.values.patientInformation.patient_address);
    formData.append("patient_city", formik.values.patientInformation.patient_city);
    formData.append("patient_state", formik.values.patientInformation.patient_state);
    formData.append("patient_zip", formik.values.patientInformation.patient_zip);
    formData.append("patient_area_code", formik.values.patientInformation.patient_area_code);
    formData.append("patient_age", formik.values.patientInformation.patient_age);
    formData.append("patient_sex", formik.values.patientInformation.patient_sex);
    formData.append("patient_dob", formik.values.patientInformation.patient_dob);
    formData.append("patient_phone", formik.values.patientInformation.patient_phone);
    formData.append("patient_payment", formik.values.patientInformation.patient_payment);
    formData.append("bill_number", formik.values.patientInformation.bill_number);
    formData.append("bill_amount", formik.values.patientInformation.bill_amount);
    formData.append("insurance_company", formik.values.patientInformation.insurance_company);
    formData.append("insurance_no", formik.values.patientInformation.insurance_no);
    formData.append("pay_amount", formik.values.patientInformation.pay_amount);
    formData.append("icd_10", formik.values.patientInformation.icd_10);
    formData.append("clinical_history", formik.values.patientInformation.clinical_history);
    formData.append("additional_physician", formik.values.patientInformation.additional_physician);
    formData.append("other_test", formik.values.patientInformation.other_test);
    formData.append("right_nail_biopsy", JSON.stringify(formik.values.right_nail_biopsy));
    formData.append("right_skin_soft_tissue_bone_biopsy", JSON.stringify(formik.values.right_skin_soft_tissue_bone_biopsy));
    formData.append("right_leg_image", formik.values.right_leg_image);
    formData.append("left_leg_image", formik.values.left_leg_image);
    formData.append("right_aspirate_joint_fluid", JSON.stringify(formik.values.right_aspirate_joint_fluid));
    formData.append("right_aspirate_cyst_or_other_fluid", JSON.stringify(formik.values.right_aspirate_cyst_or_other_fluid));
    formData.append("right_microbiology_only", JSON.stringify(formik.values.right_microbiology_only));
    formData.append("right_additional_test_notes", formik.values.right_additional_test_notes);
    formData.append("left_nail_biopsy", JSON.stringify(formik.values.left_nail_biopsy));
    formData.append("left_skin_soft_tissue_bone_biopsy", JSON.stringify(formik.values.left_skin_soft_tissue_bone_biopsy));
    formData.append("left_aspirate_joint_fluid", JSON.stringify(formik.values.left_aspirate_joint_fluid));
    formData.append("left_aspirate_cyst_or_other_fluid", JSON.stringify(formik.values.left_aspirate_cyst_or_other_fluid));
    formData.append("left_microbiology_only", JSON.stringify(formik.values.left_microbiology_only));
    formData.append("left_additional_test_notes", formik.values.left_additional_test_notes);
    formData.append("left_leg_specymen", JSON.stringify(formik.values.left_leg_specymen));
    formData.append("right_leg_specymen", JSON.stringify(formik.values.right_leg_specymen));
    formData.append("left_leg_codinet", JSON.stringify(formik.values.left_leg_codinet));
    formData.append("right_leg_codinet", JSON.stringify(formik.values.right_leg_codinet));


    const { data, message, status } = await createPodiatryFormhandler(formData);
    if (status) {
      if (formik.values.patientInformation.pationtDoc && formik.values.patientInformation.pationtDoc.length > 0) {
        savePatientDocHandler(data?.patient_information?.id)
      } else {

        showNotification("success", message || 'Form Register Successfully');
        if (user.group_name === "provider") {
          navigate(`/submitted-forms`)
        } else {
          navigate(`/admin/submitted-forms`);
        }
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const formik = useFormik({
    initialValues: podiatryIv,
    validationSchema: podiatryValidation,
    onSubmit: submitFormHandler,
  });

  const savePatientDocHandler = async (id) => {
    const formData = new FormData();
    formData.append("patient_document", formik.values.patientInformation.pationtDoc[0]);
    const { data, message, status } = await savePatientDoc(id, formData);
    if (status) {
      showNotification("success", message || 'Form Register Successfully');
      if (user.group_name === "provider") {
        navigate(`/submitted-forms`)
      } else {
        navigate(`/admin/submitted-forms`);
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const rightNailBiopsy = [
    { label: "Nail Panel by PCR (nailbag)", value: "Nail Panel by PCR (nailbag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
    { label: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", value: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
    { label: "Histology with stains: PAS (formalin or nail bag)", value: "Histology with stains: PAS (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
    { label: "Fungal culture (nail bag)", value: "Fungal culture (nail bag)", onChange: (e) => handleOrderChange(formik, "right_nail_biopsy", e) },
  ]

  const leftNailBiopsy = [
    { label: "Nail Panel by PCR (nailbag)", value: "Nail Panel by PCR (nailbag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
    { label: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", value: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
    { label: "Histology with stains: PAS (formalin or nail bag)", value: "Histology with stains: PAS (formalin or nail bag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
    { label: "Fungal culture (nail bag)", value: "Fungal culture (nail bag)", onChange: (e) => handleOrderChange(formik, "left_nail_biopsy", e) },
  ]

  const rightBoneBiopsy = [
    { label: "Histology (formalin)", value: "Histology (formalin)", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
    { label: "Histology with gout analysis (100% Alcohol)", value: "Histology with gout analysis (100% Alcohol)", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
    { label: "Culture (swab)", value: "Culture (swab)", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
    { label: "Histology with stains: PAS, GAS, Fontana-Masson", value: "Histology with stains: PAS, GAS, Fontana-Masson", onChange: (e) => handleOrderChange(formik, "right_skin_soft_tissue_bone_biopsy", e) },
  ]

  const rightAspirateJointFluid = [
    { label: "Crystal analysis (lavender top tube)", value: "Crystal analysis (lavender top tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_joint_fluid", e) },
    { label: "Cell count (lavender top tube)", value: "Cell count (lavender top tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_joint_fluid", e) },
    { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_joint_fluid", e) },
  ]

  const leftAspirateJointFluid = [
    { label: "Crystal analysis (lavender top tube)", value: "Crystal analysis (lavender top tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_joint_fluid", e) },
    { label: "Cell count (lavender top tube)", value: "Cell count (lavender top tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_joint_fluid", e) },
    { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_joint_fluid", e) },
  ]



  const leftBoneBiopsy = [
    { label: "Histology (formalin)", value: "Histology (formalin)", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
    { label: "Histology with gout analysis (100% Alcohol)", value: "Histology with gout analysis (100% Alcohol)", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
    { label: "Culture (swab)", value: "Culture (swab)", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
    { label: "Histology with stains: PAS, GAS, Fontana-Masson", value: "Histology with stains: PAS, GAS, Fontana-Masson", onChange: (e) => handleOrderChange(formik, "left_skin_soft_tissue_bone_biopsy", e) },
  ]

  const rightAspirateOtherFluid = [
    { label: "Cytology only (cytolyt)", value: "Cytology only (cytolyt)", onChange: (e) => handleOrderChange(formik, "right_aspirate_cyst_or_other_fluid", e) },
    { label: "Crystal analysis for gout (lavender top tube)", value: "Crystal analysis for gout (lavender top tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_cyst_or_other_fluid", e) },
    { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "right_aspirate_cyst_or_other_fluid", e) },
  ]

  const leftAspirateOtherFluid = [
    { label: "Cytology only (cytolyt)", value: "Cytology only (cytolyt)", onChange: (e) => handleOrderChange(formik, "left_aspirate_cyst_or_other_fluid", e) },
    { label: "Crystal analysis for gout (lavender top tube)", value: "Crystal analysis for gout (lavender top tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_cyst_or_other_fluid", e) },
    { label: "Culture (culture tube)", value: "Culture (culture tube)", onChange: (e) => handleOrderChange(formik, "left_aspirate_cyst_or_other_fluid", e) },
  ]

  const rightMicrobiology = [
    { label: "Culture and sensitivity only (swab)", value: "Culture and sensitivity only (swab)", onChange: (e) => handleOrderChange(formik, "right_microbiology_only", e) },
    { label: "Aerobic only (for skin or superficial wound)", value: "Aerobic only (for skin or superficial wound)", onChange: (e) => handleOrderChange(formik, "right_microbiology_only", e) },
    { label: "Aerobic and Anaerobic (for deep wound or abscess)", value: "Aerobic and Anaerobic (for deep wound or abscess)", onChange: (e) => handleOrderChange(formik, "right_microbiology_only", e) },
    { label: "Fungal (Swab)", value: "Fungal (Swab)", onChange: (e) => handleOrderChange(formik, "right_microbiology_only", e) },
  ]

  const leftMicrobiology = [
    { label: "Culture and sensitivity only (swab)", value: "Culture and sensitivity only (swab)", onChange: (e) => handleOrderChange(formik, "left_microbiology_only", e) },
    { label: "Aerobic only (for skin or superficial wound)", value: "Aerobic only (for skin or superficial wound)", onChange: (e) => handleOrderChange(formik, "left_microbiology_only", e) },
    { label: "Aerobic and Anaerobic (for deep wound or abscess)", value: "Aerobic and Anaerobic (for deep wound or abscess)", onChange: (e) => handleOrderChange(formik, "left_microbiology_only", e) },
    { label: "Fungal (Swab)", value: "Fungal (Swab)", onChange: (e) => handleOrderChange(formik, "left_microbiology_only", e) },
  ]


  useEffect(() => {
    referredByDataHandler();
  }, []);

  const referredByDataHandler = async () => {
    const { data, message, status } = await getProfile();
    if (status) {
      setReferByUser(data);
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data.facility_name,
          doctor: data.full_name,
          address: data.address,
          phone: data.phone,
          fax: data.fax,
        },
      });
    }
  };

  const handleOrderChange = (formik, orderFieldName, e) => {
    const currentValue = formik.values[orderFieldName];

    if (e.target.checked) {
      formik.setFieldValue(orderFieldName, [...currentValue, e.target.value]);
    } else {
      formik.setFieldValue(orderFieldName, currentValue.filter(item => item !== e.target.value));
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
        <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: 'pointer', background: '#8cc63f', color: '#fff' }} />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        } />
      <Box className={classes.cardContainer} padding={{ xs: '12px 10px', sm: 3 }}>
        <UserDetails formik={formik} isFormSubmitted={isFormSubmitted} />

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>Right</Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Stack flexDirection={{ sm: 'row' }} alignItems={{ sm: 'flex-end' }} gap={2} className='markeble_image'>
                <Annotation
                  src={RightFeet}
                  alt="feet_image"
                  annotations={formik.values.right_leg_codinet}
                  onAnnotationsUpdate={(updatedAnnotations) => formik.setFieldValue("right_leg_codinet", updatedAnnotations)}
                  allowTouch
                  editorMode='AnnotateOnly'
                />
                <Input variant='standard' placeholder='Specimen Source' name="right_leg_specymen" value={formik.values.right_leg_specymen} onChange={formik.handleChange} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={rightNailBiopsy} row labelInput='Nail, Biopsy'
                checked={formik.values.right_nail_biopsy}

              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={rightBoneBiopsy} row labelInput='Skin, Soft Tissue, Bone Biopsy'
                checked={formik.values.right_skin_soft_tissue_bone_biopsy}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={rightAspirateJointFluid} row labelInput='Aspirate (Joint Fluid)'
                checked={formik.values.right_aspirate_joint_fluid}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={rightAspirateOtherFluid} row labelInput='Aspirate (Cyst Or Other Fluid)'
                checked={formik.values.right_aspirate_cyst_or_other_fluid}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={rightMicrobiology} row labelInput='Microbiology Only'
                checked={formik.values.right_microbiology_only}
              />
            </Grid>
            <Grid item xs={12}>
              <Input labelInput='Additional Test / Notes'
                name="right_additional_test_notes"
                value={formik.values.right_additional_test_notes}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </Box>

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Left</Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Stack flexDirection={{ sm: 'row' }} alignItems={{ sm: 'flex-end' }} gap={2} className='markeble_image'>
                <Annotation
                  src={LeftFeet}
                  alt="feet_image"
                  annotations={formik.values.left_leg_codinet}
                  onAnnotationsUpdate={(updatedAnnotations) => formik.setFieldValue("left_leg_codinet", updatedAnnotations)}
                  allowTouch
                  editorMode='AnnotateOnly'
                />
                <Input variant='standard' placeholder='Specimen Source' name="left_leg_specymen" value={formik.values.left_leg_specymen} onChange={formik.handleChange} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={leftNailBiopsy} row labelInput='Nail, Biopsy'
                checked={formik.values.left_nail_biopsy}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={leftBoneBiopsy} row labelInput='Skin, Soft Tissue, Bone Biopsy'
                checked={formik.values.left_skin_soft_tissue_bone_biopsy}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={leftAspirateJointFluid} row labelInput='Aspirate (Joint Fluid)'
                checked={formik.values.left_aspirate_joint_fluid}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={leftAspirateOtherFluid} row labelInput='Aspirate (Cyst Or Other Fluid)'
                checked={formik.values.left_aspirate_cyst_or_other_fluid}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={leftMicrobiology} row labelInput='Microbiology Only'
                checked={formik.values.left_microbiology_only}
              />
            </Grid>
            <Grid item xs={12}>
              <Input labelInput='Additional Test / Notes' name="left_additional_test_notes" value={formik.values.left_additional_test_notes} onChange={formik.handleChange} />
            </Grid>
          </Grid>
        </Box>
        {
          location.pathname === '/admin/submitted-forms/view' ? '' :
            <>
              <Divider sx={{ marginTop: '20px !important', marginBottom: '16px !important' }} />
              <Stack gap={'10px'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Button buttonName='Cancel' onClick={() => navigate(-1)} />
                <Button buttonName='Submit' onClick={() =>
                  [formik.handleSubmit(), validationHandler()]
                } />
              </Stack>
            </>
        }
      </Box>
    </>

  )
}

export default PodiatryReq