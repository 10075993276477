import React, { useState, useEffect } from 'react'
import { Box, Divider, FormControlLabel, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { Button, Checkbox, Input, Modal } from '../../../../Component/Commons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, FiFileText } from '../../../../Helper/Icons';
import { useStyles } from '../../../../Helper/Theme';
import UserDetails from '../../UserDetails';
import { getProfile } from "../../../../Service/profile.service";
import { useFormik } from "formik";
import { gynIv } from "../../../../Helper/initialValues";
import { gynValidation } from "../../../../Helper/validation";
import { showNotification } from "../../../../Helper/Notification";
import { createGynecologyFormhandler } from "../../../../Service/gynecology.service";
import { useSelector } from "react-redux";
import { savePatientDoc } from "../../../../Service/commanForm.service";


const GynecologicalCytology = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [referByUser, setReferByUser] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { user } = useSelector((state) => state?.root?.auth);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });


  useEffect(() => {
    referredByDataHandler();
  }, []);

  const validationHandler = () => {
    setIsFormSubmitted(true)
    if (Object.keys(formik.errors).length > 0) {
      showNotification("error", 'Required filed is missing')
    }
  }

  const submitFormHandler = async () => {
    const payload = {
      "patient_information": {
        "patient_first_name": formik.values.patientInformation.patient_first_name,
        "patient_last_name": formik.values.patientInformation.patient_last_name,
        "initial": formik.values.patientInformation.initial,
        "patient_address": formik.values.patientInformation.patient_address,
        "patient_city": formik.values.patientInformation.patient_city,
        "patient_state": formik.values.patientInformation.patient_state,
        "patient_zip": formik.values.patientInformation.patient_zip,
        "patient_area_code": formik.values.patientInformation.patient_area_code,
        "patient_age": formik.values.patientInformation.patient_age,
        "patient_sex": formik.values.patientInformation.patient_sex,
        "patient_dob": formik.values.patientInformation.patient_dob,
        "patient_phone": formik.values.patientInformation.patient_phone,
        "patient_payment": formik.values.patientInformation.patient_payment,
        "bill_number": formik.values.patientInformation.bill_number,
        "bill_amount": formik.values.patientInformation.bill_amount,
        "insurance_company": formik.values.patientInformation.insurance_company,
        "insurance_no": formik.values.patientInformation.insurance_no,
        "pay_amount": formik.values.patientInformation.pay_amount,
        "icd_10": formik.values.patientInformation.icd_10,
        "clinical_history": formik.values.patientInformation.clinical_history,
        "additional_physician": formik.values.patientInformation.additional_physician,
        "other_test": formik.values.patientInformation.other_test
      },
      "gynecological_cytology_requisition_information": {
        "submit_in_formalin": [
          formik.values.submit_in_formalin1,
          formik.values.submit_in_formalin2,
          formik.values.submit_in_formalin3,
          formik.values.submit_in_formalin4,
          formik.values.submit_in_formalin5
        ],
        "lmp": formik.values.lmp,
        "diagnosis": formik.values.diagnosis,
        "icd_10_code": formik.values.icd_10_code,
        "specimen_source": formik.values.specimen_source,
        "previous_pap_dx": formik.values.previous_pap_dx,
        "clinical_Impression": formik.values.clinical_Impression,
        "hormonal_therapy": formik.values.hormonal_therapy,
        "hormonal_therapy_other": formik.values.hormonal_therapy_other,
        "thinprep_pap_smear": formik.values.thinprep_pap_smear,
        "thinprep_with_reflex_on_ascus": formik.values.thinprep_with_reflex_on_ascus,
        "thinprep_with_reflex_on_ascus_or_higher": formik.values.thinprep_with_reflex_on_ascus_or_higher,
        "bacterial_vaginosis": formik.values.bacterial_vaginosis,
        "candida": formik.values.candida,
        "mycoplasma": formik.values.mycoplasma,
        "hsv_1_2": formik.values.hsv_1_2,
        "trichomonas": formik.values.trichomonas,
        "gbs": formik.values.gbs,
        "icd_10_codes": formik.values.icd_10_codes,
        "blank_box": formik.values.blank_box,
        "ct_gc": formik.values.ct_gc,
        "affirm_testbd_Vaginala_endocervical": formik.values.affirm_testbd_Vaginala_endocervical,
      }
    }
    const { data, message, status } = await createGynecologyFormhandler(payload);
    if (status) {
      if (formik.values.patientInformation.pationtDoc && formik.values.patientInformation.pationtDoc.length > 0) {
        savePatientDocHandler(data?.patient_information?.id)
      } else {

        showNotification("success", message || 'Form Register Successfully');
        if (user.group_name === "provider") {
          navigate(`/submitted-forms`)
        } else {
          navigate(`/admin/submitted-forms`);
        }
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };
  



  const formik = useFormik({
    initialValues: gynIv,
    validationSchema: gynValidation,
    onSubmit: submitFormHandler,
  });

  const savePatientDocHandler = async (id) => {
    const formData = new FormData();
    formData.append("patient_document", formik.values.patientInformation.pationtDoc[0]);
    const { data, message, status } = await savePatientDoc(id,formData);
    if (status) {
      showNotification("success", message || 'Form Register Successfully');
      if (user.group_name === "provider") {
        navigate(`/submitted-forms`)
      } else {
        navigate(`/admin/submitted-forms`);
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const handleOrderChange = (formik, orderFieldName, e) => {
    const currentValue = formik.values[orderFieldName];
  
    if (e.target.checked) {
      formik.setFieldValue(orderFieldName, [...currentValue, e.target.value]);
    } else {
      formik.setFieldValue(orderFieldName, currentValue.filter(item => item !== e.target.value));
    }
  };

  const specimenList = [
    { label: "Vaginal", value: "Vaginal", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
    { label: "Cervical", value: "Cervical", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
    { label: "Endocervical", value: "Endocervical", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
    { label: "Conventional Pap", value: "Conventional Pap", onChange: (e) => handleOrderChange(formik, "specimen_source", e) },
]

const gynSpecimen = [
  { label: "Hormonal Therapy", value: "Hormonal Therapy", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Quo", value: "Quo" , onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e)},
  { label: "DES Exposure", value: "DES Exposure" , onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e)},
  { label: "Chemotherapy", value: "Chemotherapy" , onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e)},
  { label: "Radiation Therapy", value: "Radiation Therapy" , onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e)},
  { label: "Birth Control", value: "Birth Control", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Postpartum", value: "Postpartum", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Hysterectomy", value: "Hysterectomy", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Supracervical", value: "Supracervical", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Pregnant", value: "Pregnant", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Postmenopausal", value: "Postmenopausal", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "GYN Surgeries", value: "GYN Surgeries", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Hx of CA", value: "Hx of CA", onChange: (e) => handleOrderChange(formik, "hormonal_therapy", e) },
  { label: "Other History", value: "Other History", onChange: (e) => [handleOrderChange(formik, "hormonal_therapy", e),otherChange(e,"hormonal_therapy_other")] }
]

const otherChange = (e,name) => {
  formik.setFieldValue(name, "");
}


  const referredByDataHandler = async () => {
    const { data, message, status } = await getProfile();
    if (status) {
      setReferByUser(data);
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data.facility_name,
          doctor: data.full_name,
          address: data.address,
          phone: data.phone,
          fax: data.fax,
        },
      });
    }
  };



  // Herererere

  return (
    <>
      <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
        <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: 'pointer', background: '#8cc63f', color: '#fff' }} />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        } />
      <Box className={classes.cardContainer} padding={{ xs: '12px 10px', sm: 3 }}>
        <UserDetails formik={formik} isFormSubmitted={isFormSubmitted} />

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>Pathology Specimens</Typography>
        <Divider />
        <Box marginTop={2}>
          <Typography fontWeight={'600'}>Submit in Formalin</Typography>
          <Stack flexDirection={{ xs: 'column', sm: 'row' }} gap={2}>
            <Box width={'100%'}>
              <Input labelInput='1' 
              onChange={(e) => {
                formik.setFieldValue("submit_in_formalin1", e.target.value);
              }}  
              value={formik.values.submit_in_formalin1} />

            </Box>
            <Box width={'100%'}>
              <Input labelInput='2' 
               onChange={(e) => {
                formik.setFieldValue("submit_in_formalin2", e.target.value);
              }}  
              value={formik.values.submit_in_formalin2} />
            </Box>
            <Box width={'100%'}>
              <Input labelInput='3' 
               onChange={(e) => {
                formik.setFieldValue("submit_in_formalin3", e.target.value);
              }}  
              value={formik.values.submit_in_formalin3} />
            </Box>
            <Box width={'100%'}>
              <Input labelInput='4' 
               onChange={(e) => {
                formik.setFieldValue("submit_in_formalin4", e.target.value);
              }}  
              value={formik.values.submit_in_formalin4} />
            </Box>
            <Box width={'100%'}>
              <Input labelInput='5' 
               onChange={(e) => {
                formik.setFieldValue("submit_in_formalin5", e.target.value);
              }}  
              value={formik.values.submit_in_formalin5} />
            </Box>
          </Stack>
        </Box>

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Gyn Cytology Specimens</Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input labelInput='LMP' type='date' 
                onChange={(e) => {
                  formik.setFieldValue("lmp", e.target.value);
                }}
                value={formik.values.lmp}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input labelInput='Diagnosis' 
                onChange={(e) => {
                  formik.setFieldValue("diagnosis", e.target.value);
                }}
                value={formik.values.diagnosis}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input labelInput='ICD-10 Code' 
               onChange={(e) => {
                formik.setFieldValue("icd_10_code", e.target.value);
              }}
              value={formik.values.icd_10_code}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={'600'} fontSize={{ xs: '12px', sm: '14px' }}>For Medicare Patient With No Diagnosis, Please Attach Abn.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Checkbox labelInput='Specimen Source' checkboxList={specimenList} row 
              checked={formik.values.specimen_source}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input labelInput='Previous Pap Dx:' 
               onChange={(e) => {
                formik.setFieldValue("previous_pap_dx", e.target.value);
              }}
              value={formik.values.previous_pap_dx}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input labelInput='Clinical Impression / Pertinent History'
               onChange={(e) => {
                formik.setFieldValue("clinical_Impression", e.target.value);
              }}
              value={formik.values.clinical_Impression}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox checkboxList={gynSpecimen} row 
              checked={formik.values.hormonal_therapy}
              />
            </Grid>
            <Grid item xs={12}>
              {formik.values.hormonal_therapy?.includes("Other History") && (
                 <Input variant='standard'
                 placeholder='Other History'
                  name='hormonal_therapy_other' onChange={formik.handleChange} value={formik.values.hormonal_therapy_other} />
              )}
           
            </Grid>
          </Grid>
        </Box>

        <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }} marginTop={3}>Physician's Orders</Typography>
        <Divider />
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight={'700'} fontSize={{ xs: '12px', sm: '14px' }}>Note:  “HPV” Includes High Risk HPV w/ 16/18 subtyping if positive</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        ThinPrep Pap smear
                      </Typography>
                    }
                    value="ThinPrep Pap smear"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "thinprep_pap_smear", e);
                    }} 
                    checked={formik.values.thinprep_pap_smear?.includes("ThinPrep Pap smear")}
                  />
                  <Box marginLeft={4} marginTop={1}>
                    <Checkbox
                      label="HPV"
                      singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                      onChange={(e) => {
                        handleOrderChange(formik, "thinprep_pap_smear", e);
                      }} 
                      value="HPV"
                      checked={formik.values.thinprep_pap_smear?.includes("HPV")}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Chlamydia by PCR"
                      singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                      onChange={(e) => {
                        handleOrderChange(formik, "thinprep_pap_smear", e);
                      }}
                      value="Chlamydia by PCR"
                      checked={formik.values.thinprep_pap_smear?.includes("Chlamydia by PCR")}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Gonorrhea by PCR"
                      singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                      onChange={(e) => {
                        handleOrderChange(formik, "thinprep_pap_smear", e);
                      }}
                      value="Gonorrhea by PCR"
                      checked={formik.values.thinprep_pap_smear?.includes("Gonorrhea by PCR")}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginY={2}>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        ThinPrep with reflex on ASCUS
                      </Typography>
                    }
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "thinprep_with_reflex_on_ascus", e);

                    }}
                    value="ThinPrep with reflex on ASCUS"
                    checked={formik.values.thinprep_with_reflex_on_ascus?.includes("ThinPrep with reflex on ASCUS")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        ThinPrep with reflex on ASCUS or higher
                      </Typography>
                    }
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "thinprep_with_reflex_on_ascus_or_higher", e);
                    }}
                    value="ThinPrep with reflex on ASCUS or higher"
                    checked={formik.values.thinprep_with_reflex_on_ascus_or_higher?.includes("ThinPrep with reflex on ASCUS or higher")}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={1} marginTop={0}>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        Bacterial vaginosis (Apex Dx UTM Swab)
                      </Typography>
                    }
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "bacterial_vaginosis", e);
                    }}
                    value="Bacterial vaginosis (Apex Dx UTM Swab)"
                    checked={formik.values.bacterial_vaginosis?.includes("Bacterial vaginosis (Apex Dx UTM Swab)")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        Candida (Apex Dx UTM Swab)
                      </Typography>
                    }
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "candida", e);
                    }}
                    value="Candida (Apex Dx UTM Swab)"
                    checked={formik.values.candida?.includes("Candida (Apex Dx UTM Swab)")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        Mycoplasma/Ureaplasma (Apex Dx UTM Swab)
                      </Typography>
                    }
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "mycoplasma", e);
                    }}
                    value="Mycoplasma/Ureaplasma (Apex Dx UTM Swab)"
                    checked={formik.values.mycoplasma?.includes("Mycoplasma/Ureaplasma (Apex Dx UTM Swab)")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        HSV 1&2 (Apex Dx UTM Swab)
                      </Typography>
                    }
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "hsv_1_2", e);
                    }}
                    value="HSV 1&2 (Apex Dx UTM Swab)"
                    checked={formik.values.hsv_1_2?.includes("HSV 1&2 (Apex Dx UTM Swab)")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12}>
                  <Typography fontWeight={'600'} fontSize={{ xs: '14px', sm: '16px' }}> Trichomonas </Typography>
                  <Box marginLeft={4} marginTop={1}>
                    <Checkbox
                      label="Apex Dx UTM Swab (Vaginal/Endocervical)"
                      singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                      onChange={(e) => {
                        handleOrderChange(formik, "trichomonas", e);
                      }}
                      value="Apex Dx UTM Swab (Vaginal/Endocervical)"
                      checked={formik.values.trichomonas?.includes("Apex Dx UTM Swab (Vaginal/Endocervical)")}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="Urine"
                      singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                      onChange={(e) => {
                        handleOrderChange(formik, "trichomonas", e);
                      }}
                      value="Urine"
                      checked={formik.values.trichomonas?.includes("Urine")}
                    />
                  </Box>
                  <Box marginLeft={4}>
                    <Checkbox
                      label="ThinPrep"
                      singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                      onChange={(e) => {
                        handleOrderChange(formik, "trichomonas", e);
                      }}
                      value="ThinPrep"
                      checked={formik.values.trichomonas?.includes("ThinPrep")}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} marginTop={0}>
                <Grid item xs={12}>
                  <Checkbox
                    label={
                      <Typography style={{ fontWeight: '600', lineHeight: '1.2' }} fontSize={{ xs: '12px', sm: '14px' }}>
                        GBS w/ reflex to susceptibility if positive (rectal/vaginal)
                      </Typography>
                    }
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "gbs", e);
                    }}
                    value="GBS w/ reflex to susceptibility if positive (rectal/vaginal)"
                    checked={formik.values.gbs?.includes("GBS w/ reflex to susceptibility if positive (rectal/vaginal)")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography fontWeight={'700'} fontSize={{ xs: '15px', sm: '18px' }}>ICD-10 Codes</Typography>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <Typography fontWeight={'600'} fontSize={{ xs: '12px', sm: '14px' }}>For your convenience, a partial list of common diagnoses follows. For a complete listing of all ICD-10 codes, refer to a current version of the ICD-10 book.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label=" Z01.411 Encounter for GYN exam (general)"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="Z01.411 Encounter for GYN exam (general)"
                    checked={formik.values.icd_10_codes?.includes("Z01.411 Encounter for GYN exam (general)")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>(routine) w/abnormal findings</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Z01.419 Encounter for GYN exam (general)"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="Z01.419 Encounter for GYN exam (general)"
                    checked={formik.values.icd_10_codes?.includes("Z01.419 Encounter for GYN exam (general)")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}> (routine) w/o abnormal findings</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Z12.4 Encounter for screening for malignant "
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="Z12.4 Encounter for screening for malignant"
                    checked={formik.values.icd_10_codes?.includes("Z12.4 Encounter for screening for malignant")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}> neoplasm of cervix</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Z12.82 Encounter for screening for malignant"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="Z12.82 Encounter for screening for malignant"
                    checked={formik.values.icd_10_codes?.includes("Z12.82 Encounter for screening for malignant")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>neoplasm of nervous system</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label=" Z86.19 Personal history of other infectious and"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="Z86.19 Personal history of other infectious and"
                    checked={formik.values.icd_10_codes?.includes("Z86.19 Personal history of other infectious and")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>parasitic diseases.</Typography>
                  </Box>
                </Grid>
                
                <Grid item xs={12}>
                  <Checkbox
                    label="N72 Inflammatory disease of cervix uteri"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="N72 Inflammatory disease of cervix uteri"
                    checked={formik.values.icd_10_codes?.includes("N72 Inflammatory disease of cervix uteri")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label=" N95.2 Postmenopausal atrophic vaginitis "
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="N95.2 Postmenopausal atrophic vaginitis"
                    checked={formik.values.icd_10_codes?.includes("N95.2 Postmenopausal atrophic vaginitis")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="R87.610 ASC-US"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="R87.610 ASC-US"
                    checked={formik.values.icd_10_codes?.includes("R87.610 ASC-US")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="R87.612 LGSIL"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="R87.612 LGSIL"
                    checked={formik.values.icd_10_codes?.includes("R87.612 LGSIL")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="R87.613 HGSIL"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="R87.613 HGSIL"
                    checked={formik.values.icd_10_codes?.includes("R87.613 HGSIL")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label=" R87.618 Other abnormal GYN findings on "
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="R87.618 Other abnormal GYN findings on"
                    checked={formik.values.icd_10_codes?.includes("R87.618 Other abnormal GYN findings on")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}> specimens from cervix uteri</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="R87.810 Cervical high risk human papillomavirus"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="R87.810 Cervical high risk human papillomavirus"
                    checked={formik.values.icd_10_codes?.includes("R87.810 Cervical high risk human papillomavirus")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>(HPV) DNA test positive</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Checkbox
                    label="Others"
                    singleCheckbox checkboxStyle={{ padding: '5px 10px', }}
                    placeholder="Others"
                    onChange={(e) => {
                      otherChange(e,"blank_box");

                      handleOrderChange(formik, "icd_10_codes", e);
                    }}
                    value="Others"
                    checked={formik.values.icd_10_codes?.includes("Others")}
                  />
                  {formik.values.icd_10_codes?.includes("Others") && (<Input onChange={(e) => {
                    formik.setFieldValue("blank_box", e.target.value);
                  }
                  } value={formik.values.blank_box} />)}
                  

                </Grid>
                <Grid item xs={12} marginTop={1}>
                  <Divider />
                </Grid>
                <Grid container spacing={2} marginTop={0}>
                  <Grid item xs={12}>
                    <Typography fontWeight={'600'} fontSize={{ xs: '14px', sm: '16px' }}>CT/GC</Typography>
                    <Box marginLeft={4} marginTop={1}>
                      <Checkbox
                        label="Apex Dx UTM Swab (Vaginal/Endocervical)"
                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                        onChange={(e) => {
                          handleOrderChange(formik, "ct_gc", e);
                        }}
                        value="Apex Dx UTM Swab (Vaginal/Endocervical)"
                        checked={formik.values.ct_gc?.includes("Apex Dx UTM Swab (Vaginal/Endocervical)")}
                      />
                    </Box>
                    <Box marginLeft={4}>
                      <Checkbox
                        label="Urine"
                        singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                        onChange={(e) => {
                          handleOrderChange(formik, "ct_gc", e);
                        }}
                        value="Urine"
                        checked={formik.values.ct_gc?.includes("Urine")}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label="Affirm TestBD (Vaginala/Endocervical)"
                    singleCheckbox checkboxStyle={{ padding: '0 10px' }}
                    onChange={(e) => {
                      handleOrderChange(formik, "affirm_testbd_Vaginala_endocervical", e);
                    }}
                    value="Affirm TestBD (Vaginala/Endocervical)"
                    checked={formik.values.affirm_testbd_Vaginala_endocervical?.includes("Affirm TestBD (Vaginala/Endocervical)")}
                  />
                  <Box marginLeft={7}>
                    <Typography fontWeight={'300'} fontSize={{ xs: '12px', sm: '14px' }}>(Candida, Gardnerella vaginalis, Trichomonas vaginalis)</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {
          location.pathname === '/admin/submitted-forms/view' ? '' :
            <>
              <Divider sx={{ marginTop: '20px !important', marginBottom: '16px !important' }} />
              <Stack gap={'10px'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Button buttonName='Cancel' onClick={() => navigate(-1)} />
                <Button buttonName='Submit' onClick={() => [formik.handleSubmit(), validationHandler()]} />
              </Stack>
            </>
        }
      </Box>
    </>

  )
}

export default GynecologicalCytology