import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Sidebar from './Sidebar';
import Header from './Header';
import { Divider, Grid } from '@mui/material';

export default function UserLayout({ children }) {
    const { window } = Window;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [drawerWidth, setDrawerWidth] = React.useState(240);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{
            display: 'flex', height: '100vh',
            width: '100vw'
        }}>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, height: '100vh', flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        '& .MuiDrawer-paper': { backgroundColor: '#fff' },
                    }}
                >
                    <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        '& .MuiDrawer-paper': { backgroundColor: '#fff' },
                    }}
                >
                    <Sidebar handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'transparent', height: '100vh', height: '100%', overflow: 'auto' }}
            >
                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', p: '10px 16px', position: 'fixed', width: '-webkit-fill-available', zIndex: '999', background: '#fff', borderBottom: '1px solid #e3e3e3' }}>
                    <Header handleDrawerToggle={handleDrawerToggle} />
                </Grid>

                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { md: `calc(100%)` }, p: 2, marginTop: '65px' }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
