import { ThemeProvider } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { theme } from './Helper/Theme';
import ScrollToTop from './Helper/ScrollToTop';
import Login from './Pages/login';
import Signup from './Pages/signup';
import ForgotPassword from './Pages/forgotpassword';
import Layout from './Component/Layouts/Layout';
import { AdminDashboard, AdminCreateTest, AdminSubmittedForm, AdminMolecular, AdminProfile, UserManagements, } from './Views/Admin';
import { UserMolecular, UserCreateTest, UserDashboard, UserProfile, UserSubmittedForms } from './Views/User';
import UserLayout from './Component/UserLayout/UserLayout';
import SurgicalPathology from './Pages/createtest/forms/surgical-pathology/SurgicalPathology';
import Cytopathology from './Pages/createtest/forms/cytopathology/Cytopathology';
import GynecologicalCytology from './Pages/createtest/forms/gynecological-cytology/GynecologicalCytology';
import './App.css';
import { Hematopathology } from './Pages/createtest/forms/hematopathology';
import { PodiatryReq } from './Pages/createtest/forms/podiatryreq';
import { ProstateReq } from './Pages/createtest/forms/prostatereq';
import { ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { loadUser } from "./Service/auth.service";
import { loginUserApi, loding } from "./state/action/authAction"
import PrivateRoute from "./Routes/PrivateRoute"


const adminPages = [
  { name: 'Dashboard', path: '', element: <AdminDashboard /> },
  { name: 'MyProfile', path: 'profile', element: <AdminProfile /> },
  { name: 'UserManagement', path: 'user-management', element: <UserManagements /> },
  { name: 'SubmittedForm', path: 'submitted-forms', element: <AdminSubmittedForm /> },
  { name: 'SubmittedForm', path: 'submitted-forms/view', element: <UserMolecular /> },
  { name: 'CreateTest', path: 'create-test', element: <AdminCreateTest /> },
  { name: 'CreateForm', path: 'create-test/surgical-pathology-requisition-form', element: <SurgicalPathology /> },
  { name: 'CreateForm', path: 'create-test/cytopathology-requisition-form', element: <Cytopathology /> },
  { name: 'CreateForm', path: 'create-test/gynecological-cytology-requisition-form', element: <GynecologicalCytology /> },
  { name: 'CreateForm', path: 'create-test/molecular-requisition-form', element: <AdminMolecular /> },
  { name: 'CreateForm', path: 'create-test/hematopathology-requisition-form', element: <Hematopathology /> },
  { name: 'CreateForm', path: 'create-test/podiatry-requisition-form', element: <PodiatryReq /> },
  { name: 'CreateForm', path: 'create-test/prostater-requisition-form', element: <ProstateReq /> },
]
const userPages = [
  { name: 'Dashboard', path: '/dashboard', element: <UserDashboard /> },
  { name: 'MyProfile', path: 'profile', element: <UserProfile /> },
  { name: 'SubmittedForm', path: 'submitted-forms', element: <UserSubmittedForms /> },
  { name: 'SubmittedForm', path: 'submitted-forms/view', element: <UserMolecular /> },
  { name: 'CreateTest', path: 'create-test', element: <UserCreateTest /> },
  { name: 'CreateForm', path: 'create-test/surgical-pathology-requisition-form', element: <SurgicalPathology /> },
  { name: 'CreateForm', path: 'create-test/cytopathology-requisition-form', element: <Cytopathology /> },
  { name: 'CreateForm', path: 'create-test/gynecological-cytology-requisition-form', element: <GynecologicalCytology /> },
  { name: 'CreateForm', path: 'create-test/molecular-requisition-form', element: <AdminMolecular /> },
  { name: 'CreateForm', path: 'create-test/hematopathology-requisition-form', element: <Hematopathology /> },
  { name: 'CreateForm', path: 'create-test/podiatry-requisition-form', element: <PodiatryReq /> },
  { name: 'CreateForm', path: 'create-test/prostater-requisition-form', element: <ProstateReq /> },
]
function App() {

  const [roleName, setRoleName] = useState("admin");
  const location = useLocation();
  const dispatch = useDispatch();


  const getUsetData = async (data) => {
    dispatch(loding());
    let res = await loadUser(data);
    if (res?.status) {
      dispatch(loginUserApi(res?.data));
    }
  }

  useEffect(() => {
    if (
      pathname != "login" || !pathname
    ) {
      const token = localStorage.getItem("userToken");
      const payload = {
        token: token,
      };
      if(token){
        getUsetData(payload);
      }
    }
  }, []);

  const pathname = location.pathname.substr(1);
  const { isAuthenticate } = useSelector((store) => store?.root?.auth);

  const slug = location.pathname.split("/")[1];


  const Navigate = useNavigate();
  const { user } = useSelector((state) => state?.root?.auth);
  

  useLayoutEffect(() => {
    if (
      pathname == "login"  || !pathname
    ) {
      if (user && user?.group_name) {
        if (user?.group_name && user?.group_name == "admin") {
          Navigate("/admin")
        }
        if (user?.group_name && user?.group_name == "provider") {
          Navigate("/dashboard")
        }
        if (user?.group_name && user?.group_name == "") {
          Navigate("/login")
        }
      }
      if(Object.keys(user).length === 0){
        Navigate("/login")
      }
    }
    setRoleName(user && user?.group_name && user?.group_name);

  }, [user]);
  let routeElement = null;

  const renderAdminRoutes = () => {
    return adminPages.map((page, index) => {
      const { path, element } = page;
      return (
        <Route key={index} path={`/admin/${path}`} element={
          <PrivateRoute>
            <Layout>{element}</Layout>
          </PrivateRoute>
        } />
      );
    });
  }

  const renderUserRoutes = () => {
    return userPages.map((page, index) => {
      const { path, element } = page;
      return (
        <Route key={index} path={`/${path}`} element={
          <PrivateRoute>
            <UserLayout>{element}</UserLayout>
          </PrivateRoute>
        } />
      );
    });
  }

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {roleName === 'admin' && renderAdminRoutes()}

          {roleName === 'provider' && renderUserRoutes()}

        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default App;
