import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const CommonCheckbox = (props) => {
  const location = useLocation();
  const {
    labelInput,
    view,
    checkboxList,
    labelPlacement,
    singleCheckbox,
    label,
    checked,
    value,
    checkboxStyle,
  } = props;
  return (
    <>
      {labelInput && (
        <Typography
          fontSize={{ xs: "12px", sm: "14px" }}
          fontWeight={600}
          marginBottom={{ xs: "0px", sm: "6px" }}
          color={"#000"}
        >
          {labelInput}
        </Typography>
      )}
      <FormGroup aria-label="position" {...props}>
        {singleCheckbox ? (
          <FormControlLabel
            control={<Checkbox name={value} value={value} sx={checkboxStyle} />}
            label={label}
            disabled={view}
            labelPlacement={labelPlacement && "end"}
            {...props}
          />
        ) : (
          checkboxList.map((data, i) => {
            return (
              <FormControlLabel
                key={i}
                control={<Checkbox name={data.value} value={data.value}  checked={checked && checked?.includes(data.value)} onChange={data.onChange}  />}
                label={data.label}
                disabled={view}
                checked={checked}
                labelPlacement={labelPlacement && "end"}
                {...props}
              />
            );
          })
        )}
      </FormGroup>
    </>
  );
};

export default CommonCheckbox;
