import { LuLayoutDashboard, FaWpforms, HiOutlineUserGroup } from "./Icons";

export const SidebarList = [
    {
        name: 'Dashboard',
        icon: <LuLayoutDashboard style={{ color: '#000', fontSize: '20px' }} />,
        activeIcon: <LuLayoutDashboard style={{ color: '#fff', fontSize: '20px' }} />,
        path: '/admin'
    },
    {
        name: 'User Management',
        icon: <HiOutlineUserGroup style={{ color: '#000', fontSize: '20px' }} />,
        activeIcon: <HiOutlineUserGroup style={{ color: '#fff', fontSize: '20px' }} />,
        path: '/admin/user-management'
    },
    {
        name: 'Submitted Forms',
        icon: <FaWpforms style={{ color: '#000', fontSize: '20px' }} />,
        activeIcon: <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />,
        path: '/admin/submitted-forms'
    },
    {
        name: 'Create Test',
        icon: <FaWpforms style={{ color: '#000', fontSize: '20px' }} />,
        activeIcon: <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />,
        path: '/admin/create-test'
    },
]

export const UserSidebarList = [
    {
        name: 'Dashboard',
        icon: <LuLayoutDashboard style={{ color: '#000', fontSize: '20px' }} />,
        activeIcon: <LuLayoutDashboard style={{ color: '#fff', fontSize: '20px' }} />,
        path: '/dashboard'
    },
    {
        name: 'Submitted Forms',
        icon: <FaWpforms style={{ color: '#000', fontSize: '20px' }} />,
        activeIcon: <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />,
        path: '/submitted-forms'
    },
    {
        name: 'Create Test',
        icon: <FaWpforms style={{ color: '#000', fontSize: '20px' }} />,
        activeIcon: <FaWpforms style={{ color: '#fff', fontSize: '20px' }} />,
        path: '/create-test'
    },
]

export const ascusList = [
    { name: "Pap Only ", value: "Pap Only", },
    { name: "Pap w/reflex to HPV High-risk screen* if ASCUS/AGC or higher", value: "Pap w/reflex to HPV High-risk screen* if ASCUS/AGC or higher", },
    { name: "Pap w/reflex to HPV High-risk screen* if ASCUS/AGC or higher + CT +NG", value: "Pap w/reflex to HPV High-risk screen* if ASCUS/AGC or higher + CT +NG", },
    { name: "Pap with HRHPV (co-testing)", value: "Pap with HRHPV (co-testing)", },
    { name: "Pap w/CTNG", value: "Pap w/CTNG", },
    { name: "CTNG Only", value: "CTNG Only", },
    { name: "Trichomonas", value: "Trichomonas", },
    { name: "HPV Only", value: "HPV Only", },
]

export const gynList = [
    { name: "BV+Candida+Trich (Vaginal/Endocervical)", value: "BV+Candida+Trich (Vaginal/Endocervical)", },
    { name: "Mycoplasma/Ureaplasma (M. hominis, M. genitalium, U. parvum, U. urealyticum) (Vaginal/Endocervial)", value: "Mycoplasma/Ureaplasma (M. hominis, M. genitalium, U. parvum, U. urealyticum) (Vaginal/Endocervial)", },
    { name: "HSV 1&2 (Apex Dx UTM Swab) (Vaginal/Endocervical)", value: "HSV 1&2 (Apex Dx UTM Swab) (Vaginal/Endocervical)", },
    { name: "Trichomonas", value: "Trichomonas", },
    { name: "CT/NG", value: "CT/NG", },
]

export const sourceList = [
    { label: "Vaginal", value: "Vaginal", },
    { label: "Cervical", value: "Cervical", },
    { label: "Endocervical", value: "Endocervical", },
]

export const icdList = [
    { label: "Z01.419", value: "Z01.419", },
    { label: "R87.610 ASC-US", value: "R87.610 ASC-US", },
    { label: "R87.612 LGSIL", value: "R87.612 LGSIL", },
    { label: "Other (Free Text)", value: "Other (Free Text)", },
]

export const clinicalList = [
    { label: "Clinical History", value: "Clinical History", },
    { label: "Birth Control", value: "Birth Control", },
    { label: "Postpartum", value: "Postpartum", },
    { label: "Hysterectomy", value: "Hysterectomy", },
    { label: "Supracervical", value: "Supracervical", },
    { label: "Pregnant", value: "Pregnant", },
    { label: "Postmenopausal", value: "Postmenopausal", },
    { label: "Hormotherapy", value: "Hormotherapy", },
    { label: "IUD", value: "IUD", },
    { label: "DES exposure", value: "DES exposure", },
    { label: "Chemotherapy", value: "Chemotherapy", },
    { label: "Radiation therapy", value: "Radiation therapy", },
    { label: "Other (Free Text)", value: "Other (Free Text)", },
]

export const specimenList = [
    { label: "Vaginal", value: "Vaginal", },
    { label: "Cervical", value: "Cervical", },
    { label: "Endocervical", value: "Endocervical", },
    { label: "Conventional Pap", value: "Conventional Pap", },
]

export const gynSpecimen = [
    { label: "Hormonal Therapy", value: "Hormonal Therapy" },
    { label: "Quo", value: "Quo" },
    { label: "DES Exposure", value: "DES Exposure" },
    { label: "Chemotherapy", value: "Chemotherapy" },
    { label: "Radiation Therapy", value: "Radiation Therapy" },
    { label: "Birth Control", value: "Birth Control" },
    { label: "Postpartum", value: "Postpartum" },
    { label: "Hysterectomy", value: "Hysterectomy" },
    { label: "Supracervical", value: "Supracervical" },
    { label: "Pregnant", value: "Pregnant" },
    { label: "Postmenopausal", value: "Postmenopausal" },
    { label: "GYN Surgeries", value: "GYN Surgeries" },
    { label: "Hx of CA", value: "Hx of CA" },
    { label: "Other History", value: "Other History" }
];

export const treatmentStatus = [
    { label: "New Diagnosis", value: "New Diagnosis" },
    { label: "Follow Up", value: "Follow Up" },
    { label: "MRD", value: "MRD" },
    { label: "Relapse", value: "Relapse" },
    { label: "Monitoring", value: "Monitoring" },
];

export const therapy = [
    { label: "Prior ( >1 month ago )", value: "Prior" },
    { label: "Rituxan", value: "Rituxan" },
    { label: "Campath", value: "Campath" },
    { label: "Gieevec", value: "Gieevec" },
    { label: "Mylotarg", value: "Mylotarg" },
    { label: "Velcade", value: "Velcade" },
    { label: "Chemotherapy", value: "Chemotherapy" },
    { label: "Radiotherapy", value: "Radiotherapy" },
    { label: "EPO", value: "EPO" },
    { label: "GCSF", value: "GCSF" },
    { label: "GMCSF", value: "GMCSF" },
    { label: "Other", value: "Other" },
];

export const boneMarrowTransplantType = [
    { label: "Autologous", value: "Autologous" },
    { label: "Atlogeneic", value: "Atlogeneic" },
    { label: "Sex Mismatch", value: "Sex Mismatch" },
];

export const lymphoproliferative = [
    { label: "Chronic lymphocytic leukemia/small lymphocytic leukemia (CLL/SLL)", value: "Chronic lymphocytic leukemia/small lymphocytic leukemia (CLL/SLL)" },
    { label: "Mantle cell lymphoma (MCL)", value: "Mantle cell lymphoma (MCL)" },
    { label: "Form (FL)", value: "Form (FL)" },
    { label: "Hairy cell leukemia (HCL)", value: "Hairy cell leukemia (HCL)" },
    { label: "Diffuse large B-cell lymphoma (DLBCL)", value: "Diffuse large B-cell lymphoma (DLBCL)" },
    { label: "Barklymphoma ", value: "Barklymphoma" },
    { label: "Hodgkin lymphoma", value: "Hodgkin lymphoma" },
    { label: "Marginal zone lympha", value: "Marginal zone lympha" },
    { label: "T-cell lymphoma", value: "T-cell lymphoma" },
];

export const myeloproliferativeNeoplasms = [
    { label: "CML", value: "CML" },
    { label: "Polycythemia vera (PV)", value: "Polycythemia vera (PV)" },
    { label: "Essential thrombocytosis (ET)", value: "Essential thrombocytosis (ET)" },
    { label: "Idiopathic myelofibrosis (IMF)", value: "Idiopathic myelofibrosis (IMF)" },
    { label: "Other", value: "Other" },
];

export const flowCytometry = [
    { label: "Comprehensive (myeloid, B-, T-Cell)", value: "Comprehensive (myeloid, B-, T-Cell)" },
    { label: "B- & T-Cell", value: "B- & T-Cell" },
    { label: " Plasma Cell", value: "Plasma Cell" },
    { label: "The RNH", value: "The RNH" },
    { label: "ZAP-70", value: "ZAP-70" },
];

export const chromosomeAnalysis = [
    { label: "Classical Cytogenetics", value: "Classical Cytogenetics" },
    { label: "Array-CGH", value: "Array-CGH" },
];

export const pcr = [
    { label: "IGH/CCND1, t(11;14) for MCL, PCN (subset)", value: "IGH/CCND1, t(11;14) for MCL, PCN (subset)" },
    { label: "IGH/BCL2, t(14;18) for FL, DLBCL (subset) ", value: "IGH/BCL2, t(14;18) for FL, DLBCL (subset) " },
    { label: "PML/RARA, 1(15;17) for APL monitoring ", value: "PML/RARA, 1(15;17) for APL monitoring " },
    { label: "BCR/ABL, Quantitative, t(9;22) for CML, ALL", value: "BCR/ABL, Quantitative, t(9;22) for CML, ALL" },
    { label: "BCR/ABL,major (p210; b2a2 or b2a3)", value: "BCR/ABL,major (p210; b2a2 or b2a3)" },
    { label: "BCR/ABL,minor (p190; e1a2)", value: "BCR/ABL,minor (p190; e1a2)" },
    { label: "JAK2 mutation analysis for PV, ET, IMF", value: "JAK2 mutation analysis for PV, ET, IMF" },
    { label: "FLT3 mutation analysis for AML ", value: "FLT3 mutation analysis for AML " },
    { label: "KIT mutation analysis for AML ", value: "KIT mutation analysis for AML " },
    { label: "MPL mutation analysis for ET, IMF ", value: "MPL mutation analysis for ET, IMF " },
    { label: "FIP1L1/PDGFRA (4q 12 deletion)", value: "FIP1L1/PDGFRA (4q 12 deletion)" },
    { label: "B-Cell (IGH) Gene Rearrangement, B-Cell Clonality ", value: "B-Cell (IGH) Gene Rearrangement, B-Cell Clonality " },
    { label: "T-Cell (TGR) Gene Rearrangement, T-cell Clonality ", value: "T-Cell (TGR) Gene Rearrangement, T-cell Clonality " },
];

export const fishPanels = [
    { label: "CLL/SLL Panel", value: "CLL/SLL Panel" },
    { label: "MDS Panel", value: "MDS Panel" },
    { label: "Plasma Cell Neoplasm /Myeloma Panel", value: "Plasma Cell Neoplasm /Myeloma Panel" },
    { label: "NHL Panel", value: "NHL Panel" },
    { label: "AML Panel", value: "AML Panel" },
];

export const induvidualFishAssays = [
    { label: "IGH/CCND1, t(11;14) for MCL, PCN (subset)", value: "IGH/CCND1, t(11;14) for MCL, PCN (subset)" },
    { label: " IGH/BCL2, t(14;18) for FL, DLBCL (subset)", value: " IGH/BCL2, t(14;18) for FL, DLBCL (subset)" },
    { label: " PML/RARA, t(15;17) for APL", value: " PML/RARA, t(15;17) for APL" },
    { label: "BCR/ABL, t(9;22) for CML, ALL", value: "BCR/ABL, t(9;22) for CML, ALL" },
    { label: " FIP1L1/PDGFRA (4q12 deletion)", value: " FIP1L1/PDGFRA (4q12 deletion)" },
    { label: "PDGFRB Rearrangement; t(15;12) ", value: "PDGFRB Rearrangement; t(15;12) " },
    { label: "QX,Y for Bone Marrow Transplant", value: "QX,Y for Bone Marrow Transplant" },
];

export const nailBiopsy = [
    { label: "Nail Panel by PCR (nailbag)", value: "Nail Panel by PCR (nailbag)" },
    { label: "Histology with stains: PAS, GAS, FM (formalin or nail bag)", value: "Histology with stains: PAS, GAS, FM (formalin or nail bag)" },
    { label: "Histology with stains: PAS (formalin or nail bag)", value: "Histology with stains: PAS (formalin or nail bag)" },
    { label: "Fungal culture (nail bag)", value: "Fungal culture (nail bag)" },
];

export const boneBiopsy = [
    { label: "Histology (formalin)", value: "Histology (formalin)" },
    { label: "Histology with gout analysis (100% Alcohol)", value: "Histology with gout analysis (100% Alcohol)" },
    { label: "Culture (swab)", value: "Culture (swab)" },
    { label: "Histology with stains: PAS, GAS, Fontana-Masson", value: "Histology with stains: PAS, GAS, Fontana-Masson" },
];

export const aspirateJointFluid = [
    { label: "Crystal analysis (lavender top tube)", value: "Crystal analysis (lavender top tube)" },
    { label: "Cell count (lavender top tube)", value: "Cell count (lavender top tube)" },
    { label: "Culture (culture tube)", value: "Culture (culture tube)" },
];

export const aspirateOtherFluid = [
    { label: "Cytology only (cytolyt)", value: "Cytology only (cytolyt)" },
    { label: "Crystal analysis for gout (lavender top tube)", value: "Crystal analysis for gout (lavender top tube)" },
    { label: "Culture (culture tube)", value: "Culture (culture tube)" },
];

export const microbiology = [
    { label: "Culture and sensitivity only (swab)", value: "Culture and sensitivity only (swab)" },
    { label: "Aerobic only (for skin or superficial wound)", value: "Aerobic only (for skin or superficial wound)" },
    { label: "Aerobic and Anaerobic (for deep wound or abscess)", value: "Aerobic and Anaerobic (for deep wound or abscess)" },
    { label: "Fungal (Swab)", value: "Fungal (Swab)" },
];

export const prostateIcdCodes = [
    { label: "Elevated PSA (790.93)", value: "Elevated PSA (790.93)" },
    { label: "Uncertain Neoplasm Prostate (236.5)", value: "Uncertain Neoplasm Prostate (236.5)" },
    { label: "Malignant Neoplasm of Prostate (185)", value: "Malignant Neoplasm of Prostate (185)" },
    { label: "Hyperplasia of Prostate (600.90)", value: "Hyperplasia of Prostate (600.90)" },
    { label: "Hx of Prostate Cancer (V 10.46)", value: "Hx of Prostate Cancer (V 10.46)" },
    { label: "Other", value: "Other" },
];

export const bladderIcdCodes = [
    { label: "Hematuria, Unspecified (599.70)", value: "Hematuria, Unspecified (599.70)" },
    { label: "Gross Hematuria (599.71)", value: "Gross Hematuria (599.71)" },
    { label: "Microscopic Hematuria (599.72)", value: "Microscopic Hematuria (599.72)" },
    { label: "Hx of Bladder Cancer (V10.51)", value: "Hx of Bladder Cancer (V10.51)" },
    { label: "Irritable bladder (596.8)", value: "Irritable bladder (596.8)" },
    { label: "Malignant Neoplasm of Bladder (188.9)", value: "Malignant Neoplasm of Bladder (188.9)" },
    { label: "Other", value: "Other" },
];

export const specimenType = [
    { label: "Prostate Bx", value: "Prostate Bx" },
    { label: "Carrying Vessel", value: "Carrying Vessel" },
    { label: "Urine Cytology", value: "Urine Cytology" },
    { label: "Urovysion", value: "Urovysion" },
    { label: "Other", value: "Other" },
];

export const urineCytologySource = [
    { label: "Voided Urine", value: "Voided Urine" },
    { label: "Cath Urine", value: "Cath Urine" },
    { label: "Other", value: "Other" },
];

export const prostateMultiSelect = [
    {
        label: "Left Seminal Vesicle", value: "Left Seminal Vesicle",
    },
    {
        label: "Right Seminal Vesicle", value: "Right Seminal Vesicle",
    },
    {
        label: "Left Lateral Base", value: "Left Lateral Base",
    }, {
        label: "Right Lateral Base", value: "Right Lateral Base",
    }, {
        label: "Left Base", value: "Left Base",
    }, {
        label: "Right Base", value: "Right Base"
    }, {
        label: "Left Lateral Mid", value: "Left Lateral Mid"
    }, {
        label: "Right Lateral Mid", value: "Right Lateral Mid"
    }, {
        label: "Left Mid", value: "Left Mid"
    }, {
        label: "Right Mid", value: "Right Mid"
    }, {
        label: "Left Lateral Apex", value: "Left Lateral Apex"
    }, {
        label: "Right Lateral Apex", value: "Right Lateral Apex"
    }, {
        label: "Left Apex", value: "Left Apex"
    }, {
        label: "Right Apex", value: "Right Apex"
    }, {
        label: "Right Posterior Medial", value: "Right Posterior Medial"
    }, {
        label: "Right Posterior Lateral", value: "Right Posterior Lateral"
    }
    , {
        label: "Right Anterior Medial", value: "Right Anterior Medial"
    }
    , {
        label: "Right Anterior Lateral", value: "Right Anterior Lateral"
    }
    , {
        label: "Left Posterior Medial", value: "Left Posterior Medial"
    }
    , {
        label: "Left Posterior Lateral", value: "Left Posterior Lateral"
    }
    , {
        label: "Left Anterior Medial", value: "Left Anterior Medial"
    }
    , {
        label: "Left Anterior Lateral", value: "Left Anterior Lateral"
    }
    // 'Left Seminal Vesicle',
    // 'Right Seminal Vesicle',
    // 'Left Lateral Base',
    // 'Right Lateral Base',
    // 'Left Base',
    // 'Right Base',
    // 'Left Lateral Mid',
    // 'Right Lateral Mid',
    // 'Left Mid',
    // 'Right Mid',
    // 'Left Lateral Apex',
    // 'Right Lateral Apex',
    // 'Left Apex',
    // 'Right Apex',
    // 'Right Posterior Medial',
    // 'Right Posterior Lateral',
    // 'Right Anterior Medial',
    // 'Right Anterior Lateral',
    // 'Left Posterior Medial',
    // 'Left Posterior Lateral',
    // 'Left Anterior Medial',
    // 'Left Anterior Lateral',
];


export const SurgicalPathologycheckboxOptions = [
    'Biopsy',
    'Cone Biopsy',
    'Curettage',
    'D&C',
    'Fine Needle Aspiration',
    'Hysterectomy',
    'LEEP',
    'Shave Biopsy',
    'Supracervical hysterectomy',
    'Total hysterectomy',
];

export const CytopathologycheckboxOptions = [
    'Fine Needle Aspiration',
    'Cyst Aspiration',
    'Voided Specimen (urine)',
    'Catheterized specimen (urine)',
    'Urine',
    'Nipple discharge, Right',
    'Nipple discharge, Left',
    'Paracentesis',
    'Thoracentesis',
    'Sputum',
];

export const GynecologicalCytologycheckboxOptions = [
    'ThinPrep',
    'SurePath',
    'ApexUTM',
];

export const molecularcheckboxOptions = [
    'GC',
    'Chlamydia',
    'HPV',
    'Pertussis',
    'Group A Strep',
    'Group B Strep',
    'C. Diff only',
    'Influenza A, A-2009H1N1, and B',
    'GI panel',
    'Respiratory panel',
    'Candida',
    'Bacterial Vaginosis',
    'Mycoplasma/UreaPlasma',
    'HSV 1 & 2',
    'Trichomonas',
    'COVID19',
    'Nail Panel',
    'Pneumonia Panel',
    'Affirm',
];

export const hematopathologycheckboxOptions = [
    'Peripheral Blood',
    'Bone Marrow',
    'Fluid',
    'Fresh Tissue',
    'Smears',
    'Diagnosis or Signs/Symptoms',
    'Therapy',
    'Bone Marrow Transplant Type',
    'Lymphoproliferative Disorders',
    'Myeloproliferative Neoplasms',
    'Flow Cytometry',
    'Chromosome Analysis',
    'PCR#',
    'Fish Panels*',
    'Individual Fish Assays',
];


export const podiatrycheckboxOptions = [
    'Nail, Biopsy',
    'Skin, Soft Tissue, Bone Biopsy',
    'Aspirate (Joint Fluid)',
    'Aspirate (Cyst Or Other Fluid)',
    'Microbiology Only',
];


export const prostatecheckboxOptions = [
    'Prostate-ICD-10 Codes',
    'Bladder-ICD-10 Codes',
    'Specimen Type',
    'Urine Cytology Source',
];

export const hospitalList = [
    { name: "abc", value: "abc", },
    { name: "xyz", value: "xyz", },
]


export const EventEmitter = {
    events: {},
    dispatch: function (event, data) {
        if (!this.events[event]) return
        this.events[event].forEach((callback) => callback(data))
    },
    subscribe: function (event, callback) {
        if (!this.events[event]) this.events[event] = []
        this.events[event].push(callback)
    }
}