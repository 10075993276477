import { get, post, getAllResponse, deleteRequest, put } from "../web.request";

export const createUser = async (data) => {
    return await post(`/create_user`, data);
}

export const userList = async (data) => {
    return await getAllResponse(`/create_user${data}`);
}

export const deleteUser = async (data) => {
    return await deleteRequest(`/delete_user/${data}`);
}

export const userStatusUpdate = async (data) => {
    return await put(`/status_update/${data}`);
}

export const userUpdateSave = async (id, data) => {
    return await put(`/admin_update_user/${id}`, data);
}

export const userPassUpdate = async (id, data) => {
    return await put(`/admin_change_password/${id}`, data);
}

export const userChangePasswordHandler = async (data) =>{
    return await put(`/change_password`,data);
}