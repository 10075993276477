import React from 'react'
import { Molecular } from '../../Pages/createtest/forms/molecular'
import { useLocation } from 'react-router-dom';
import CommanFormView from '../../Component/Commons/CommanFormView';

const UserMolecular = () => {
  return (
    // <Molecular/>
    <CommanFormView/>
  )
}

export default UserMolecular