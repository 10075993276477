import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export default function DateFilter(props) {
  const { fromDate, toDate,formik, setFromDate, setToDate, days, setDays, className,setWeekDayStartName, setMonthStartName, setMonthEndName, setWeekDayEndName } = props;

  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
        moment()
            .subtract(1, "month")
            .startOf("month"),
        moment()
            .subtract(1, "month")
            .endOf("month")
    ],
    "Last Year": [
        moment()
            .subtract(1, "year")
            .startOf("year"),
        moment()
            .subtract(1, "year")
            .endOf("year")
    ]
};

const handleEvent = (event, picker) => {
  const diffDays = picker.endDate.diff(picker.startDate, 'days');
  if (diffDays) {
      const startDayOfWeek = picker.startDate.format("dddd"); // Get the weekday name of the start date
      const endDayOfWeek = picker.endDate.format("dddd"); // Get the weekday name of the end date
      const startMonth = picker.startDate.format("MMMM"); // Get the month name of the start date
      const endMonth = picker.endDate.format("MMMM"); // Get the month name of the end date
      setWeekDayStartName(startDayOfWeek);
      setMonthStartName(startMonth);
      setWeekDayEndName(endDayOfWeek);
      setMonthEndName(endMonth);
  }

  setDays(diffDays);
  setFromDate(moment(picker.startDate._d).format("YYYY-MM-DD"));
  formik.setFieldValue('startDate', moment(picker.startDate._d).format("YYYY-MM-DD"));
  formik.setFieldValue('endDate', moment(picker.endDate._d).format("YYYY-MM-DD"));
  setToDate(moment(picker.endDate._d).format("YYYY-MM-DD"));
};

  return (
    <div className={`${className ? className : ''} date_range`}>
      <DateRangePicker
                initialSettings={{
                    ranges: range,
                }}
                alwaysShowCalendars={true}
                // onEvent={handleEvent}
                onApply={handleEvent}
            >
                <button>
                    {!fromDate ? 'Select Date' :
                        `${moment(fromDate).format("L")} to ${moment(toDate).format("L")}`}
                </button>
            </DateRangePicker>
    </div>
  );
}
