import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { SurgicalPathologyIv } from "../../Helper/initialValues";
import UserDetails from "../../Pages/createtest/UserDetails";
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import { Checkbox, Input } from "../Commons";
import { StyledTableRow, useStyles } from "../../Helper/Theme";
import { getSurgicalFormByFormId } from "../../Service/surgical.service";
import { useLocation } from "react-router-dom";

function SurgicalFormView() {
  const classes = useStyles();
  const { state } = useLocation();
  const [consultantText, setConsultantText] = useState("");
  const [cytologySlides, setCytologySlides] = useState([
    {
      slides0: "",
      slides01: "",
      slides1: "",
    },
    {
      slides0: "",
      slides01: "",
      slides1: "",
    },
  ]);
  const PathologySpecimens = [
    { value: "Formalin", label: "Formalin" },
    { value: "Saline", label: "Saline" },
    { value: "other", label: "other" },
  ];

  const [pathologySpecimensValue,setPathologySpecimensValue] = useState([]);
  const [pathologyText,setPathologyText] = useState("");

  const formik = useFormik({
    initialValues: SurgicalPathologyIv,
  });

  useEffect(() => {
    surgicalFormApiHandler();
  }, []);

  

  const [bodySubProcedureData, setBodySubProcedureData] = useState([]);

  const cytologySpecimens = [
    {
      value: "95% Alcohol",
      label: "95% Alcohol",
    },
    {
      value: "other",
      label: "other",
    },
  ];

  const [cytologySpecimensValues,setCytologySpecimensValues] = useState([]);
  const [cytologyText,setCytologyText] = useState("");

  const [breastTable, setBreastTable] = useState([
    {
      specimenNumber: "",
      timeTakenoutOfPatient: "",
      timePacedInFormalin: "",
    },
    {
      specimenNumber: "",
      timeTakenoutOfPatient: "",
      timePacedInFormalin: "",
    },
    {
      specimenNumber: "",
      timeTakenoutOfPatient: "",
      timePacedInFormalin: "",
    },
  ]);

  const consultantSurgery = [
    {
      value: "Scheduled",
      label: "Scheduled",
    },
    {
      value: "Gross",
      label: "Gross",
    },
    {
      value: "Unscheduled",
      label: "Unscheduled",
    },
    {
      value: "Frozen",
      label: "Frozen",
    },
  ];

  const [consultantSurgeryValue,setConsultantSurgeryValue] = useState([]);

  const surgicalFormApiHandler = async () => {
    const { data, message, status } = await getSurgicalFormByFormId(
      state.formId
    );
    if (status) {
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data?.user_information?.facility_name,
          doctor: data?.user_information?.full_name,
          address: data?.user_information?.address,
          phone: data?.user_information?.phone,
          fax: data?.user_information?.fax,
        },
        patientInformation:{
            patient_first_name: data?.patient_information?.patient_first_name,
            patient_last_name: data?.patient_information?.patient_last_name,
            initial: data?.patient_information?.initial,
            patient_address: data?.patient_information?.patient_address,
            patient_city: data?.patient_information?.patient_city,
            patient_state: data?.patient_information?.patient_state,
            patient_zip: data?.patient_information?.patient_zip,
            patient_area_code: data?.patient_information?.patient_area_code,
            patient_age: data?.patient_information?.patient_age,
            patient_sex: data?.patient_information?.patient_sex,
            patient_dob: data?.patient_information?.patient_dob,
            patient_phone: data?.patient_information?.patient_phone,
            patient_payment: data?.patient_information?.patient_payment,
            pay_amount: data?.patient_information?.pay_amount,
            bill_number: data?.patient_information?.bill_number,
            bill_amount: data?.patient_information?.bill_amount,
            insurance_company: data?.patient_information?.insurance_company,
            insurance_no: data?.patient_information?.insurance_no,
            icd_10: data?.patient_information?.icd_10,
            clinical_history: data?.patient_information?.clinical_history,
            additional_physician: data?.patient_information?.additional_physician == null ? "": data?.patient_information?.additional_physician,
            other_test: data?.patient_information?.other_test,
            pationtDoc : [data?.patient_information?.patient_document],
        }
    });
    setPathologySpecimensValue(data?.surgical_pathology_information?.pathology_submitted);
    setBodySubProcedureData(data?.surgical_pathology_information?.pathology_specimens);
    setCytologySpecimensValues(data?.surgical_pathology_information?.cytology_submitted);
    setBreastTable(data?.surgical_pathology_information?.breast_table);
    setConsultantSurgeryValue(data?.surgical_pathology_information?.consultant_surgery);
    setCytologySlides(data?.surgical_pathology_information?.cytology_specimens);
    setConsultantText(data?.surgical_pathology_information?.box_text);
    setPathologyText(data?.surgical_pathology_information?.pathology_specimens_other);
    setCytologyText(data?.surgical_pathology_information?.cytology_specimens_other);
    } else {
    }
  };

  return (
    <>
      <UserDetails formik={formik} view={true} />

      <Grid container spacing={2}>
        <Grid item lg={7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                fontWeight={"700"}
                fontSize={{ xs: "15px", sm: "18px" }}
              >
                Pathology Specimens{" "}
                <small style={{ fontWeight: "500", marginLeft: "10px" }}>
                  <i>
                    Indicate site, fixative. Submit specimen fresh for frozen
                    section.
                  </i>
                </small>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <Typography
                fontWeight={"500"}
                marginRight={1}
                fontSize={{ xs: "13px", sm: "16px" }}
              >
                Submitted in:
              </Typography>
              <Checkbox
                checkboxList={PathologySpecimens}
                row
                view={true}
                checked={pathologySpecimensValue}
                checkboxStyle={{ padding: "0 5px" }}
              />
             
             {
              pathologyText && <Input variant="standard" value={pathologyText} view={true}/>
             }
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                className={classes.cardContainer}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableBody>
                    <StyledTableRow>
                      <TableCell sx={{ textAlign: "center !important" }}>
                        Body Site
                      </TableCell>
                      <TableCell>Sub Site</TableCell>
                      <TableCell>Procedure</TableCell>
                    </StyledTableRow>
                    {bodySubProcedureData.map((data, i) => {
                      return (
                        <StyledTableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Stack
                              flexDirection={"row"}
                              gap={1}
                              alignItems={"flex-end"}
                            >
                              <Typography
                                fontWeight={"700"}
                                sx={{ width: "20px" }}
                                fontSize={{ xs: "13px", sm: "16px" }}
                              >
                                {i + 1}.
                              </Typography>
                              <Input
                                boxWidth="100%"
                                variant="standard"
                                value={data.BodySite}
                                view={true}
                               
                              />
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Input
                              variant="standard"
                              value={data.SubSite}
                              view={true}
                            
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Input
                              variant="standard"
                              value={data.Procedure}
                              view={true}
                             
                            />
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontWeight={"700"}
                fontSize={{ xs: "15px", sm: "18px" }}
              >
                Cytology Specimens{" "}
                <small style={{ fontWeight: "500", marginLeft: "10px" }}>
                  <i>(non-gyn cytology & fine needle aspirations):</i>
                </small>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontWeight={"700"}
                fontSize={{ xs: "15px", sm: "18px" }}
              >
                <small style={{ fontWeight: "500" }}>
                  Indicate site, fixative, and any other features
                </small>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <Typography
                fontWeight={"500"}
                fontSize={{ xs: "13px", sm: "16px" }}
              >
                Submitted in:
              </Typography>

              <Checkbox
                checkboxList={cytologySpecimens}
                row
                view={true}
                checked={cytologySpecimensValues}
              />
              {
                cytologyText && <Input variant="standard" value={cytologyText} view={true}/>
              }
            
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"}>
              <Typography
                fontWeight={"700"}
                fontSize={{ xs: "15px", sm: "18px" }}
              >
                Breast Table{" "}
              </Typography>
              <small
                style={{
                  fontWeight: "500",
                  marginLeft: "10px",
                  fontSize: "12px",
                }}
              >
                <i>
                  Required for all breast <br /> samples fill out Breast Table
                </i>
              </small>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                className={classes.cardContainer}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableBody>
                    <StyledTableRow>
                      <TableCell sx={{ textAlign: "center !important" }}>
                        Specimen <br /> number
                      </TableCell>
                      <TableCell>
                        Time taken out <br /> of Patient
                      </TableCell>
                      <TableCell>
                        Time paced in <br /> 10% formalin
                      </TableCell>
                    </StyledTableRow>
                    {breastTable.map((data, i) => {
                      return (
                        <StyledTableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Input
                              variant="standard"
                              value={data.specimenNumber}
                              view={true}
                         
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Input
                              variant="standard"
                              value={data.timeTakenoutOfPatient}
                              view={true}
                             
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Input
                              variant="standard"
                              value={data.timePacedInFormalin}
                              view={true}
                             
                            />
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                className={classes.cardContainer}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableBody>
                   
                    <StyledTableRow>
                      <TableCell sx={{ textAlign: "center !important" }}>
                        Consultant <br /> at Surgery
                      </TableCell>
                      <TableCell sx={{ textAlign: "center !important" }}>
                        <Stack sx={{ width: "min-content", margin: "auto" }}>
                        
                          <Checkbox
                            checkboxList={consultantSurgery}
                            row
                            view={true}
                            checked={consultantSurgeryValue}
                           
                          />
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center !important" }}>
                        <Stack sx={{ width: "min-content", margin: "auto" }}>
                        
                        </Stack>
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell
                        colSpan={3}
                        component="th"
                        scope="row"
                        sx={{ height: { sm: "250px" } }}
                      >
                        <textarea
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: "12px",
                            fontSize: "16px",
                          }}
                          value={consultantText}
                          disabled={true}
                          onChange={(event) =>
                            setConsultantText(event.target.value)
                          }
                        ></textarea>
                      </TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                className={classes.cardContainer}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableBody>
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell component="th" scope="row">
                        Slides
                      </TableCell>
                    </StyledTableRow>
                    {cytologySlides.map((res, index) => {
                      return (
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Input
                              boxWidth="100%"
                              variant="standard"
                              value={res.slides0}
                              view={true}
                             
                            />{" "}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Input
                              variant="standard"
                              value={res.slides01}
                              view={true}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Input
                              variant="standard"
                              value={res.slides1}
                              view={true}
                            />
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}

                 
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SurgicalFormView;
