import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, LOADING } from '../action-types/authActionType';


export const loginUserApi = (data) => async (dispatch) => {
    dispatch({ type: LOGIN_SUCCESS, payload: data });
}

export const loding = (data) => async (dispatch) => {
    dispatch({ type: LOADING });
}

export const logoutApi = () => async (dispatch) => {
    dispatch({ type: LOGOUT });
}

