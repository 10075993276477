import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Checkbox, Input, Modal } from "../../../../Component/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon, FiFileText } from "../../../../Helper/Icons";
import UserDetails from "../../UserDetails";
import {
  StyledTableCell,
  StyledTableRow,
  useStyles,
} from "../../../../Helper/Theme";
import StaticUserDetails from "../../StaticUserDetails";
import { useFormik } from "formik";
import { SurgicalPathologyIv } from "../../../../Helper/initialValues";
import { getProfile } from "../../../../Service/profile.service";
import { SurgicalPathologyValidation } from "../../../../Helper/validation";
import { createSurgicalFormhandler } from "../../../../Service/surgical.service";
import { showNotification } from "../../../../Helper/Notification";
import { useSelector } from "react-redux";
import { savePatientDoc } from "../../../../Service/commanForm.service";

const SurgicalPathology = () => {
  const classes = useStyles();
  const [referByUser, setReferByUser] = useState({});
  const [pathologySpecimesValues, setPathologySpecimesValues] = useState([]);
  const [consultantSurgeryValues, setConsultantSurgeryValues] = useState([]);
  const [cytologyValues, setCytologyValues] = useState([]);
  const [consultantText, setConsultantText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { user } = useSelector((state) => state?.root?.auth);
  const [pathologyOtherText, setPathologyOtherText] = useState("");
  const [pathologyOtherFlag, setPathologyOtherFlag] = useState(false);

  const [cytologyOtherText,setCytologyOtherText] = useState("");
  const [cytologyFlag,setCytologyFlag] = useState(false);
  
  const [cytologySlides, setCytologySlides] = useState([
    {
      slides0: "",
      slides01: "",
      slides1: "",
    },
    {
      slides0: "",
      slides01: "",
      slides1: "",
    },
  ]);

  const validationHandler = () => {
    setIsFormSubmitted(true);
    if (Object.keys(formik.errors).length > 0) {
      showNotification("error", "Required filed is missing");
    }
  };

  const PathologySpecimens = [
    { value: "Formalin", label: "Formalin" },
    { value: "Saline", label: "Saline" },
    { value: "other", label: "other" },
  ];

  const [bodySubProcedureData, setBodySubProcedureData] = useState([
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
  ]);

  const [breastTable, setBreastTable] = useState([
    {
      specimenNumber: "",
      timeTakenoutOfPatient: "",
      timePacedInFormalin: "",
    },
    {
      specimenNumber: "",
      timeTakenoutOfPatient: "",
      timePacedInFormalin: "",
    },
    {
      specimenNumber: "",
      timeTakenoutOfPatient: "",
      timePacedInFormalin: "",
    },
  ]);

  //
  const breastChangeHadler = (index, field, value) => {
    const newData = [...breastTable];
    newData[index][field] = value;
    formik.setFieldValue("breast_table", newData);
    setBreastTable(newData);
  };

  const consultantSurgery = [
    {
      value: "Scheduled",
      label: "Scheduled",
    },
    {
      value: "Gross",
      label: "Gross",
    },
    {
      value: "Unscheduled",
      label: "Unscheduled",
    },
    {
      value: "Frozen",
      label: "Frozen",
    },
  ];

  // CHECK box
  const consultantSurgeryHandler = (value) => {
    if (consultantSurgeryValues.includes(value)) {
      setConsultantSurgeryValues(
        consultantSurgeryValues.filter((v) => v !== value)
      );
      formik.setValues({
        ...formik.values,
        consultant_surgery: consultantSurgeryValues.filter((v) => v !== value),
      });
    } else {
      setConsultantSurgeryValues([...consultantSurgeryValues, value]);
      formik.setValues({
        ...formik.values,
        consultant_surgery: [...consultantSurgeryValues, value],
      });
    }
  };

  const cytologySlideHandler = (index, field, value) => {
    const newData = [...cytologySlides];
    newData[index][field] = value;
    setCytologySlides(newData);
    formik.setFieldValue("cytology_specimens", newData);
  };

  const pathologyHandler = (value) => {
    if (value === "other") {
      setPathologyOtherFlag(!pathologyOtherFlag);
      setPathologyOtherText("");
    } 
    if (pathologySpecimesValues.includes(value)) {
      setPathologySpecimesValues(
        pathologySpecimesValues.filter((v) => v !== value)
      );
      formik.setValues({
        ...formik.values,
        pathologySubmit: pathologySpecimesValues.filter((v) => v !== value),
      });
    } else {
      setPathologySpecimesValues([...pathologySpecimesValues, value]);
      formik.setValues({
        ...formik.values,
        pathologySubmit: [...pathologySpecimesValues, value],
      });
    }
  };

  const cytologySpecimens = [
    {
      value: "95% Alcohol",
      label: "95% Alcohol",
    },
    {
      value: "other",
      label: "other",
    },
  ];

  const cytologyHandler = (value) => {
    if(value === "other"){
      setCytologyFlag(!cytologyFlag);
      setCytologyOtherText("");
    }
    if (cytologyValues.includes(value)) {
      setCytologyValues(cytologyValues.filter((v) => v !== value));
      formik.setValues({
        ...formik.values,
        cytology_submitted: cytologyValues.filter((v) => v !== value),
      });
    } else {
      setCytologyValues([...cytologyValues, value]);
      formik.setValues({
        ...formik.values,
        cytology_submitted: [...cytologyValues, value],
      });
    }
    // setCytologyValues(cytologyValues)
  };

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  //

  // handleChange will handle data of bodySubProcedureData
  const handleChange = (index, field, value) => {
    const newData = [...bodySubProcedureData];
    newData[index][field] = value;
    setBodySubProcedureData(newData);
    formik.setFieldValue("pathology_specimens", newData);
  };

  useEffect(() => {
    referredByDataHandler();
  }, []);

  const referredByDataHandler = async () => {
    const { data, message, status } = await getProfile();
    if (status) {
      setReferByUser(data);
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data.facility_name,
          doctor: data.full_name,
          address: data.address,
          phone: data.phone,
          fax: data.fax,
        },
      });
    }
  };

  const submitFormHandler = async () => {
    const payload = {
      patient_information: {
        patient_first_name: formik.values.patientInformation.patient_first_name,
        patient_last_name: formik.values.patientInformation.patient_last_name,
        initial: formik.values.patientInformation.initial,
        patient_address: formik.values.patientInformation.patient_address,
        patient_city: formik.values.patientInformation.patient_city,
        patient_state: formik.values.patientInformation.patient_state,
        patient_zip: formik.values.patientInformation.patient_zip,
        patient_area_code: formik.values.patientInformation.patient_area_code,
        patient_age: formik.values.patientInformation.patient_age,
        patient_sex: formik.values.patientInformation.patient_sex,
        patient_dob: formik.values.patientInformation.patient_dob,
        patient_phone: formik.values.patientInformation.patient_phone,
        patient_payment: formik.values.patientInformation.patient_payment,
        bill_number: formik.values.patientInformation.bill_number,
        bill_amount: formik.values.patientInformation.bill_amount,
        insurance_company: formik.values.patientInformation.insurance_company,
        insurance_no: formik.values.patientInformation.insurance_no,
        pay_amount: formik.values.patientInformation.pay_amount,
        icd_10: formik.values.patientInformation.icd_10,
        clinical_history: formik.values.patientInformation.clinical_history,
        additional_physician:
          formik.values.patientInformation.additional_physician,
        other_test: formik.values.patientInformation.other_test,
      },
      surgical_pathology_information: {
        pathology_specimens: formik.values.pathology_specimens,
        pathology_specimens_other:pathologyOtherText,
        pathology_submitted: formik.values.pathologySubmit,
        breast_table: formik.values.breast_table,
        consultant_surgery: formik.values.consultant_surgery,
        box_text: consultantText,
        cytology_specimens: formik.values.cytology_specimens,
        cytology_specimens_other:cytologyOtherText,
        cytology_submitted: formik.values.cytology_submitted,
      },
    };

    const { data, message, status } = await createSurgicalFormhandler(payload);
    if (status) {
      if (formik.values.patientInformation.pationtDoc && formik.values.patientInformation.pationtDoc.length > 0) {
        savePatientDocHandler(data?.patient_information?.id)
      } else {

        showNotification("success", message || 'Form Register Successfully');
        if (user.group_name === "provider") {
          navigate(`/submitted-forms`)
        } else {
          navigate(`/admin/submitted-forms`);
        }
      }
    } else {
      showNotification(
        "error",
        message || "Something went wrong while Form Register"
      );
    }
  };

  const formik = useFormik({
    initialValues: SurgicalPathologyIv,
    validationSchema: SurgicalPathologyValidation,
    onSubmit: submitFormHandler,
    // onSubmit: submitFormHandler,
  });
  const savePatientDocHandler = async (id) => {
    const formData = new FormData();
    formData.append("patient_document", formik.values.patientInformation.pationtDoc[0]);
    const { data, message, status } = await savePatientDoc(id,formData);
    if (status) {
      showNotification("success", message || 'Form Register Successfully');
      if (user.group_name === "provider") {
        navigate(`/submitted-forms`)
      } else {
        navigate(`/admin/submitted-forms`);
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", background: "#8cc63f", color: "#fff" }}
        />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
      <Box
        className={classes.cardContainer}
        padding={{ xs: "12px 10px", sm: 3 }}
      >
        <UserDetails formik={formik} isFormSubmitted={isFormSubmitted} />
        {/* <StaticUserDetails /> */}
        <Grid container spacing={2}>
          <Grid item lg={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  fontWeight={"700"}
                  fontSize={{ xs: "15px", sm: "18px" }}
                >
                  Pathology Specimens{" "}
                  <small style={{ fontWeight: "500", marginLeft: "10px" }}>
                    <i>
                      Indicate site, fixative. Submit specimen fresh for frozen
                      section.
                    </i>
                  </small>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  fontWeight={"500"}
                  marginRight={1}
                  fontSize={{ xs: "13px", sm: "16px" }}
                >
                  Submitted in:
                </Typography>
                <Checkbox
                  checkboxList={PathologySpecimens}
                  row
                  checkboxStyle={{ padding: "0 5px" }}
                  onChange={(event) => pathologyHandler(event.target.value)}
                />
                {pathologyOtherFlag && (
                  <Input
                    variant="standard"
                    value={pathologyOtherText}
                    onChange={(event) =>
                      setPathologyOtherText(event.target.value)
                    }
                  />
                )}
                {/* <Checkbox checkboxList={[]} row onChange={()=>}/> */}
                {/* <Checkbox
                  singleCheckbox
                  label="Formalin"
                  checkboxStyle={{ padding: "0 5px" }}
                /> */}
                {/* <Checkbox
                  singleCheckbox
                  label="Saline"
                  checkboxStyle={{ padding: "0 5px" }}
                /> */}
                {/* <Input variant='standard' sx={{ width: '100px' }} />
                                <Typography fontWeight={'500'}>o'clock</Typography> */}
                {/* <Checkbox
                  singleCheckbox
                  label="Other"
                  checkboxStyle={{ padding: "0 5px" }}
                /> */}
              </Grid>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  className={classes.cardContainer}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableBody>
                      <StyledTableRow>
                        <TableCell sx={{ textAlign: "center !important" }}>
                          Body Site
                        </TableCell>
                        <TableCell>Sub Site</TableCell>
                        <TableCell>Procedure</TableCell>
                        {/* <TableCell>Cassette</TableCell> */}
                      </StyledTableRow>
                      {bodySubProcedureData.map((data, i) => {
                        return (
                          <StyledTableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Stack
                                flexDirection={"row"}
                                gap={1}
                                alignItems={"flex-end"}
                              >
                                <Typography
                                  fontWeight={"700"}
                                  sx={{ width: "20px" }}
                                  fontSize={{ xs: "13px", sm: "16px" }}
                                >
                                  {i + 1}.
                                </Typography>
                                <Input
                                  boxWidth="100%"
                                  variant="standard"
                                  value={data.BodySite}
                                  onChange={(e) =>
                                    handleChange(i, "BodySite", e.target.value)
                                  }
                                />
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Input
                                variant="standard"
                                value={data.SubSite}
                                onChange={(e) =>
                                  handleChange(i, "SubSite", e.target.value)
                                }
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Input
                                variant="standard"
                                value={data.Procedure}
                                onChange={(e) =>
                                  handleChange(i, "Procedure", e.target.value)
                                }
                              />
                            </TableCell>
                            {/* <TableCell component="th" scope="row"><Input variant='standard' /></TableCell> */}
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontWeight={"700"}
                  fontSize={{ xs: "15px", sm: "18px" }}
                >
                  Cytology Specimens{" "}
                  <small style={{ fontWeight: "500", marginLeft: "10px" }}>
                    <i>(non-gyn cytology & fine needle aspirations):</i>
                  </small>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontWeight={"700"}
                  fontSize={{ xs: "15px", sm: "18px" }}
                >
                  <small style={{ fontWeight: "500" }}>
                    Indicate site, fixative, and any other features
                  </small>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  fontWeight={"500"}
                  fontSize={{ xs: "13px", sm: "16px" }}
                >
                  Submitted in:
                </Typography>

                <Checkbox
                  checkboxList={cytologySpecimens}
                  row
                  onChange={(event) => cytologyHandler(event.target.value)}
                />

                {
                  cytologyFlag && <Input variant="standard" value={cytologyOtherText} onChange={(event)=>setCytologyOtherText(event.target.value)}/>
                }
                {/* <Checkbox
                  singleCheckbox
                  label="95% Alcohol"
                  checkboxStyle={{ padding: "0 5px" }}
                /> */}
                {/* <Checkbox singleCheckbox label='no fixative' checkboxStyle={{ padding:'0 5px' }} /> */}
                {/* <Checkbox
                  singleCheckbox
                  label="other"
                  checkboxStyle={{ padding: "0 5px" }}
                /> */}
                {/* <Input variant='standard' sx={{ width: '100px' }} /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} display={"flex"}>
                <Typography
                  fontWeight={"700"}
                  fontSize={{ xs: "15px", sm: "18px" }}
                >
                  Breast Table{" "}
                </Typography>
                <small
                  style={{
                    fontWeight: "500",
                    marginLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  <i>
                    Required for all breast <br /> samples fill out Breast Table
                  </i>
                </small>
              </Grid>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  className={classes.cardContainer}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableBody>
                      <StyledTableRow>
                        <TableCell sx={{ textAlign: "center !important" }}>
                          Specimen <br /> number
                        </TableCell>
                        <TableCell>
                          Time taken out <br /> of Patient
                        </TableCell>
                        <TableCell>
                          Time paced in <br /> 10% formalin
                        </TableCell>
                      </StyledTableRow>
                      {breastTable.map((data, i) => {
                        return (
                          <StyledTableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Input
                                variant="standard"
                                value={data.specimenNumber}
                                onChange={(e) =>
                                  breastChangeHadler(
                                    i,
                                    "specimenNumber",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Input
                                variant="standard"
                                value={data.timeTakenoutOfPatient}
                                onChange={(e) =>
                                  breastChangeHadler(
                                    i,
                                    "timeTakenoutOfPatient",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Input
                                variant="standard"
                                value={data.timePacedInFormalin}
                                onChange={(e) =>
                                  breastChangeHadler(
                                    i,
                                    "timePacedInFormalin",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  className={classes.cardContainer}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableBody>
                      {/* <StyledTableRow>
                                                <TableCell colSpan={3} sx={{ textAlign: 'center !important' }}>For Pathology Use Only</TableCell>
                                            </StyledTableRow> */}
                      <StyledTableRow>
                        <TableCell sx={{ textAlign: "center !important" }}>
                          Consultant <br /> at Surgery
                        </TableCell>
                        <TableCell sx={{ textAlign: "center !important" }}>
                          <Stack sx={{ width: "min-content", margin: "auto" }}>
                            {/* <Checkbox
                              singleCheckbox
                              label="Scheduled "
                              checkboxStyle={{ padding: 0, marginRight: "5px" }}
                            />
                            <Checkbox
                              singleCheckbox
                              label="Gross"
                              checkboxStyle={{ padding: 0, marginRight: "5px" }}
                            /> */}
                            <Checkbox
                              checkboxList={consultantSurgery}
                              row
                              onChange={(event) =>
                                consultantSurgeryHandler(event.target.value)
                              }
                            />
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center !important" }}>
                          <Stack sx={{ width: "min-content", margin: "auto" }}>
                            {/* <Checkbox
                              singleCheckbox
                              label="Unscheduled "
                              checkboxStyle={{ padding: 0, marginRight: "5px" }}
                            />
                            <Checkbox
                              singleCheckbox
                              label="Frozen"
                              checkboxStyle={{ padding: 0, marginRight: "5px" }}
                            /> */}
                          </Stack>
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell
                          colSpan={3}
                          component="th"
                          scope="row"
                          sx={{ height: { sm: "250px" } }}
                        >
                          <textarea
                            style={{
                              width: "100%",
                              height: "100%",
                              padding: "12px",
                              fontSize: "16px",
                            }}
                            value={consultantText}
                            onChange={(event) =>
                              setConsultantText(event.target.value)
                            }
                          ></textarea>
                        </TableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  className={classes.cardContainer}
                >
                  <Table stickyHeader aria-label="simple table">
                    <TableBody>
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell component="th" scope="row">
                          Slides
                        </TableCell>
                        {/* <TableCell component="th" scope="row">Fluid</TableCell> */}
                      </StyledTableRow>
                      {cytologySlides.map((res, index) => {
                        return (
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Input
                                boxWidth="100%"
                                variant="standard"
                                value={res.slides0}
                                onChange={(event) =>
                                  cytologySlideHandler(
                                    index,
                                    "slides0",
                                    event.target.value
                                  )
                                }
                              />{" "}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Input
                                variant="standard"
                                value={res.slides01}
                                onChange={(event) =>
                                  cytologySlideHandler(
                                    index,
                                    "slides01",
                                    event.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Input
                                variant="standard"
                                value={res.slides1}
                                onChange={(event) =>
                                  cytologySlideHandler(
                                    index,
                                    "slides1",
                                    event.target.value
                                  )
                                }
                              />
                            </TableCell>
                            {/* <TableCell component="th" scope="row"><Input variant='standard' /></TableCell> */}
                          </StyledTableRow>
                        );
                      })}

                      {/* <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      > */}
                      {/* <TableCell component="th" scope="row">
                          <Input boxWidth="100%" variant="standard" />{" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Input variant="standard" />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Input variant="standard" />
                        </TableCell> */}
                      {/* <TableCell component="th" scope="row"><Input variant='standard' /></TableCell> */}
                      {/* </StyledTableRow> */}
                      {/* <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                                <TableCell colSpan={4} component="th" scope="row"> <Typography>For Lab Use Only</Typography></TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                                <TableCell colSpan={4} component="th" scope="row"><Input variant='standard' /></TableCell>
                                            </StyledTableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {location.pathname === "/admin/submitted-forms/view" ? (
          ""
        ) : (
          <>
            <Divider
              sx={{
                marginTop: "20px !important",
                marginBottom: "16px !important",
              }}
            />
            <Stack
              gap={"10px"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button buttonName="Cancel" onClick={() => navigate(-1)} />
              <Button
                buttonName="Submit"
                onClick={() => [formik.handleSubmit(), validationHandler()]}
              />
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default SurgicalPathology;
