import React, { useState, useEffect } from 'react'
import { Box, Checkbox, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Logo, login } from '../../Helper/Constant'
import { Input, Button } from '../../Component/Commons'
import { useFormik } from "formik";
import { showNotification } from '../../Helper/Notification'
import { loginInitialValues } from '../../Helper/initialValues';
import { loginValidationSchema } from '../../Helper/validation';
import Cookies from "universal-cookie";
import { loginUserApi } from '../../state/action/authAction';
import { adminLogin } from '../../Service/auth.service';
import { useDispatch } from "react-redux";
import LoginIcon from "@mui/icons-material/Login";

const LogIn = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);



  const formik = useFormik({
    initialValues: loginInitialValues,
    onSubmit: async (value) => {
      setLoadingButton(true)
      const payload = {
        username:value.username,
        password: value.password,
      };

      const res = await adminLogin(payload);
      if (res.status) {
        setLoadingButton(false)

        if (value.remeberme) {
          cookies.set("username", value.username, { path: "/" });
          cookies.set("password", value.password, { path: "/" });
        }
        localStorage.setItem('userToken', res?.data?.token);

        dispatch(loginUserApi(res?.data));
        showNotification("success", res.message || 'Login Successfully')
      } else {
        setLoadingButton(false)

        showNotification("error", res.message || 'Something went wrong')
      }
    },
    validationSchema: loginValidationSchema,
  });


  useEffect(() => {
    const username = cookies.get("username");
    const userPassword = cookies.get("password");

    if (username != "" && username != undefined && userPassword != "" && userPassword != undefined) {
      formik.setFieldValue("username", username);
      formik.setFieldValue("password", userPassword);
    }
  }, []);



  return (
    <Box height={'100vh'}>
      <Box padding={'16px 16px 0'}>
        <img src={Logo} width={'120px'} />
      </Box>
      <Grid container height={'calc(100vh - 100px)'} sx={{ overflow: 'hidden' }} justifyContent={'center'} alignItems={'center'}>
        <Grid item md={5.8} sx={{ position: 'relative', height: '100%', display: { xs: 'none', md: 'block' } }}>
          <img src={login} style={{ height: '100%', width: '100%', objectFit: 'contain', padding: '50px' }} />
        </Grid>
        <Grid item xs={12} md={5.8} sx={{ overflow: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
          <Stack gap={3} justifyContent={'center'} height={'100%'} alignItems={'center'} width={'400px'} margin={'auto'} padding={'50px 20px'} sx={{ border: { xs: '', sm: '1px solid #c1c1c1' }, borderRadius: { xs: '', sm: '25px' }, height: 'fit-content' }} >
            <Box textAlign={'center'} marginBottom={2}>
              <Typography fontSize={'25px'} fontWeight={'600'} color={'#000'}>Log In</Typography>
              {/* <Typography fontSize={'16px'} fontWeight={'500'} color={'#000'} marginTop={1}>Don’t have an Account? <Link to='/signup'>Sign Up</Link></Typography> */}
            </Box>
            <Box width={'100%'}>
              <Stack gap={2}>
                <Box>
                  <Input placeholder='Enter your Username' labelInput='Username' name="username" formik={formik} maxLength={50} />
                </Box>
                <Box>
                  <Input placeholder='Enter your Password' passwordInput labelInput='Password' name="password" formik={formik} maxLength={50} />
                </Box>
              </Stack>
              <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }} marginTop={2}>
                <Grid item xs={6} alignItems={'center'}>
                  <Grid container>
                    <Grid item>
                      <Checkbox

                        sx={{
                          padding: '0', marginRight: '5px',
                          '& .MuiSvgIcon-root': { fontSize: 22 },
                        }}
                        onChange={(e) => formik.setFieldValue('remeberme', e.target.checked)}
                        checked={formik.values.remeberme}
                      />
                    </Grid>
                    <Grid item>Remember me</Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} textAlign={'end'}>
                  <Link to='/forgot-password'>Forgot Password</Link>
                </Grid>
              </Grid>
              <Stack padding={'30px 0 16px 0'} className='login_button'>
                <Button buttonName={loadingButton == true ? <CircularProgress color='white' size={'26px'} /> : "Login"} onClick={formik.handleSubmit} 
                  disabled={loadingButton == true ? true : false} 
                />
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LogIn