import { toast } from "react-toastify";

export const showNotification = (type, message) => {
    const commonSettings = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    };

    switch (type) {
        case 'success':
            toast.success(message, commonSettings);
            break;
        case 'error':
            toast.error(message, commonSettings);
            break;
        default:
            toast.error("Network Error", commonSettings);
    }
};
