import React, { useState, useEffect } from 'react'
import CounterCard from './CounterCard'
import { Grid, Stack, Typography } from '@mui/material'
import { useStyles } from '../../Helper/Theme';
import BarChart from './BarChart';
import { PieChart } from './PieChart';
import MolecularTable from '../submittedform/CreateFormTable';
import { Select } from '../../Component/Commons';
import Skeleton from '@mui/material/Skeleton';
import { getDashboardData, getDashboardDataForms } from '../../Service/dashboard.service';
import { useSelector } from "react-redux";

const filterList = [
  {
    value: "this_week",
    name: "This Week",
  },
  {
    value: "last_week",
    name: "Previous Week",
  },
  {
    value: "this_month",
    name: "This Month",
  },
  {
    value: "last_month",
    name: "Previous Month",
  },
  // {
  //   value: 5,

  //   name: "This Quarter",
  // },
  {
    value: "last_quarter",
    name: "Previous Quarter",
  },
  {
    value: "this_year",
    name: "This Year",
  },
  {
    value: "last_year",
    name: "Previous Year",
  },
];



const DashBoard1 = () => {
  const classes = useStyles();
  const [skalaton, setSkalaton] = useState("")
  const [filterValue, setFilterValue] = useState("this_month")
  const [dashboardData, setDashboardData] = useState([])
  const [submitFormList, setSubmitFormList] = useState([])
  const { user } = useSelector((state) => state?.root?.auth);

  useEffect(() => {
    setTimeout(() => {
      setSkalaton("false")
    }, 1000)
  }, [])

  useEffect(() => {
    dashboardDataGet("this_month")
  }
    , [])

  const dashboardDataGet = async (filterValues) => {
    let selectQry = `?time_period=${filterValues}`
    setSkalaton("")
    const response = await getDashboardData(selectQry);

    const res = await getDashboardDataForms(selectQry)

    if (res.status) {
      setSubmitFormList(res.data)

    } else {
      setSubmitFormList([])
    }

    if (response.status) {
      setDashboardData(response.data)
    }
    else {
      setDashboardData([])
    }

    if (response.status && res.status) {
      setSkalaton("false")
    } else {
      setSkalaton("false")
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack alignItems={'flex-end'}>
            <Select selectHead='Select' selectList={filterList} style={{ width: '180px' }}
              onChange={(e) => {
                dashboardDataGet(e.target.value)
                setFilterValue(e.target.value)
              }}
              value={filterValue}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <CounterCard skalaton={skalaton} dashboardData={dashboardData?.total_count} group={user?.group_name} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: '10px', sm: 2 }} justifyContent={'space-between'}>
            <Grid item md={7} xs={12}>
              {skalaton === "false" ? (
                <div className={classes.cardContainer}>
                  {dashboardData?.bar_graph_data?.find((item) => item?.count !== 0) ? (
                    <BarChart dashboardData={dashboardData?.bar_graph_data} filterValue={filterValue} />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px'
                      }}
                    >
                      <Typography variant="h6" sx={{ textAlign: 'center', color: 'silver' }}>No data found</Typography>
                    </div>
                  )}
                </div>
              ) : skalaton === "" ? (
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: '39%' }} />
                </Skeleton>
              ) : null}
            </Grid>

            <Grid item md={5} xs={12}>
              {skalaton == "false" && <div className={classes.cardContainer}>

                {dashboardData?.pie_chart?.filter_patient == 0 && dashboardData?.pie_chart?.filter_docter == 0 && dashboardData?.pie_chart?.filter_from_count == 0 ? <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px'

                  }}
                ><Typography variant="h6" sx={{ textAlign: 'center', color: 'silver' }}>No data found</Typography></div>
                  : <PieChart chartData={dashboardData?.pie_chart} group={user?.group_name} />}
              </div>}
              {skalaton == "" && <Skeleton variant="rectangular" width="100%">
                <div style={{ paddingTop: '55%' }} />
              </Skeleton>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MolecularTable submitFormList={submitFormList} dashboard={true} />
        </Grid>
      </Grid>
    </>
  )
}

export default DashBoard1