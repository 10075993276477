import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import { Input, Radio } from "../../Component/Commons";
import { useDropzone } from 'react-dropzone';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { showNotification } from "../../Helper/Notification";

const UserDetails = ({ formik, view, isFormSubmitted }) => {
  const [selectedBillList, setSelectedBillList] = useState("");
  const [referByUser, setReferByUser] = useState({});

  const sexList = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const billList = [
    { value: "Bill Patient", label: "Bill Patient" },
    { value: "Bill Insurance", label: "Bill Insurance" },
    { value: "Cash Pay", label: "Uninsured / Cash Pay" },
  ];

  const handleRadioChange = (event) => {
    formik.setFieldValue("patientInformation.patient_payment", event.target.value)
    setSelectedBillList(event.target.value);
    formik.setFieldValue("patientInformation.bill_number", "");
    formik.setFieldValue("patientInformation.bill_amount", "");
    formik.setFieldValue("patientInformation.insurance_company", "");
    formik.setFieldValue("patientInformation.insurance_no", "");
    formik.setFieldValue("patientInformation.pay_amount", "");
    formik.setFieldValue("patientInformation.icd_10", "");
    formik.setFieldValue("patientInformation.clinical_history", "");
    formik.setFieldValue("patientInformation.other_test", "");
  };

  const AgeCalculator = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    formik.setFieldValue("patientInformation.patient_age", age.toString() == "NaN" ? "" : age)
    return age;
  };

  const handelDownloadFile = () => {
    window.open(formik?.values?.patientInformation?.pationtDoc[0], '_blank');
  }


  const onDrop = useCallback((acceptedFiles) => {
    const pdfFiles = acceptedFiles.filter(file => file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
    if (pdfFiles.length === 0) {
      showNotification("error", "Please upload a valid file");
      return;
    } else {
      setFiles(acceptedFiles);
      formik.setFieldValue("patientInformation.pationtDoc", acceptedFiles);
    }

  }, []);

  const [files, setFiles] = useState([]);
  const removeFile = (e) => {
    e.stopPropagation();
    setFiles([]);
    formik.setFieldValue("patientInformation.pationtDoc", []);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    multiple: false,
    disabled: view
  });


  return (
    <>
      <Typography fontWeight={"700"} fontSize={{ xs: "15px", sm: "18px" }}>
        Referred By: <small>(if Applicable)</small>
      </Typography>
      <Divider />
      <Box marginTop={2} marginBottom={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Input
              labelInput="Facility Name"
              name="referByUser.facility_name"
              formik={formik}
              view={view}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              labelInput="Doctor"
              name="referByUser.doctor"
              formik={formik}
              view={view}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelInput="Address"
              name="referByUser.address"
              formik={formik}
              view={view}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              labelInput="Phone"
              name="referByUser.phone"
              formik={formik}
              view={view}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              labelInput="Fax"
              name="referByUser.fax"
              view={view}
              formik={formik}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelInput="Copies to additional physician ( print full name )"
              onChange={formik.handleChange}
              view={view}
              name="patientInformation.additional_physician"
              formik={formik}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography fontWeight={"700"} fontSize={{ xs: "15px", sm: "18px" }}>
        Patient Information
      </Typography>
      <Divider />
      <Box marginTop={2} marginBottom={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              labelInput="First Name"
              name="patientInformation.patient_first_name"
              formik={formik}
              view={view}
              maxLength={100}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              labelInput="Last Name"
              name="patientInformation.patient_last_name"
              formik={formik}
              view={view}
              maxLength={100}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_last_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              labelInput="Initial"
              name="patientInformation.initial"
              formik={formik}
              view={view}
              maxLength={100}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.initial}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelInput="Address"
              name="patientInformation.patient_address"
              formik={formik}
              value={formik?.values?.patientInformation?.patient_address}
              maxLength={200}
              view={view}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_address}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              labelInput="City"
              name="patientInformation.patient_city"
              formik={formik}
              view={view}
              value={formik?.values?.patientInformation?.patient_city}
              maxLength={100}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_city}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              labelInput="State"
              name="patientInformation.patient_state"
              formik={formik}
              view={view}
              maxLength={100}
              value={formik?.values?.patientInformation?.patient_state}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_state}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              labelInput="Zip Code"
              name="patientInformation.patient_zip"
              formik={formik}
              view={view}
              maxLength={10}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_zip}
              onChange={(e) => {
                let regex = /^[0-9\b]+$/;
                if (e.target.value === '' || regex.test(e.target.value))
                  formik.handleChange(e)
              }
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              labelInput="Area Code"
              name="patientInformation.patient_area_code"
              formik={formik}
              view={view}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_area_code}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              labelInput="Birth Date"
              type="date"
              name="patientInformation.patient_dob"
              formik={formik}
              onChange={(e) => {
                AgeCalculator(e.target.value)
                formik.handleChange(e)
              }}
              min={new Date().toISOString().split("T")[0]}
              view={view}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_dob}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Radio labelInput="Sex" radioList={sexList} view={view} row name="sex" value={formik?.values?.patientInformation?.patient_sex} onChange={(e) => formik.setFieldValue("patientInformation.patient_sex", e.target.value)} />

            <Typography fontSize={"12px"} sx={{ color: "#dc3545" }}>{isFormSubmitted && formik?.errors?.patientInformation?.patient_sex}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              labelInput="Age"
              name="patientInformation.patient_age"
              formik={formik}
              maxLength={3}
              disabled={true}
              view={view}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_age}
              onChange={(e) => {
                let regex = /^[0-9\b]+$/;
                if (e.target.value === '' || regex.test(e.target.value))
                  formik.handleChange(e)
              }
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Input
              labelInput="Phone"
              name="patientInformation.patient_phone"
              formik={formik}
              maxLength={15}
              view={view}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.patient_phone}
            />
          </Grid>
          <Grid item xs={12}>
            <Radio
              radioList={billList}
              row
              view={view}
              name="billList"
              value={formik?.values?.patientInformation?.patient_payment}
              onChange={handleRadioChange}
            />
            <Typography fontSize={"12px"} sx={{ color: "#dc3545" }}>{isFormSubmitted && formik?.errors?.patientInformation?.patient_payment}</Typography>
          </Grid>
          <Grid item xs={12}>
            {formik?.values?.patientInformation?.patient_payment === "Bill Patient" && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Input
                    labelInput="Bill No"
                    name="patientInformation.bill_number"
                    formik={formik}
                    view={view}
                    value={formik?.values?.patientInformation?.bill_number}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    labelInput="Bill Amount"
                    name="patientInformation.bill_amount"
                    formik={formik}
                    view={view}
                  />
                </Grid>
              </Grid>
            )}
            {formik?.values?.patientInformation?.patient_payment === "Bill Insurance" && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Input
                    labelInput="Insurance Company"
                    name="patientInformation.insurance_company"
                    formik={formik}
                    view={view}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    labelInput="Insurance No"
                    name="patientInformation.insurance_no"
                    formik={formik}
                    view={view}
                  />
                </Grid>
              </Grid>
            )}
            {formik?.values?.patientInformation?.patient_payment === "Cash Pay" && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Input
                    labelInput="Pay Amount"
                    name="patientInformation.pay_amount"
                    formik={formik}
                    view={view}
                    onChange={(e) => {
                      let regex = /^[0-9\b]+$/;
                      if (e.target.value === '' || regex.test(e.target.value))
                        formik.handleChange(e)
                    }
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            <Input
              labelInput="ICD-10"
              name="patientInformation.icd_10"
              formik={formik}
              view={view}
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.icd_10}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelInput="Clinical History"
              name="patientInformation.clinical_history"
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.clinical_history}
              formik={formik}
              view={view}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelInput="Other Tests/Additional Tests"
              name="patientInformation.other_test"
              errorText={isFormSubmitted && formik?.errors?.patientInformation?.other_test}
              formik={formik}
              view={view}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "12px", sm: "14px" }}
              fontWeight={600}
              marginBottom={{ xs: "3px", sm: "6px" }}
              color={"#000"}
            >
              Upload doc

            </Typography>
            <div {...getRootProps()} style={dropzoneStyles}>
              {!view && <input {...getInputProps()} />}
              {formik?.values?.patientInformation?.pationtDoc && formik?.values?.patientInformation?.pationtDoc.length > 0 && formik?.values?.patientInformation?.pationtDoc[0] != null ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p onClick={view ? handelDownloadFile : undefined} style={{ color: '#000', marginBottom: 0, textAlign: 'center' }}>{!view ? (formik?.values?.patientInformation?.pationtDoc && formik?.values?.patientInformation?.pationtDoc[0] && formik?.values?.patientInformation?.pationtDoc[0]?.name) : String(formik?.values?.patientInformation?.pationtDoc).split('/').pop()}</p>
                  {!view &&
                    <Tooltip title="Remove file" placement="top">
                      <IconButton onClick={removeFile} style={{ marginLeft: '8px' }}> {/* Adjust margin for space */}
                        <RemoveIcon style={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  }
                </div>
              ) : (
                <p style={{ color: '#000', marginBottom: 0 }}>Drag 'n' drop a document here, or click to select one</p>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};
export default UserDetails;
