import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { Chip, TableCell, TableRow, TextareaAutosize } from '@mui/material';

export const useStyles = makeStyles(({ theme }) => ({
  cardContainer: {
    borderRadius: '5px !important',
    background: '#FFF',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important',
    border: "1.5px solid #fff",
  },
  smallHead: {
    color: '#797D8C',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '20px',
    paddingBottom: '8px'
  },
  counterNumber: {
    color: '#000',
    fontSize: '30px',
    lineHeight: '30px',
    fontWeight: '700',
  },
  counterImg: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '15px',
    height: '15px'
  },
  percentage: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: ' #6AD2A0',
    fontSize: '12px',
    fontWeight: '400',
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#8cc63f',
    },
    secondary: {
      main: '#0073bd',
    },
    orange: {
      main: '#f7931e',
    },
    black: {
      main: '#000',
      secondary: '#c1c1c1',
    },
    para: {
      main: '#747474'
    },
    white: {
      main: '#fff',
    },
    red: {
      main: '#dd4949',
    },
    yellow: {
      main: '#ffe000',
    },
    silver: {
      main: 'silver',
    },
  },
  components: {
    // button -----------
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          textTransform: 'capitalize',
          fontSize: '15px',
          fontWeight: '500',
          boxShadow: 'unset',
          color: '#fff',
        },
      },
    },
    // button -----------
    // input ------------
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            border: '1px solid #e3e3e3',
            input: {
              fontSize: '15px',
              fontWeight: '400',
              padding: '10px 15px',
              color: '#000',
            },
          },
          '& .MuiOutlinedInput-root:hover': {
            border: '1px solid #e3e3e3',
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: '#747474',
          },
        },
      }
    },
    // input ------------
    // select ------------
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '10px 15px',
          color: '#000',
          borderRadius: '0px',
          // border: '1px solid #454545 !important',
          '& .MuiInputBase-root:hover': {
            // border: '1px solid #454545 !important',
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#000',
          },
        }
      }
    },
    // select ------------
    // searchbar ------------
    MuiStack: {
      styleOverrides: {
        root: {
          '& .search_field': {
            '& .MuiInputBase-root': {
              background: '#fff',
              padding: '8px 15px',
              border: '1px solid #fff',
              borderRadius: '5px',
              input: {
                padding: '0'
              }
            },
          },
        },
      }
    },
    // searchbar ------------
    // modal ------------
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#fff',
          margin: '16px',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: {
          borderTop: '1px solid transparent',
          borderBottom: '1px solid transparent',
          padding: '16px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    // modal ------------
    // table -----------
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '10px 15px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            '&:first-of-type': {
              textAlign: 'start',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          textAlign: 'center',
          '&:first-of-type': {
            textAlign: 'start',
          },
        },
        head: {
          fontWeight: '700',
          background: '#0073bd17 !important',
          padding: '12px 15px !important',
        }
      },
    },
    // table -----------
    MuiList: {
      styleOverrides: {
        root: {
          color: '#000',
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0',
          marginTop: '5px',
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.12) !important',
          margin: '5px 0 !important'
        }
      }
    },
  },
});

export const MyComponent = styled('div')({
  borderRadius: '5px',
  background: '#f7931e',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
});


// table -----------
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#0073bd00",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#0073bd00",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#000",
  fontWeight: '700 !important',
}));
export const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
export const getUserStatus = (value) => {
  const status = {
    false: {
      title: "inActive",
      styles: {
        backgroundColor: "rgb(235 193 14 / 24%)",
        color: "orange",
      },
    },
    true: {
      title: "Active",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },
    },

  };
  return status[value];
};

// table -----------

export const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    border: 1px solid #e3e3e3;
    box-shadow: unset ;
width:'100%',
    &:hover {
        border-color: 1px solid #7a52f4;
    }

    &:focus {
      border-color: 1px solid #7a52f4;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);