import React, { useEffect, useState } from 'react'
import {
    prostate1, prostate2, prostate3, prostate4, prostate5, prostate6, prostate7, prostate8, prostate9, prostate10, prostate11, prostate12, prostate_top_left, prostate_top_right,
} from "../../../../Helper/Constant";

const ProstateImage = ({ handleItemRemoval, selectedImage, setSelectedImage, isDetail, prostateSelectedImageList, bodySubProcedureData, setBodySubProcedureData, setProstateSelectedImageList }) => {

    const handleImageClick = (value) => {
        if (isDetail) return;

        if (!bodySubProcedureData.find((data) => data.BodySite == value) && !prostateSelectedImageList.includes(value)) {
            const newData1 = bodySubProcedureData[bodySubProcedureData.findIndex((data) => data.BodySite === "") == -1 ? 19 : bodySubProcedureData.findIndex((data) => data.BodySite === "")];
            const newData = [...bodySubProcedureData];
            newData[bodySubProcedureData.findIndex((data) => data.BodySite === "") == -1 ? 19 : bodySubProcedureData.findIndex((data) => data.BodySite === "")] = { ...newData1, BodySite: value };
            setBodySubProcedureData(newData);
        } else {
            if (bodySubProcedureData.findIndex((data) => data.BodySite === value) !== -1 && prostateSelectedImageList.indexOf(value) !== -1) {
                handleItemRemoval(value)
            } else {
                setBodySubProcedureData(bodySubProcedureData.filter((data) => data.BodySite !== value));
            }
        }

        if (!prostateSelectedImageList.includes(value)) {
            setSelectedImage([...selectedImage, value]);
            setProstateSelectedImageList([...prostateSelectedImageList, value]);
        } else {
            handleItemRemoval(prostateSelectedImageList.indexOf(value))
            setSelectedImage(selectedImage.filter((data) => data !== value));
            setProstateSelectedImageList(prostateSelectedImageList.filter((data) => data !== value));
        }
    };

    return (
        <>
            <div className="prostate_grid">
                <div
                    className={`${selectedImage.includes('Left Seminal Vesicle') ? 'active_prostate' : ""} prostate_top_left`}
                    onClick={() => handleImageClick('Left Seminal Vesicle')}>
                    <img src={prostate_top_left} alt="" style={{ width: "100%" }} />
                </div>
                <div
                    className={`${selectedImage.includes('Right Seminal Vesicle') ? 'active_prostate' : ""} prostate_top_right`}
                    onClick={() => handleImageClick('Right Seminal Vesicle')}>
                    <img src={prostate_top_right} alt="" style={{ width: "100%" }} />
                </div>
                <div
                    className={`${selectedImage.includes('Left Lateral Base') ? 'active_prostate' : ""} prostate1`}
                    onClick={() => handleImageClick('Left Lateral Base')}>
                    <img src={prostate1} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Left Base') ? 'active_prostate' : ""} prostate2`} onClick={() => handleImageClick('Left Base')}>
                    <img src={prostate2} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Right Base') ? 'active_prostate' : ""} prostate3`} onClick={() => handleImageClick('Right Base')}>
                    <img src={prostate3} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Right Lateral Base') ? 'active_prostate' : ""} prostate4`} onClick={() => handleImageClick('Right Lateral Base')}>
                    <img src={prostate4} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Left Lateral Mid') ? 'active_prostate' : ""} prostate5`} onClick={() => handleImageClick('Left Lateral Mid')}>
                    <img src={prostate5} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Left Mid') ? 'active_prostate' : ""} prostate6`} onClick={() => handleImageClick('Left Mid')}>
                    <img src={prostate6} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Right Mid') ? 'active_prostate' : ""} prostate7`} onClick={() => handleImageClick('Right Mid')}>
                    <img src={prostate7} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Right Lateral Mid') ? 'active_prostate' : ""} prostate8`} onClick={() => handleImageClick('Right Lateral Mid')}>
                    <img src={prostate8} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Left Lateral Apex') ? 'active_prostate' : ""} prostate9`} onClick={() => handleImageClick('Left Lateral Apex')}>
                    <img src={prostate9} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Left Apex') ? 'active_prostate' : ""} prostate10`} onClick={() => handleImageClick('Left Apex')}>
                    <img src={prostate10} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Right Apex') ? 'active_prostate' : ""} prostate11`} onClick={() => handleImageClick('Right Apex')}>
                    <img src={prostate11} alt="" style={{ width: "100%" }} />
                </div>
                <div className={`${selectedImage.includes('Right Lateral Apex') ? 'active_prostate' : ""} prostate11`} onClick={() => handleImageClick('Right Lateral Apex')}>
                    <img src={prostate12} alt="" style={{ width: "100%" }} />
                </div>
            </div>
        </>
    )
}

export default ProstateImage