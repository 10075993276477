import React from "react";
import { Chart } from "react-google-charts";



export function PieChart({ chartData,group }) {
  let data = [];

  if(group == "provider"){
    data = [
      ["Modules", "Number"],
      ["Total Patients", chartData?.filter_patient],
      ["Total Forms", chartData?.filter_from_count],
    ];
  }
  else{
    data = [
      ["Modules", "Number"],
      ["Total Doctors", chartData?.filter_docter],
      ["Total Patients", chartData?.filter_patient],
      ["Total Forms", chartData?.filter_from_count],
    ];
  }

  const options = {
    title: "",
    pieHole: 0.4,
    is3D: false,
    legend: { position: "bottom" },
    slices: {
      0: { color: '#8cc63f' },
      1: { color: '#0073bd' },
      2: { color: '#f7931e' },
    }
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="300px"
      data={data}
      options={options}
    />
  );
}
