import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CloseIcon } from '../../Helper/Icons'
import { Button } from '../Commons'

const Delete = (props) => {
    const { confirmDelete, setDeletePopup, title, message } = props;

    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setDeletePopup(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Box width={{ sm: '400px' }}>
                    <Typography> {message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName='No' onClick={() => setDeletePopup(false)} />
                <Button buttonName='Yes' onClick={() => confirmDelete()} />
            </DialogActions>
        </div>
    )
}

export default Delete