import { get } from "../web.request"

export const getStateData = () =>{
    return get(`/state_list`)
}

export const getCityData = () =>{
    return get(`/city_list`)
}

export const getHospitalData = () =>{
    return get(`/hospital_list`)
}

