import React, { useEffect, useState } from "react";
import UserDetails from "../../Pages/createtest/UserDetails";
import { Grid,Paper, Stack, Table, TableBody, TableCell, TableContainer, Typography } from "@mui/material";
import { Checkbox } from "../Commons";
import { useFormik } from "formik";
import { nonGynCytopathologyRequisitionIV } from "../../Helper/initialValues";
import { StyledTableRow, useStyles } from "../../Helper/Theme";
import { getCreateNonGynDataHandler } from "../../Service/NonGynCytopathology.service";
import { useLocation } from "react-router-dom";
import {Input} from "../Commons"

function NonGynFormView() {
  const [fillUpdata, setFillUpdata] = useState([]);
  const [testingType, setTestingType] = useState([]);
  const classes = useStyles();

  const { state } = useLocation();
  
  const formik = useFormik({
    initialValues: nonGynCytopathologyRequisitionIV,
  });
  
  const checkboxList = [
    { value: "FNA", label: "FNA" },
    { value: "Reflex Molecular testing", label: "Reflex Molecular testing" },
  ];


  useEffect(() => {
    viewFormApiHandler();
  }, []);


  const viewFormApiHandler = async () =>{
    const { data, message, status } = await getCreateNonGynDataHandler(
        state.formId
      );
      if (status) {
        formik.setValues({
          ...formik.values,
          referByUser: {
            facility_name: data?.user_information?.facility_name,
            doctor: data?.user_information?.full_name,
            address: data?.user_information?.address,
            phone: data?.user_information?.phone,
            fax: data?.user_information?.fax,
          },
          patientInformation: {
            patient_first_name: data?.patient_information?.patient_first_name,
            patient_last_name: data?.patient_information?.patient_last_name,
            initial: data?.patient_information?.initial,
            patient_address: data?.patient_information?.patient_address,
            patient_city: data?.patient_information?.patient_city,
            patient_state: data?.patient_information?.patient_state,
            patient_zip: data?.patient_information?.patient_zip,
            patient_area_code: data?.patient_information?.patient_area_code,
            patient_age: data?.patient_information?.patient_age,
            patient_sex: data?.patient_information?.patient_sex,
            patient_dob: data?.patient_information?.patient_dob,
            patient_phone: data?.patient_information?.patient_phone,
            patient_payment: data?.patient_information?.patient_payment,
            pay_amount: data?.patient_information?.pay_amount,
            bill_number: data?.patient_information?.bill_number,
            bill_amount: data?.patient_information?.bill_amount,
            insurance_company: data?.patient_information?.insurance_company,
            insurance_no: data?.patient_information?.insurance_no,
            icd_10: data?.patient_information?.icd_10,
            clinical_history: data?.patient_information?.clinical_history,
            additional_physician: data?.patient_information?.additional_physician == null ? "": data?.patient_information?.additional_physician,
            other_test: data?.patient_information?.other_test,
            pationtDoc : [data?.patient_information?.patient_document],
          },
        });
        setTestingType(
          data?.non_gyn_cytopathology_requisition_information?.testing_type
        );
        setFillUpdata(
          data?.non_gyn_cytopathology_requisition_information.body_sub_procedure
        );
      }
  };

  return (
    <>
      <UserDetails formik={formik} view={true}/>

      <Grid container marginTop={{ sm: 3 }} spacing={2}>
        <Grid item xs={12}>
          <Checkbox
            checkboxList={checkboxList}
            row
            view={true}
            checked={testingType}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} className={classes.cardContainer}>
            <Table stickyHeader aria-label="simple table">
              <TableBody>
                <StyledTableRow>
                  <TableCell sx={{ textAlign: "center !important" }}>
                    Body Site
                  </TableCell>
                  <TableCell>Sub Site</TableCell>
                  <TableCell>Procedure</TableCell>
                </StyledTableRow>
                {fillUpdata.map((data, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell component="th" scope="row" width={"300px"}>
                        <Stack
                          flexDirection={"row"}
                          gap={1}
                          alignItems={"flex-end"}
                        >
                          <Typography fontWeight={"700"} sx={{ width: "20px" }}>
                            {index + 1}.
                          </Typography>
                          <Input
                            boxWidth="100%"
                            variant="standard"
                            value={data.BodySite}
                            view={true}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Input
                          variant="standard"
                          value={data.SubSite}
                          view={true}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Input
                          variant="standard"
                          value={data.Procedure}
                          view={true}
                        />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default NonGynFormView;
