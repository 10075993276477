import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../Helper/Icons'
import { Button } from '../Commons'
import { useDispatch } from "react-redux";
import { logoutApi } from "../../state/action/authAction"
import { useNavigate } from 'react-router-dom';

const Logout = ({ handleClose }) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Logout
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Box width={{ sm: '400px' }}>
                    <Typography>Do you want to logout from this Web? </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName='No' onClick={handleClose} />
                <Button buttonName='Yes' onClick={() => {
                    dispatch(logoutApi())
                    Navigate("/login")
                }} />
            </DialogActions>
        </div>
    )
}

export default Logout