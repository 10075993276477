import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    username: Yup.string().required('User Name is required'),
    password: Yup.string()
        .required('Password is required')
    // .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    // ),
});

export const profileValidationSchema = Yup.object().shape({
    fname: Yup.string().required('First Name is required'),
    lname: Yup.string().required('Last Name is required'),
    phone: Yup.number().typeError('Phone must be a valid number')
        .required('Phone is required')
        .positive('Phone must be a positive number'),
    address: Yup.string().required('Address is required'),
    dob: Yup.date().required('Date of birth is required'),
    fax: Yup.number().typeError('Fax must be a valid number')
        .required('Fax is required'),
});

export const createUserValidationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required').matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    username:Yup.string().required('UserName is required'),
});

export const UpdateUserValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    address: Yup.string().optional('Address Name is required'),
    username:Yup.string().required('UserName is required'),
});


export const updatePassValidation = Yup.object().shape({
    new_pass: Yup.string()
        .required('Password is required').matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
    confirm_new_pass: Yup.string()
        .required('Password is required').matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
});


export const userChangePasswordValidation = Yup.object().shape({
    new_password: Yup.string()
        .required('Password is required').matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        ),
    confirm_password: Yup.string()
        .required('Confrim password is required').matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
            'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
        )
});




// export const referByUserValidation = Yup.object().shape({

// });

export const patientInformationSchema = Yup.object().shape({
    patient_first_name: Yup.string().required('First name is required'),
    patient_last_name: Yup.string().required('Last name is required'),
    patient_sex: Yup.string().required('Sex is required'),
    patient_dob: Yup.date().required('Date of birth is required'),
    patient_payment: Yup.string().required('Payment information is required'),
    icd_10: Yup.string().required('ICD-10 code is required'),
})

export const nonGynCytopathologyRequisitionValidation = Yup.object().shape({
    patientInformation: patientInformationSchema
})

export const SurgicalPathologyValidation = Yup.object().shape({
    patientInformation: patientInformationSchema
})

export const prostateValidation = Yup.object().shape({
    patientInformation: patientInformationSchema
})
export const molecularValidation = Yup.object().shape({
    patientInformation: patientInformationSchema
})
export const hamepathologyValidation = Yup.object().shape({
    patientInformation: patientInformationSchema
})

export const gynValidation = Yup.object().shape({
    patientInformation: patientInformationSchema
})

export const podiatryValidation = Yup.object().shape({
    patientInformation: patientInformationSchema
})