import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import { Button, Checkbox, Input, Modal } from "../../../../Component/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon, FiFileText } from "../../../../Helper/Icons";
import { StyledTableRow, useStyles } from "../../../../Helper/Theme";
import UserDetails from "../../UserDetails";
import { useFormik } from "formik";
import { nonGynCytopathologyRequisitionIV } from "../../../../Helper/initialValues";
import { getProfile } from "../../../../Service/profile.service";
import { createNonCytDataHandler } from "../../../../Service/NonGynCytopathology.service";
import { showNotification } from "../../../../Helper/Notification";
import { nonGynCytopathologyRequisitionValidation } from "../../../../Helper/validation";
import { useSelector } from "react-redux";
import { savePatientDoc } from "../../../../Service/commanForm.service";

const Cytopathology = () => {
  const classes = useStyles();
  const { user } = useSelector((state) => state?.root?.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedValues, setSelectedValues] = useState([]);
  const [referByUser, setReferByUser] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [fillUpdata, setFillUpdata] = useState([
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
    {
      BodySite: "",
      SubSite: "",
      Procedure: "",
    },
  ]);

  const checkboxList = [
    { value: "FNA", label: "FNA" },
    { value: "Reflex Molecular testing", label: "Reflex Molecular testing" },
  ];

  const dataSubmitHandler = async () => {
    const payload = {
      patient_information: {
        patient_first_name: formik.values.patientInformation.patient_first_name,
        patient_last_name: formik.values.patientInformation.patient_last_name,
        initial: formik.values.patientInformation.initial,
        patient_address: formik.values.patientInformation.patient_address,
        patient_city: formik.values.patientInformation.patient_city,
        patient_state: formik.values.patientInformation.patient_state,
        patient_zip: formik.values.patientInformation.patient_zip,
        patient_area_code: formik.values.patientInformation.patient_area_code,
        patient_age: formik.values.patientInformation.patient_age,
        patient_sex: formik.values.patientInformation.patient_sex,
        patient_dob: formik.values.patientInformation.patient_dob,
        patient_phone: formik.values.patientInformation.patient_phone,
        patient_payment: formik.values.patientInformation.patient_payment,
        bill_number: formik.values.patientInformation.bill_number,
        bill_amount: formik.values.patientInformation.bill_amount,
        insurance_company: formik.values.patientInformation.insurance_company,
        insurance_no: formik.values.patientInformation.insurance_no,
        pay_amount: formik.values.patientInformation.pay_amount,
        icd_10: formik.values.patientInformation.icd_10,
        clinical_history: formik.values.patientInformation.clinical_history,
        "additional_physician": formik.values.patientInformation.additional_physician,
        other_test: formik.values.patientInformation.other_test,
      },
      non_gyn_cytopathology_requisition_information: {
        body_sub_procedure: formik.values.bodySubProcedure,
        testing_type: formik.values.testingType,
      },
    };
    const { data, message, status } = await createNonCytDataHandler(payload);
    if (status) {
      if (formik.values.patientInformation.pationtDoc && formik.values.patientInformation.pationtDoc.length > 0) {
        savePatientDocHandler(data?.patient_information?.id)
      } else {

        showNotification("success", message || 'Form Register Successfully');
        if (user.group_name === "provider") {
          navigate(`/submitted-forms`)
        } else {
          navigate(`/admin/submitted-forms`);
        }
      }
      // navigate(`/admin/submitted-forms`);
    } else {
      showNotification(
        "error",
        message || "Something went wrong while Form Register"
      );
    }
  };

  const formik = useFormik({
    initialValues: nonGynCytopathologyRequisitionIV,
    validationSchema: nonGynCytopathologyRequisitionValidation,
    onSubmit: dataSubmitHandler,
  });

  const savePatientDocHandler = async (id) => {
    const formData = new FormData();
    formData.append("patient_document", formik.values.patientInformation.pationtDoc[0]);
    const { data, message, status } = await savePatientDoc(id,formData);
    if (status) {
      showNotification("success", message || 'Form Register Successfully');
      if (user.group_name === "provider") {
        navigate(`/submitted-forms`)
      } else {
        navigate(`/admin/submitted-forms`);
      }
    } else {
      showNotification("error", message || 'Something went wrong while Form Register')
    }
  };

  const validationHandler = () => {
    setIsFormSubmitted(true);
    if (Object.keys(formik.errors).length > 0) {
      showNotification("error", "Required filed is missing");
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...fillUpdata];
    newData[index][field] = value;
    setFillUpdata(newData);
    formik.setFieldValue("bodySubProcedure", newData);
  };

  const handleCheckboxChange = (value) => {
    // Check if the checkbox is already selected
    if (selectedValues.includes(value)) {
      // If selected, remove it from the array
      setSelectedValues(selectedValues.filter((v) => v !== value));
      formik.setValues({
        ...formik.values,
        testingType: selectedValues.filter((v) => v !== value),
      });
    } else {
      // If not selected, add it to the array
      setSelectedValues([...selectedValues, value]);
      formik.setValues({
        ...formik.values,
        testingType: [...selectedValues, value],
      });
    }
  };

  useEffect(() => {
    referredByDataHandler();
  }, []);

  const referredByDataHandler = async () => {
    const { data, message, status } = await getProfile();
    if (status) {
      setReferByUser(data);
      formik.setValues({
        ...formik.values,
        referByUser: {
          facility_name: data.facility_name,
          doctor: data.full_name,
          address: data.address,
          phone: data.phone,
          fax: data.fax,
        },
      });
    } else {
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", background: "#8cc63f", color: "#fff" }}
        />
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
      />
      <Box
        className={classes.cardContainer}
        padding={{ xs: "12px 10px", sm: 3 }}
      >
        <UserDetails formik={formik} isFormSubmitted={isFormSubmitted}/>

        <Grid container marginTop={{ sm: 3 }} spacing={2}>
          <Grid item xs={12}>
            <Checkbox
              checkboxList={checkboxList}
              row
              onChange={(e) => handleCheckboxChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.cardContainer}>
              <Table stickyHeader aria-label="simple table">
                <TableBody>
                  <StyledTableRow>
                    <TableCell sx={{ textAlign: "center !important" }}>
                      Body Site
                    </TableCell>
                    <TableCell>Sub Site</TableCell>
                    <TableCell>Procedure</TableCell>
                    {/* <TableCell>Cassette</TableCell> */}
                  </StyledTableRow>
                  {fillUpdata.map((data, index) => {
                    return (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row" width={"300px"}>
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"flex-end"}
                          >
                            <Typography
                              fontWeight={"700"}
                              sx={{ width: "20px" }}
                            >
                              {index + 1}.
                            </Typography>
                            <Input
                              boxWidth="100%"
                              variant="standard"
                              value={data.BodySite}
                              onChange={(e) =>
                                handleChange(index, "BodySite", e.target.value)
                              }
                            />
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Input
                            variant="standard"
                            value={data.SubSite}
                            onChange={(e) =>
                              handleChange(index, "SubSite", e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Input
                            variant="standard"
                            value={data.Procedure}
                            onChange={(e) =>
                              handleChange(index, "Procedure", e.target.value)
                            }
                          />
                        </TableCell>
                        {/* <TableCell component="th" scope="row"><Input variant='standard' /></TableCell> */}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {location.pathname === "/admin/submitted-forms/view" ? (
          ""
        ) : (
          <>
            <Divider
              sx={{
                marginTop: "20px !important",
                marginBottom: "16px !important",
              }}
            />
            <Stack
              gap={"10px"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button buttonName="Cancel" onClick={() => navigate(-1)} />
              <Button
                buttonName="Submit"
                onClick={() => [formik.handleSubmit(), validationHandler()]}
              />
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default Cytopathology;
