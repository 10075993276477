import React, { useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, DateFilter, Select } from "../../Component/Commons";

const FilterForm = ({ openDrawer, toggleDrawer, dataHandler, testTypeList, stateList, cityList, genderList,
  formik,

}) => {
  const drawerWidth = 250;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [days, setDays] = useState("");
  const [weekDayStartName, setWeekDayStartName] = useState("");
  const [monthStartName, setMonthStartName] = useState("");
  const [weekDayEndName, setWeekDayEndName] = useState("");
  const [monthEndName, setMonthEndName] = useState("");


  const resetHandler = () => {
    setFromDate("");
    setToDate("");
    setDays("");
    setWeekDayStartName("");
    setMonthStartName("");
    setWeekDayEndName("");
    setMonthEndName("");
    formik.setFieldValue('startDate', '');
    formik.setFieldValue('endDate', '');
    formik.setFieldValue('patient_state', '');
    formik.setFieldValue('patient_city', '');
    formik.setFieldValue('patient_sex', '');
    formik.setFieldValue('form_type', '');
    formik.handleSubmit();
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            padding: 2,
          },
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize={"20px"}>
              Add Filter
            </Typography>
            <IconButton
              aria-label="close"
              onClick={toggleDrawer}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} marginY={"5px"}>
            <Divider sx={{ borderColor: "gray.main" }} />
          </Grid>

          <Grid item xs={12} marginY={2}>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={600}
                  marginBottom={"6px"}
                >
                  Date
                </Typography>
                <DateFilter
                  setFromDate={setFromDate}
                  formik={formik}
                  fromDate={fromDate}
                  setToDate={setToDate}
                  toDate={toDate}
                  setDays={setDays}
                  days={days}
                  setWeekDayStartName={setWeekDayStartName}
                  setMonthStartName={setMonthStartName}
                  setWeekDayEndName={setWeekDayEndName}
                  setMonthEndName={setMonthEndName}
                />
              </Grid>
              <Grid item xs={12}>
                <Select labelSelect="Select State" selectList={stateList}
                  onChange={(e) => formik.setFieldValue("patient_state", e.target.value)}
                  value={formik.values.patient_state}
                  placeholder="Select State"
                />
              </Grid>
              <Grid item xs={12}>
                <Select labelSelect="Select City" selectList={cityList}
                  onChange={(e) => formik.setFieldValue("patient_city", e.target.value)}
                  value={formik.values.patient_city}
                  placeholder="Select City"
                />
              </Grid>
              <Grid item xs={12}>
                <Select labelSelect="Select Sex" selectList={genderList}
                  onChange={(e) => formik.setFieldValue("patient_sex", e.target.value)}
                  value={formik.values.patient_sex}
                  placeholder='Select Gender'
                />
              </Grid>
              <Grid item xs={12}>
                <Select labelSelect="Select Test" selectList={testTypeList}
                  onChange={(e) => formik.setFieldValue("form_type", e.target.value)}
                  value={formik.values.form_type}
                  placeholder='Select Test'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} marginY={"5px"}>
            <Divider sx={{ borderColor: "gray.main" }} />
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={"row"} gap={1}>
              <Button
                buttonName="Reset"
                buttonColor="gray"
                fullWidth
                onClick={() => resetHandler()}
              />
              <Button
                buttonName="Apply"
                fullWidth
                disabled={formik.values.startDate == "" && formik.values.endDate == "" && formik.values.patient_state == "" && formik.values.patient_city == "" && formik.values.patient_sex == "" && formik.values.form_type == ""}
                onClick={() => formik.handleSubmit()}
              />
            </Stack>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default FilterForm;
