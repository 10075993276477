import React from 'react'
import { Box, Card, Grid, Stack, Typography, } from '@mui/material'
import { useStyles } from '../../Helper/Theme';
import { FaUserDoctor } from "react-icons/fa6";
import { FaUserInjured } from "react-icons/fa";
import { FaFileWaveform } from "react-icons/fa6";
import Skeleton from '@mui/material/Skeleton';

const CounterData = [
    {
        para: 'Doctors',
        number: '25',
        icons: <Box sx={{ width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#8cc63f', borderRadius: '50%' }}><FaUserDoctor style={{ fontSize: '26px', color: '#fff' }} /></Box>,
    },
    {
        para: 'Patients',
        number: '102',
        icons: <Box sx={{ width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#0073bd', borderRadius: '50%' }}><FaUserInjured style={{ fontSize: '26px', color: '#fff' }} /></Box>,
    },
    {
        para: 'Forms',
        number: '40',
        icons: <Box sx={{ width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7931e', borderRadius: '50%' }}><FaFileWaveform style={{ fontSize: '26px', color: '#fff' }} /></Box>,
    },
]

const CounterCard = (props) => {
    const { skalaton,dashboardData,group } = props;
    const classes = useStyles();


    return (
        <Grid container sx={{ justifyContent: 'space-between' }} spacing={2}>
            {
                CounterData.map((data, i) => {
                    return (
                         group == "provider" && data.para == "Doctors" ? null :(

                        <Grid item xs={6} sm={6} md={group == "provider" ? 6 : 4} lg={group == "provider" ? 6 : 4} key={i}>
                            <Card className={classes.cardContainer}>
                                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} padding={{ xs: 2, md: 3 }} sx={{ background: 'var(--cardBG)' }}>
                                    <Stack>
                                        <Box item xs={6} className={classes.smallHead}>{data.para}</Box>
                                        
                                       {skalaton == "false" && <Box item xs={6} className={classes.counterNumber}>{
                                        data.para == "Doctors" ? dashboardData?.total_docter : data.para == "Patients" ? dashboardData?.total_patient : dashboardData?.total_form}
                                       </Box>}
                                        {skalaton == "" && <Skeleton width="100%" height="25%">
                                            <Typography>.</Typography>
                                        </Skeleton>}
                                    </Stack>
                                    {skalaton == "" &&<Skeleton animation="wave" variant="circular" width={40} height={40} />}

                                    {skalaton == "false" && data.icons}
                                </Stack>
                            </Card>
                        </Grid>
                            )
                    );
                })
            }
        </Grid>
    )
}

export default CounterCard