import React from 'react';
import { Chart } from 'react-google-charts';
import { useStyles } from '../../Helper/Theme';
import moment from 'moment';    

const BarChart = (props) => {
    const { dashboardData,filterValue } = props;
    const classes = useStyles();
    console.log("dashboardData",dashboardData)

    let transformedData = [];

    if (dashboardData) {
        transformedData = [
            ['Category', 'Forms', { role: "style" }],
            ...dashboardData.map(item => [
                ["this_month","last_month"].includes(filterValue) ? moment(item.date).format("DD MMM") 
                 : item.date,
              item.count,
              '#8cc63f'
            ])
          ];
    }

    return (
        <Chart
            width={'100%'}
            height={'300px'}
            chartType="ColumnChart"
            data={transformedData}
            options={{
                title: '',
                legend: 'none',
                chartArea: { width: '85%', height: '80%' },
            }}
        />
    );
};

export default BarChart;
