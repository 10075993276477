import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, ...props }) => {

    const isAuthenticate = useSelector((state) => state?.root?.auth?.isAuthenticate);

    return isAuthenticate == true ? children : setTimeout(() => {
        <Navigate to='/login' />
    }, 1000);
};

export default PrivateRoute;
