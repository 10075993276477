import React, { useState } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../Helper/Icons";
import { Button, DateFilter, Select } from "../../Component/Commons";
import { hospitalList } from "../../Helper/Helper";

const FilterUser = ({ openDrawer, toggleDrawer, dataHandler, hospitalData, formik }) => {
  const drawerWidth = 250;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [hospital, setHospital] = useState("");
  const [days, setDays] = useState("");
  const [weekDayStartName, setWeekDayStartName] = useState("");
  const [monthStartName, setMonthStartName] = useState("");
  const [weekDayEndName, setWeekDayEndName] = useState("");
  const [monthEndName, setMonthEndName] = useState("");

  const applyHandler = () => {
    toggleDrawer();
    dataHandler({
      startDate: fromDate,
      endDate: toDate,
      hospital: hospital,
    });
  };

  const resetHandler = () => {
    setFromDate("");
    setToDate("");
    setDays("");
    setWeekDayStartName("");
    setMonthStartName("");
    setWeekDayEndName("");
    setMonthEndName("");
    formik.setFieldValue('startDate', '');
    formik.setFieldValue('endDate', '');
    formik.setFieldValue('hospital', '');
    formik.handleSubmit();
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            padding: 2,
          },
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize={"20px"}>
              Add Filter
            </Typography>
            <IconButton
              aria-label="close"
              onClick={toggleDrawer}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} marginY={"5px"}>
            <Divider sx={{ borderColor: "gray.main" }} />
          </Grid>

          <Grid item xs={12} marginY={2}>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={600}
                  marginBottom={"6px"}
                >
                  Date
                </Typography>
                <DateFilter setFromDate={setFromDate}
                  fromDate={fromDate}
                  setToDate={setToDate}
                  toDate={toDate}
                  setDays={setDays}
                  days={days}
                  formik={formik}
                  setWeekDayStartName={setWeekDayStartName}
                  setMonthStartName={setMonthStartName}
                  setWeekDayEndName={setWeekDayEndName}
                  setMonthEndName={setMonthEndName} />
              </Grid>
              <Grid item xs={12}>
                <Select
                  labelSelect="Select Facility"
                  selectList={hospitalData}
                  onChange={(e) => formik.setFieldValue("hospital", e.target.value)}
                  value={formik.values.hospital}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} marginY={"5px"}>
            <Divider sx={{ borderColor: "gray.main" }} />
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={"row"} gap={1}>
              <Button
                buttonName="Reset"
                buttonColor="gray"
                fullWidth
                onClick={() => resetHandler()}
              />
              <Button buttonName="Apply" fullWidth
                disabled={formik.values.hospital == '' && formik.values.startDate == '' && formik.values.endDate == ''}
                onClick={() => formik.handleSubmit()}
              />
            </Stack>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default FilterUser;
